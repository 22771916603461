export const QaMediumIcon = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315886">
        <path id="Rectangle 34625573" d="M12 6.45312H21.375V21.875H12V6.45312Z" fill="black" />
        <path
          id="Rectangle 34625572"
          d="M0.75 0.875C0.75 0.460787 1.08579 0.125 1.5 0.125H18.75C19.1642 0.125 19.5 0.460786 19.5 0.875V21.875H3C1.75736 21.875 0.75 20.8676 0.75 19.625V0.875Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="21.375" cy="20" r="1.875" fill="#4268FB" />
        <path
          id="Rectangle 34625573_2"
          d="M19.5 5H22.5C22.9142 5 23.25 5.33579 23.25 5.75V20H19.5V5Z"
          fill="#4268FB"
        />
        <path
          id="Vector 4"
          d="M6.375 9.8576L9.13663 12.6192L13.875 7.88086"
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}
