export const NexonAdvertisementIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 35L12.6228 27.468V12.532L34 5V35ZM20.3221 18.4057L27.4478 25.5023H29.7032L29.6935 14.1575H26.8743V21.254L19.7583 14.1575H17.5029V25.5023H20.3221V18.4057Z"
        fill="black"
      />
      <path d="M5 13.1711H10.269V26.8388H5V13.1711Z" fill="black" />
    </svg>
  )
}
