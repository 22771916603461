export const OfficialCommunityIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5C7.23858 5 5 7.23858 5 10V30C5 32.7614 7.23858 35 10 35H30C32.7614 35 35 32.7614 35 30V10C35 7.23858 32.7614 5 30 5H10ZM20 17.5039C20 15.0179 22.0144 13.0021 24.5 13C26.9856 13.0021 29 15.0179 29 17.5039C29 19.0105 28.4507 20.3051 27.1874 21.8666C25.6103 23.8047 22.15 26.4568 20 27.8928C17.8579 26.4647 14.3897 23.8126 12.8126 21.8666C11.5493 20.3051 11 19.0105 11 17.5039C11 15.0166 13.0087 13 15.4961 13C17.9834 13 20 15.0166 20 17.5039ZM24.5 13L24.5039 13H24.4961L24.5 13Z"
        fill="black"
      />
    </svg>
  )
}
