import logo from '@/assets/images/img_creator.png'
import { CreatorsIcon, TRENDiIcon, WithdrawalIcon } from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.creator

export const creatorBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const creatorSEO = i18n.seo

const sponsorship: SolutionDetailData = {
  id: 'sponsorship',
  name: i18n.sponsorship.name,
  title: [i18n.sponsorship.title1, i18n.sponsorship.title2],
  description: i18n.sponsorship.description,
  featureItems: [
    {
      icon: <CreatorsIcon />,
      title: i18n.sponsorship.features.creators,
      link: i18n.sponsorship.features.creatorsLink,
    },
    {
      icon: <WithdrawalIcon />,
      title: i18n.sponsorship.features.withdrawal,
      link: i18n.sponsorship.features.withdrawalLink,
    },
  ],
  featureDetails: [],
}

const insights: SolutionDetailData = {
  id: 'insights',
  name: i18n.insights.name,
  title: [i18n.insights.title1, i18n.insights.title2],
  description: i18n.insights.description,
  featureItems: [
    {
      icon: <TRENDiIcon />,
      title: i18n.insights.features.trendi,
      link: i18n.insights.features.trendiLink,
    },
  ],
  featureDetails: [],
}

export const creatorNav: NavSectionItem[] = [
  {
    id: sponsorship.id,
    name: sponsorship.name,
  },
  {
    id: insights.id,
    name: insights.name,
  },
]

export const creatorSolution: SolutionDetailData[] = [sponsorship, insights]
