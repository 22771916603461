export const DataExtractionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 8H33V12.5H6V8Z" fill="black" />
      <path
        d="M6.18359 15H32.8164L21.768 27.0728V33.1532L17.232 35.7236V27.0728L6.18359 15Z"
        fill="black"
      />
    </svg>
  )
}
