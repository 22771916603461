export const QaXSmallIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315886">
        <path id="Rectangle 34625573" d="M8 4.96875H14.25V15.25H8V4.96875Z" fill="black" />
        <path
          id="Rectangle 34625572"
          d="M0.5 1.25C0.5 0.973858 0.723858 0.75 1 0.75H12.5C12.7761 0.75 13 0.973858 13 1.25V15.25H2C1.17157 15.25 0.5 14.5784 0.5 13.75V1.25Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="14.25" cy="14" r="1.25" fill="#4268FB" />
        <path
          id="Rectangle 34625573_2"
          d="M13 4H15C15.2761 4 15.5 4.22386 15.5 4.5V14H13V4Z"
          fill="#4268FB"
        />
        <path id="Vector 4" d="M4.25 7.23775L6.09109 9.07884L9.25 5.91992" stroke="white" />
      </g>
    </svg>
  )
}
