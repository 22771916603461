import { useEffect, useRef } from 'react'

import { RelatedNewsDto } from '@/services/news'

import style from './RelatedNews.module.scss'
import { RelatedNewsDetailItem } from './related-news-detail-item'

type RelatedNewsProps = {
  previousNews?: RelatedNewsDto
  nextNews?: RelatedNewsDto
}

export const RelatedNews = ({ previousNews, nextNews }: RelatedNewsProps) => {
  const previousRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (previousRef.current && nextRef.current) {
      if (previousRef.current.clientWidth > nextRef.current.clientWidth) {
        nextRef.current.style.minWidth = previousRef.current.clientWidth + 'px'
      } else {
        previousRef.current.style.minWidth = nextRef.current.clientWidth + 'px'
      }
    }
  }, [previousNews, nextNews])

  return (
    <div className={style.container} data-testid="data-test-id-related-news-detail">
      {previousNews && <RelatedNewsDetailItem {...previousNews} type="PREV" ref={previousRef} />}
      {nextNews && <RelatedNewsDetailItem {...nextNews} type="NEXT" ref={nextRef} />}
    </div>
  )
}
