export const StoreIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 12.02L12.84 6H3V10.38H9.37L9.76 12.02L10.78 16.4H35.99L37 12.02H14.25Z"
        fill="black"
      />
      <path
        d="M12.91 29.8C11.72 30.99 11.72 32.92 12.91 34.11C14.1 35.3 16.02 35.3 17.21 34.11C18.4 32.92 18.4 30.99 17.21 29.8C16.02 28.61 14.1 28.61 12.91 29.8Z"
        fill="black"
      />
      <path
        d="M29.56 29.8C28.37 30.99 28.37 32.92 29.56 34.11C30.75 35.3 32.67 35.3 33.86 34.11C35.05 32.92 35.05 30.99 33.86 29.8C32.67 28.61 30.75 28.61 29.56 29.8Z"
        fill="black"
      />
      <path d="M13.02 26.11L11.64 20.14H35.13L33.75 26.11H13.02Z" fill="black" />
    </svg>
  )
}
