export const NetworkBlockingIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0889 5C7.72222 5 5 7.72222 5 11.0889C5 12.7889 5.7 14.3222 6.81111 15.4222C5.37778 19.6444 6.01111 24.4222 8.72222 28.1556L6.04444 30.8333L9.14444 33.9333L28.1667 14.9222C29.0889 16.4111 29.6 18.1333 29.6 19.9444C29.6 20.9333 29.4444 21.8889 29.1667 22.8111C29.0889 22.8111 29 22.8 28.9222 22.8C25.5556 22.8 22.8333 25.5222 22.8333 28.8889C22.8333 28.9556 22.8333 29.0222 22.8333 29.0889C21.9444 29.3556 21 29.5 20.0444 29.5C19.3556 29.5 18.6889 29.4222 18.0333 29.2778L14.5111 32.8C16.2778 33.5556 18.1556 33.9444 20.0444 33.9444C21.5778 33.9444 23.1222 33.6778 24.5889 33.1778C25.6889 34.2889 27.2222 34.9778 28.9111 34.9778C32.2778 34.9778 35 32.2556 35 28.8889C35 27.2111 34.3222 25.7 33.2333 24.6C34.7333 20.3444 34.1111 15.5 31.3667 11.7333L34.0444 9.05556L30.9444 5.95556L11.9333 24.9556C11.0111 23.4667 10.5 21.7444 10.5 19.9333C10.5 18.9778 10.6333 18.0556 10.9111 17.1667C10.9778 17.1667 11.0333 17.1667 11.1 17.1667C14.4667 17.1667 17.1889 14.4444 17.1889 11.0778C17.1889 10.9889 17.1889 10.9 17.1778 10.8222C18.1 10.5333 19.0667 10.3778 20.0556 10.3778C20.7444 10.3778 21.4111 10.4556 22.0667 10.6L25.5889 7.07778C23.8222 6.32222 21.9444 5.93333 20.0556 5.93333C18.4667 5.93333 16.8889 6.22222 15.3778 6.75556C14.2778 5.66667 12.7667 5 11.1 5H11.0889ZM11.0889 13.4444C9.78889 13.4444 8.72222 12.3889 8.72222 11.0778C8.72222 9.76667 9.77778 8.71111 11.0889 8.71111C12.4 8.71111 13.4556 9.76667 13.4556 11.0778C13.4556 12.3889 12.4 13.4444 11.0889 13.4444ZM28.9111 31.2444C27.6 31.2444 26.5444 30.1889 26.5444 28.8778C26.5444 27.5667 27.6 26.5111 28.9111 26.5111C30.2222 26.5111 31.2778 27.5667 31.2778 28.8778C31.2778 30.1889 30.2222 31.2444 28.9111 31.2444Z"
        fill="black"
      />
    </svg>
  )
}
