import {
  AnalysisLargeIcon,
  AnalysisMediumIcon,
  AnalysisXLargeIcon,
  AnalysisXSmallIcon,
  BusinessLargeIcon,
  BusinessMediumIcon,
  BusinessXLargeIcon,
  BusinessXSmallIcon,
  CommunityLargeIcon,
  CommunityMediumIcon,
  CommunityXLargeIcon,
  CommunityXSmallIcon,
  MarketingLargeIcon,
  MarketingMediumIcon,
  MarketingXLargeIcon,
  MarketingXSmallIcon,
  QaLargeIcon,
  QaMediumIcon,
  QaXLargeIcon,
  QaXSmallIcon,
  SupportLargeIcon,
  SupportMediumIcon,
  SupportXLargeIcon,
  SupportXSmallIcon,
  UseCaseLargeIcon,
  UseCaseMediumIcon,
  UseCaseXSmallIcon,
  UseCaseXlargeIcon,
} from '@/components/icons'
import { BoxIcon } from '@/components/solution-icon/BoxIcon'

export const QaIcon = () => {
  return BoxIcon({
    Icon1: QaXSmallIcon,
    Icon2: QaMediumIcon,
    Icon3: QaLargeIcon,
    Icon4: QaXLargeIcon,
  })
}

export const UseCaseIcon = () => {
  return BoxIcon({
    Icon1: UseCaseXSmallIcon,
    Icon2: UseCaseMediumIcon,
    Icon3: UseCaseLargeIcon,
    Icon4: UseCaseXlargeIcon,
  })
}

export const CommunityIcon = () => {
  return BoxIcon({
    Icon1: CommunityXSmallIcon,
    Icon2: CommunityMediumIcon,
    Icon3: CommunityLargeIcon,
    Icon4: CommunityXLargeIcon,
  })
}

export const AnalysisIcon = () => {
  return BoxIcon({
    Icon1: AnalysisXSmallIcon,
    Icon2: AnalysisMediumIcon,
    Icon3: AnalysisLargeIcon,
    Icon4: AnalysisXLargeIcon,
  })
}

export const BusinessIcon = () => {
  return BoxIcon({
    Icon1: BusinessXSmallIcon,
    Icon2: BusinessMediumIcon,
    Icon3: BusinessLargeIcon,
    Icon4: BusinessXLargeIcon,
  })
}

export const MarketingIcon = () => {
  return BoxIcon({
    Icon1: MarketingXSmallIcon,
    Icon2: MarketingMediumIcon,
    Icon3: MarketingLargeIcon,
    Icon4: MarketingXLargeIcon,
  })
}

export const SupportIcon = () => {
  return BoxIcon({
    Icon1: SupportXSmallIcon,
    Icon2: SupportMediumIcon,
    Icon3: SupportLargeIcon,
    Icon4: SupportXLargeIcon,
  })
}
