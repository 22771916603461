import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { t } from 'i18next'

import { flatKeyAndValueOfLocaleEn } from '../i18n'

export const useLocale = () => {
  const { i18n } = useTranslation()

  return i18n.language
}

function useBuildT() {
  const { i18n } = useTranslation()
  const buildT = useCallback(
    (key: string) => {
      return t(key, flatKeyAndValueOfLocaleEn[key])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  return buildT
}

export default useBuildT
