export const DiscordBotIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.1089 10.0525C29.0089 9.07789 26.7876 8.38727 24.5088 8C24.1961 8.56154 23.9152 9.14244 23.6599 9.73624C21.228 9.36834 18.7641 9.36834 16.3322 9.73624C16.0833 9.14244 15.796 8.56154 15.4833 8C13.2045 8.39372 10.9832 9.08435 8.87683 10.059C4.70234 16.3133 3.57254 22.4063 4.13425 28.4154C6.57894 30.242 9.31726 31.6362 12.2343 32.5269C12.8918 31.6362 13.4726 30.6874 13.9705 29.6998C13.0258 29.3448 12.113 28.8995 11.2386 28.3831C11.4683 28.2153 11.6917 28.041 11.9088 27.8732C17.0343 30.313 22.9705 30.313 28.0961 27.8732C28.3131 28.054 28.5365 28.2282 28.7663 28.3831C27.8918 28.9059 26.9791 29.3448 26.028 29.7063C26.5259 30.6938 27.1067 31.6426 27.7642 32.5333C30.6812 31.6426 33.4195 30.2549 35.8642 28.4219C36.5281 21.4511 34.7281 15.4097 31.1089 10.059M14.6854 24.7235C13.1088 24.7235 11.8003 23.2777 11.8003 21.4962C11.8003 19.7148 13.0577 18.2561 14.679 18.2561C16.3003 18.2561 17.5897 19.7148 17.5641 21.4962C17.5386 23.2777 16.2939 24.7235 14.6854 24.7235ZM25.3195 24.7235C23.7365 24.7235 22.4408 23.2777 22.4408 21.4962C22.4408 19.7148 23.6982 18.2561 25.3195 18.2561C26.9408 18.2561 28.2238 19.7148 28.1982 21.4962C28.1727 23.2777 26.928 24.7235 25.3195 24.7235Z"
        fill="black"
      />
    </svg>
  )
}
