export const solution = {
  main: {
    risk_management: '리스크 관리',
    risk_management_description:
      '보호, 탐지, 제재까지 체계적이고 면밀한 보안으로 게임의 안정성을 높입니다.',
    personalized_recommendation: '개인화 추천',
    personalized_recommendation_description:
      '데이터를 정밀하게 분석하여 유저별 맞춤형 콘텐츠를 적재적소에 제공합니다.',
    matchmaking: '매치메이킹',
    matchmaking_description:
      '게임 규칙과 유저별 상황을 분석해 최적의 상대와 매칭하여 게임 경험을 향상합니다.',
    creators: '크리에이터',
    creators_description: '크리에이터와의 협업 강화를 통해 시장 및 콘텐츠 점유율을 확대합니다.',
    operation: '운영',
    operation_description:
      '다양한 도구와 시스템을 통해 안정적인 게임 운영을 지원하여 유저 만족도를 높입니다.',
    game_scale_ai: 'GameScale AI',
    game_scale_ai_description:
      'AI로 게임에 필요한 문제 예측, 분석, 판단 그리고 콘텐츠 추천 및 생성을 실시간 처리합니다.',
    qa: 'QA',
    qa_description: '다양한 지원 도구를 활용한 전문적인 테스트로 게임의 품질을 관리합니다.',
    user_care: '유저 케어',
    user_care_description:
      '유저 피드백 채널과 체계적인 운영 기능으로 신뢰받는 게임 환경을 조성합니다.',
    community: '커뮤니티',
    community_description: '건강하고 원활한 소통 환경을 조성함으로써로 게임의 발전을 도모합니다.',
    analytics: '분석',
    analytics_description:
      '게임 및 IP 콘텐츠에 특화된 데이터 분석을 통해 심층적인 인사이트를 제공합니다.',
    business: '비즈니스',
    business_description:
      '유저의 긍정적인 플레이 경험이 게임의 매출 상승으로 이어지는 로드맵을 제시합니다.',
    marketing: '마케팅',
    marketing_description: '유저의 모객, 이탈 방지, 복귀 유도를 위한 마케팅의 전반을 지원합니다.',
    development_support: '개발 지원',
    development_support_description:
      '게임 구현과 출시를 위한 다양한 기능의 개발을 지원하여 개발 효율성을 높입니다.',
  },
}
