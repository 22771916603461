export const CommunityMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="27"
        y="9"
        width="15.75"
        height="20.25"
        rx="0.75"
        transform="rotate(90 27 9)"
        fill="#4268FB"
      />
      <rect
        x="23.25"
        y="5.25"
        width="15.75"
        height="20.25"
        rx="0.75"
        transform="rotate(90 23.25 5.25)"
        fill="black"
      />
      <circle cx="13.125" cy="13.125" r="1.3125" fill="white" />
      <circle cx="17.998" cy="13.125" r="1.3125" fill="white" />
      <circle cx="8.25" cy="13.125" r="1.3125" fill="white" />
    </svg>
  )
}
