import { BaseIconProps } from '../base-icon'

function ArrowUpIcon({ ...passProps }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      className={passProps.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1151 12.5822L9.01167 7.94722L9.01167 21.3437L6.98833 21.3437L6.98833 7.94722L1.88493 12.5822L0.499999 11.1553L8 4.34375L15.5 11.1553L14.1151 12.5822Z"
        fill="currentColor"
      />
      <rect x="2" y="0.65625" width="12" height="2" fill="currentColor" />
    </svg>
  )
}

export default ArrowUpIcon
