export const RetentionKeeperIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 20C6 26.63 11.37 32 18 32H23.77V23.43H18C16.11 23.43 14.57 21.9 14.57 20C14.57 18.1 16.11 16.57 18 16.57H23.77V8H18C11.37 8 6 13.37 6 20Z"
        fill="black"
      />
      <path d="M34 8H27.83V16.57H34V8Z" fill="black" />
      <path d="M34 23.43H27.83V32H34V23.43Z" fill="black" />
    </svg>
  )
}
