export const QaLargeIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315886">
        <path id="Rectangle 34625573" d="M16 9.63428H29.125V31.2249H16V9.63428Z" fill="black" />
        <path
          id="Rectangle 34625572"
          d="M0.25 1.82515C0.25 1.24525 0.720101 0.775146 1.3 0.775146H25.45C26.0299 0.775146 26.5 1.24525 26.5 1.82515V31.2251H3.4C1.6603 31.2251 0.25 29.8148 0.25 28.0751V1.82515Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="29.125" cy="28.5996" r="2.625" fill="#4268FB" />
        <path
          id="Rectangle 34625573_2"
          d="M26.5 7.59961H30.7C31.2799 7.59961 31.75 8.06971 31.75 8.64961V28.5996H26.5V7.59961Z"
          fill="#4268FB"
        />
        <path
          id="Vector 4"
          d="M8.125 14.3999L11.9913 18.2662L18.625 11.6324"
          stroke="white"
          strokeWidth="2.1"
        />
      </g>
    </svg>
  )
}
