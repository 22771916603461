import { useState } from 'react'

import { ArrowDown } from '@/components/icons'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import {
  SolutionCardGroup,
  SolutionCardGroupProps,
} from '@/pages/home/components/solution-card-group'

import style from './ExpandedSolutionCard.module.scss'

type ExpandedSolutionCardProps = Omit<SolutionCardGroupProps, 'onlyRow'>

export const ExpandedSolutionCard = ({ list }: ExpandedSolutionCardProps) => {
  const isTablet = useMediaQuery(mediaQueryBreakpoints.up('sm2'))
  const [expanded, setExpanded] = useState(false)

  const handleExpanded = () => {
    setExpanded(true)
  }

  if (expanded || isTablet) {
    return <SolutionCardGroup list={list} onlyRow />
  }

  return (
    <button className={style.button} onClick={handleExpanded}>
      <p className={style.text}>Show More</p>
      <ArrowDown />
    </button>
  )
}
