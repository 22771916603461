import logo from '@/assets/images/img_community.png'
import {
  ChatIcon,
  DiscordBotIcon,
  ImageDetectionIcon,
  MessengerIcon,
  OfficialCommunityIcon,
  SocialRecommendationIcon,
  TextDetectionIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.community

export const communityBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const communitySEO = i18n.seo

const engagement: SolutionDetailData = {
  id: 'engagement',
  name: i18n.engagement.name,
  title: [i18n.engagement.title1, i18n.engagement.title2],
  description: i18n.engagement.description,
  featureItems: [
    {
      icon: <OfficialCommunityIcon />,
      title: i18n.engagement.features.officialCommunity,
      link: i18n.engagement.features.officialCommunityLink,
    },
    {
      icon: <ChatIcon />,
      title: i18n.engagement.features.chat,
      link: i18n.engagement.features.chatLink,
    },
    {
      icon: <MessengerIcon />,
      title: i18n.engagement.features.messenger,
      link: i18n.engagement.features.messengerLink,
    },
  ],
  featureDetails: [],
}

const encouragement: SolutionDetailData = {
  id: 'encouragement',
  name: i18n.encouragement.name,
  title: [i18n.encouragement.title1, i18n.encouragement.title2],
  description: i18n.encouragement.description,
  featureItems: [
    {
      icon: <SocialRecommendationIcon />,
      title: i18n.encouragement.features.socialRecommendation,
      link: i18n.encouragement.features.socialRecommendationLink,
    },
  ],
  featureDetails: [],
}

const management: SolutionDetailData = {
  id: 'management',
  name: i18n.management.name,
  title: [i18n.management.title1, i18n.management.title2],
  description: i18n.management.description,
  featureItems: [
    {
      icon: <DiscordBotIcon />,
      title: i18n.management.features.discordBot,
      link: i18n.management.features.discordBotLink,
    },
    {
      icon: <TextDetectionIcon />,
      title: i18n.management.features.textDetection,
      link: i18n.management.features.textDetectionLink,
    },
    {
      icon: <ImageDetectionIcon />,
      title: i18n.management.features.imageDetection,
      link: i18n.management.features.imageDetectionLink,
    },
  ],
  featureDetails: [],
}

export const communityNav: NavSectionItem[] = [
  {
    id: engagement.id,
    name: engagement.name,
  },
  {
    id: encouragement.id,
    name: encouragement.name,
  },
  {
    id: management.id,
    name: management.name,
  },
]

export const communitySolution: SolutionDetailData[] = [engagement, encouragement, management]
