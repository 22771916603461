import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import Container from '@/components/container'
import { Pagination } from '@/components/pagination'
import { FIRST_PAGE, MAX_PAGES_TO_SHOW } from '@/components/pagination/pagination.config'
import Skeleton from '@/components/skeleton'
import { useLocale } from '@/hooks/use-locale'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import NewsListItem from '@/pages/news/components/news-list-item'
import { useGetCategory } from '@/services/category'
import { useGetNewsList } from '@/services/news'
import { Locale } from '@/types/locales'

import styles from './news-list.module.scss'

const NewsList = () => {
  const timer = useRef<number | null>(null)
  const lang = useLocale()
  const [params, setParams] = useSearchParams()
  const newsListRef = useRef<HTMLDivElement>(null)

  const isFold = useMediaQuery('(min-width: 200px)')
  const isFoldOpen = useMediaQuery(mediaQueryBreakpoints.up('sm2'))
  const isTablet = useMediaQuery(mediaQueryBreakpoints.up('sm3'))
  const islargeDesktop = useMediaQuery(mediaQueryBreakpoints.up('lg2'))

  const [limit, setLimit] = useState(0)
  const [currentFilter, setCurrentFilter] = useState<number>(getParamByKey('category'))
  const [currentPage, setCurrentPage] = useState<number>(getParamByKey('page', FIRST_PAGE))

  const { data: filters, isFetching: isFiltersFetching } = useGetCategory({
    lang: lang as Locale,
    type: 'news',
  })
  const { data, isFetching: isDataFetching } = useGetNewsList(
    {
      langType: lang as Locale,
      categoryId: currentFilter,
      page: currentPage,
      limit,
    },
    { enabled: limit !== 0 },
  )

  function handleFilter(id: number) {
    setCurrentFilter(id)
    setCurrentPage(FIRST_PAGE)
    setParams({ page: FIRST_PAGE.toString(), category: id.toString() })
  }
  function onPageChange(page: number) {
    setCurrentPage(page)
    params.set('page', page.toString())
    setParams(params)
    const newsBannerHeight = document.getElementById('news-banner')?.clientHeight
    if (newsListRef.current && newsBannerHeight) {
      timer.current = window.setTimeout(
        () => window.scrollTo({ top: newsBannerHeight, behavior: 'smooth' }),
        0,
      )
    }
  }
  function getParamByKey(key: string, defaultValue = 0) {
    return params.get(key) !== null ? Number(params.get(key)) : defaultValue
  }

  useEffect(() => {
    if (limit !== 0) {
      setCurrentFilter(getParamByKey('category'))
      setCurrentPage(getParamByKey('page', FIRST_PAGE))
    }
  }, [params])
  useEffect(() => {
    if (limit !== 0) {
      setCurrentFilter(0)
      setCurrentPage(FIRST_PAGE)
      setParams({ page: FIRST_PAGE.toString(), category: '0' })
    }
  }, [window.location.pathname])
  useEffect(() => {
    switch (true) {
      case islargeDesktop:
        setLimit(16)
        break
      case isTablet:
        setLimit(12)
        break
      case isFoldOpen:
        setLimit(6)
        break
      case isFold:
        setLimit(4)
        break
    }
    if (limit !== 0) {
      setCurrentPage(FIRST_PAGE)
      params.set('page', FIRST_PAGE.toString())
      setParams(params)
    }
  }, [isFold, isFoldOpen, isTablet, islargeDesktop])
  useEffect(() => {
    return () => {
      timer.current && window.clearTimeout(timer.current)
    }
  }, [])

  return (
    <div className={styles['list-wrapper']} ref={newsListRef}>
      {filters && (
        <Container className={styles.filter} data-testid="news-list-filter">
          <button
            className={clsx(styles['filter-item'], {
              [styles.active]: 0 === currentFilter,
            })}
            onClick={() => handleFilter(0)}
            value="All"
            data-testid="news-list-filter-item"
          >
            All
          </button>
          {!isFiltersFetching &&
            filters &&
            filters?.map((item) => (
              <button
                key={item.id}
                className={clsx(styles['filter-item'], {
                  [styles.active]: item.id === currentFilter,
                })}
                onClick={() => handleFilter(item.id)}
                value={item.name}
                data-testid="news-list-filter-item"
              >
                {item.name}
              </button>
            ))}
        </Container>
      )}

      <Container>
        <div className={styles['card-list']} data-test-id="card-list">
          {isDataFetching
            ? Array.from({ length: limit }).map((_, index) => (
                <Skeleton key={index} className={styles.skeleton} />
              ))
            : data?.newsList.map((item) => <NewsListItem key={item.id} data={item} />)}
        </div>
      </Container>
      {data && data.pagination && (
        <Pagination
          totalCount={data.pagination.totalRecord}
          pageSize={limit}
          currentPage={currentPage}
          maxPagesToShow={MAX_PAGES_TO_SHOW}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}
export default NewsList
