export const { VITE_APP_NAME } = import.meta.env

export enum EOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum EPlacement {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export enum EDirection {
  BACK = 'back',
  FORWARD = 'forward',
}

export enum EMovement {
  LEFT_TO_RIGHT = 1,
  RIGHT_TO_LEFT = -1,
}

export const TIMESTAMP_MILLISECONDS = 1000

export const HEADER_HEIGHT_DESKTOP = 80

export const TOP_POSITION_NAV_LIST = 120

export const NEWS_DATE_FORMAT = 'YYYY-MM-DD'
