import { common } from './common'
import { header } from './header'
import { main } from './main'
import { newsDetail } from './news-detail'
import { solution } from './solution'
import { solutionDetail } from './solution-detail'

const ko = {
  header,
  main,
  solution,
  common,
  solutionDetail,
  newsDetail,
}

export default ko
