export const PersonalInformationCollectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5913 13.2859C19.5913 15.3956 17.881 17.1058 15.7713 17.1058C13.6616 17.1058 11.9514 15.3956 11.9514 13.2859C11.9514 11.1762 13.6616 9.46591 15.7713 9.46591C17.881 9.46591 19.5913 11.1762 19.5913 13.2859Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4V27.5426H27.5426V4H4ZM23.5426 8H8V23.5426H9.55507C10.1332 20.6372 12.6951 18.4497 15.7713 18.4497C18.8475 18.4497 21.4094 20.6372 21.9876 23.5426H23.5426V8Z"
        fill="black"
      />
      <path d="M31 12V31L12 31V35L35 35V12H31Z" fill="black" />
    </svg>
  )
}
