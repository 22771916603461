import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { FixedIntoView } from '@/components/fixed-into-view'
import ProgressBar from '@/components/progress-bar'
import { HEADER_HEIGHT_DESKTOP, TOP_POSITION_NAV_LIST } from '@/configs/common'
import { PathRoutes } from '@/configs/paths'
import { useTranslateData } from '@/hooks/use-translate-data'
import { NavSection } from '@/pages/solution-detail/components'
import BannerSolution from '@/pages/solution-detail/components/banner-solution/banner-solution'
import { SolutionDetailSection } from '@/pages/solution-detail/components/solution-detail-section'
import { SolutionDetailContent } from '@/pages/solution-detail/configs'

import classes from './styles.module.scss'

export function SolutionDetail() {
  const { id } = useParams()
  const solution = SolutionDetailContent.find((item) => item.id === id)
  const seo = solution?.seo

  const navSection = useTranslateData(solution?.navSection ?? [], (t, data) =>
    data.map((item) => ({ ...item, name: t(item.name) })),
  )

  const { t, i18n } = useTranslation()

  if (!id || !solution) {
    return <Navigate to={'/' + PathRoutes.MAIN} />
  }

  return (
    <>
      {seo ? (
        <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
          <title>{t(seo.title)}</title>
          <meta name="description" content={t(seo.description)} />
          <meta property="og:title" content={t(seo.ogTitle)} />
          <meta property="og:description" content={t(seo.ogDescription)} />
          <meta
            property="og:url"
            content={`https://gamescale.io/${i18n.language}/solution/${id}`}
          />
          <meta property="og:image" content={'https://gamescale.io/og-black.png'} />
        </Helmet>
      ) : (
        ''
      )}
      <div className={classes['progress-bar-container']}>
        <ProgressBar />
      </div>
      <div className={classes['solution-detail']}>
        <BannerSolution
          id={id}
          title={t(solution.banner.title)}
          subtitle={t(solution.banner.subtitle)}
          description={t(solution.banner.description)}
          logo={solution.banner.logo}
        />
        <FixedIntoView
          topToFixed={TOP_POSITION_NAV_LIST}
          fixedElement={<NavSection offsetTop={-HEADER_HEIGHT_DESKTOP} data={navSection} />}
        >
          <div data-testid="solution-container" className={classes.container} data-id={solution.id}>
            {solution?.data.map((item) => {
              return (
                <SolutionDetailSection
                  key={item.id}
                  className={classes[item.id]}
                  data={item}
                  solutionId={solution?.id ?? ''}
                />
              )
            })}
          </div>
        </FixedIntoView>
      </div>
    </>
  )
}
