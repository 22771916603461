export const ImageDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 18C15.8807 18 17 16.8807 17 15.5C17 14.1193 15.8807 13 14.5 13C13.1193 13 12 14.1193 12 15.5C12 16.8807 13.1193 18 14.5 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.195 28.12L36.005 32.93L32.935 36L28.125 31.19C25.455 33.19 22.285 34.2 19.105 34.2C15.235 34.2 11.375 32.73 8.42501 29.78C2.525 23.88 2.525 14.32 8.42501 8.41998C11.375 5.46997 15.235 4 19.105 4C22.975 4 26.835 5.46997 29.785 8.41998C35.155 13.8 35.615 22.21 31.195 28.12ZM11.495 11.5C7.62513 15.3699 7.32946 21.4918 10.608 25.7057L21.3137 15L29.1828 22.8691C30.6034 19.0449 29.7808 14.5657 26.715 11.5C24.675 9.46997 21.985 8.34998 19.105 8.34998C16.225 8.34998 13.525 9.46997 11.495 11.5Z"
        fill="black"
      />
    </svg>
  )
}
