export const MarketingXLargeIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315890">
        <circle
          id="Ellipse 249"
          cx="23"
          cy="23.0008"
          r="17.825"
          transform="rotate(45 23 23.0008)"
          fill="black"
        />
        <circle
          id="Ellipse 250"
          cx="23"
          cy="23.0022"
          r="12.075"
          transform="rotate(45 23 23.0022)"
          fill="#4268FB"
        />
        <circle
          id="Ellipse 251"
          cx="23"
          cy="23"
          r="8.05"
          transform="rotate(45 23 23)"
          fill="black"
        />
        <path
          id="Polygon 4"
          d="M22.9996 23.0034L35.8071 16.2947L30.8061 15.1969L29.7083 10.1959L22.9996 23.0034Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
