export const CommunityXLargeIcon = () => {
  return (
    <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315837">
        <rect
          id="Rectangle 34625573"
          x="37.4004"
          y="5.79956"
          width="24.15"
          height="31.05"
          rx="1.15"
          transform="rotate(90 37.4004 5.79956)"
          fill="#4268FB"
        />
        <rect
          id="Rectangle 34625572"
          x="31.6504"
          y="0.0495605"
          width="24.15"
          height="31.05"
          rx="1.15"
          transform="rotate(90 31.6504 0.0495605)"
          fill="black"
        />
        <g id="Group 1321315835">
          <circle id="Ellipse 252" cx="16.1248" cy="12.1246" r="2.0125" fill="white" />
          <circle id="Ellipse 254" cx="23.5984" cy="12.1246" r="2.0125" fill="white" />
          <circle id="Ellipse 253" cx="8.6502" cy="12.1246" r="2.0125" fill="white" />
        </g>
      </g>
    </svg>
  )
}
