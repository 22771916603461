export const Logo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 220 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9138 16.9773L17.0498 20.5247V28.6606L23.9138 25.1502V16.9773Z"
        fill="url(#paint0_linear_2731_2951)"
      />
      <path
        d="M17.6922 13.6084L10.7021 16.9817L17.0508 20.5248L23.9256 16.9817L17.6922 13.6084Z"
        fill="white"
      />
      <path
        d="M8.04688 20.5793V30.6773L17.0302 25.6979L8.04688 20.5793Z"
        fill="url(#paint1_linear_2731_2951)"
      />
      <path
        d="M0 8.67725L0.0478974 26.288L8.05669 30.6772L8.06667 13.2464L0 8.67725Z"
        fill="url(#paint2_linear_2731_2951)"
      />
      <path
        d="M14.575 1.48975L0 8.69081L8.06668 13.2503L22.4125 5.98141L14.575 1.48975Z"
        fill="white"
      />
      <path
        d="M148.894 12.6599C149.83 11.7154 150.958 11.0957 152.278 11.0957L158.111 11.0794V7.05751L152.278 7.07784C149.826 7.07784 147.73 7.95345 145.994 9.71684C144.258 11.4802 143.39 13.6003 143.39 16.0853C143.39 18.5703 144.258 20.6945 145.994 22.4538C147.734 24.2131 149.826 24.9989 152.278 24.9989L158.111 24.9944V20.9723L152.278 20.9768C150.958 20.9768 149.826 20.4674 148.894 19.5189C147.962 18.5703 147.494 17.4271 147.494 16.0894C147.494 14.7516 147.958 13.6085 148.894 12.6599Z"
        fill="white"
      />
      <path
        d="M60.3938 7.06481L53.4512 24.995L57.9159 24.9789C59.2675 21.3787 60.9562 17.2369 62.2493 13.6155C63.5425 17.2369 65.2312 21.3787 66.5828 24.9789L71.0434 24.979L64.0412 7.06481H60.3938Z"
        fill="white"
      />
      <path
        d="M75.8321 24.9683V7.00515L80.5595 7.02106L85.5774 16.4369L90.3716 7H95.099V24.9683H91.1595V14.4731L88.0594 20.5029L83.0954 20.5079L79.7716 14.4731V24.9841L75.8321 24.9683Z"
        fill="white"
      />
      <path
        d="M116.099 7.07402L116.107 11.0463H105.43L105.43 20.9289L116.107 20.9458L116.099 25L101.49 24.9789V7.06996L116.099 7.07402Z"
        fill="white"
      />
      <path
        d="M137.311 11.2136H126.525C126.152 11.2136 125.827 11.3514 125.558 11.6231C125.289 11.8948 125.153 12.2232 125.153 12.6003C125.153 12.9774 125.289 13.3058 125.558 13.5774C125.827 13.8491 126.148 13.987 126.525 13.987H133.207C134.723 13.987 136.019 14.5262 137.086 15.6088C138.153 16.6914 138.691 17.997 138.691 19.5297C138.691 21.0624 138.157 22.3721 137.086 23.4506C136.015 24.5292 134.723 24.9717 133.207 24.9717H122.367V20.6937H133.207C133.58 20.6937 133.931 20.6523 134.174 20.3715C134.416 20.0908 134.554 19.7659 134.554 19.3889C134.554 19.0118 134.443 18.6779 134.174 18.4062C133.905 18.1345 133.58 18.143 133.207 18.143H126.525C125.008 18.143 123.713 17.6037 122.646 16.5211C121.579 15.4385 121.041 14.1329 121.041 12.6003C121.041 11.0676 121.575 9.75793 122.646 8.67938C123.717 7.60083 125.008 7.05751 126.525 7.05751H137.311V11.2136Z"
        fill="white"
      />
      <path
        d="M169.946 7.06885L163.004 24.999L167.468 24.983C168.82 21.3827 170.509 17.241 171.802 13.6196C173.274 17.2834 175.067 21.0736 176.687 24.983L181.272 24.983L173.593 7.06885H169.946Z"
        fill="white"
      />
      <path
        d="M219.992 7.07402L220 11.0754L209.315 11.0923L209.315 20.9458H220L219.992 25L205.376 24.983V7.07402H219.992Z"
        fill="white"
      />
      <path
        d="M45.2844 20.9352L45.2851 17.7005H40.9727L43.0811 14.3385H48.5963V24.9458H40.792C38.3137 24.9458 35.3866 24.1668 33.632 22.407C31.8773 20.6473 31 18.5226 31 16.0371C31 13.5516 31.8733 11.431 33.632 9.67125C35.3906 7.91152 38.3137 7.03165 40.792 7.03165H48.6095L48.5963 11.0423H40.792C39.4579 11.0423 37.5051 11.654 36.5631 12.6028C35.7181 13.4502 35.1642 14.3845 35.1642 15.6073C35.1642 17.0582 35.3975 17.8871 36.384 18.9981C36.8004 19.4684 37.3704 19.9469 37.8798 20.2185V20.2104C38.555 20.5753 39.9673 20.9433 40.7921 20.9433L45.2844 20.9352Z"
        fill="white"
      />
      <path d="M113.073 14.3314H105.43V17.7005H113.073V14.3314Z" fill="white" />
      <path d="M217.562 14.3353H209.315V17.7044H217.562V14.3353Z" fill="white" />
      <path
        d="M200.632 24.9671L186.016 24.9671V7.03565L189.963 7.03563V20.9298H200.632V24.9671Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2731_2951"
          x1="20.7698"
          y1="29.3464"
          x2="20.54"
          y2="20.358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#BEBEBE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2731_2951"
          x1="8.04688"
          y1="24.0638"
          x2="18.2375"
          y2="29.8568"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1875" stopColor="#5C5C5C" />
          <stop offset="1" stopColor="#DBDBDB" stopOpacity="0.85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2731_2951"
          x1="4.00925"
          y1="8.58836"
          x2="4.00925"
          y2="30.6772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9C9C9" />
          <stop offset="1" stopColor="#8B8B8B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
