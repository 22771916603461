export const header = {
  meta: {
    title: '게임스케일 | 넥슨의 게임 서비스 솔루션',
    description:
      '게임스케일은 게임 개발부터 게임 보안/마케팅/운영까지,유저의 즐거움을 극대화하는 전문적인 솔루션을 제공합니다.',
    keywords: '솔루션, 게임 출시, 게임 퍼블리싱,게임, 게임 개발, 넥슨, 게임 ai',
    ogTitle: ' 넥슨의 게임 서비스 솔루션',
    ogDescription:
      '게임스케일은 게임 개발부터 게임 보안/마케팅/운영까지,유저의 즐거움을 극대화하는 전문적인 솔루션을 제공합니다.',
  },
  seo: {
    news: {
      title: '게임스케일 | 뉴스',
      description: '게임스케일의 최신 뉴스를 확인하세요.',
      ogTitle: '뉴스',
      ogDescription: '게임스케일의 최신 뉴스를 확인하세요.',
    },
  },
}
