export const WithdrawalIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 8H3V19.6875H9.375V32.375H30.625V19.6875H37V8ZM26.375 28.125V13.25H13.625V28.125H26.375Z"
        fill="black"
      />
    </svg>
  )
}
