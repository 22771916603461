import { QueryClient } from '@tanstack/react-query'

export const MINUTE = 1000 * 60

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: false,
      staleTime: MINUTE * 5,
    },
  },
})
