type ChevronLeftIconType = {
  width?: number
  height?: number
  className?: string
}

export const ChevronLeftIcon = ({ width = 16, height = 16, className }: ChevronLeftIconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9429 12.6666L6.27624 7.9999L10.9429 3.33323L10.0001 2.39042L4.39063 7.9999L10.0001 13.6094L10.9429 12.6666Z"
        fill="#777D8A"
      />
    </svg>
  )
}
