export const MonolakeIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.16639 33.1865C5.96975 33.1865 5 32.2168 5 31.0201V16.5081C5 12.94 6.77787 9.62977 9.75238 7.65298C10.7501 6.99094 12.0928 7.26135 12.758 8.25907C13.42 9.25679 13.1496 10.5995 12.1519 11.2647C10.3896 12.4364 9.33589 14.3977 9.33589 16.5112V31.0201C9.33589 32.2168 8.36614 33.1865 7.1695 33.1865H7.16639Z"
        fill="black"
      />
      <path
        d="M32.5383 33.1864C33.8979 33.1864 35 32.0843 35 30.7248C35 29.3652 33.8979 28.2631 32.5383 28.2631C31.1788 28.2631 30.0767 29.3652 30.0767 30.7248C30.0767 32.0843 31.1788 33.1864 32.5383 33.1864Z"
        fill="black"
      />
      <path
        d="M24.0903 7C18.2283 7 13.4604 11.7679 13.4604 17.6299V26.7897C13.4604 26.8643 13.4635 26.9389 13.4728 27.0103C13.5909 30.4355 16.4069 33.1863 19.8601 33.1863C23.3133 33.1863 26.1292 30.4355 26.2474 27.0103C26.2536 26.9389 26.2598 26.8643 26.2598 26.7897V17.6299C26.2598 16.4333 25.29 15.4635 24.0934 15.4635C22.8968 15.4635 21.927 16.4333 21.927 17.6299V26.7897C21.927 27.9273 21.0008 28.8535 19.8632 28.8535C18.7256 28.8535 17.7994 27.9273 17.7994 26.7897V17.6299C17.7994 14.1581 20.6216 11.3359 24.0934 11.3359C27.5652 11.3359 30.3874 14.1581 30.3874 17.6299V23.178C30.3874 24.3746 31.3572 25.3444 32.5538 25.3444C33.7505 25.3444 34.7202 24.3746 34.7202 23.178V17.6299C34.7202 11.7679 29.9523 7 24.0903 7Z"
        fill="black"
      />
    </svg>
  )
}
