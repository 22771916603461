export const ProductRecommendationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.5 24H19.5V33H23.5V24Z" fill="black" />
      <path d="M17 21.5001H24.5L26.87 16.5001H31V12.5H5V28.43H17V21.5001Z" fill="black" />
      <path
        d="M30.5 24.0001L30.65 18.5H28.5L26.01 24.0001V33H35.67L36 24.0001H30.5Z"
        fill="black"
      />
      <path d="M4 6H32V10H4V6Z" fill="black" />
    </svg>
  )
}
