// reference: https://github.com/mayankshubham/react-pagination
import { useMemo } from 'react'

import { FIRST_PAGE } from './pagination.config'

type UsePaginationProps = {
  totalPages: number
  currentPage: number
  maxPagesToShow: number
}

function usePagination({ totalPages, currentPage, maxPagesToShow }: UsePaginationProps) {
  const paginationRange = useMemo(() => {
    //* pageSize is 12, totalPages is 11, maxPagesToShow is 5
    //* if currentPage is 1 => minPage = 1, maxPage = 5 => range: 1, 2, 3, 4, 5
    //* if currentPage is 5 => minPage = 1, maxPage = 5 => range: 1, 2, 3, 4, 5
    //* if currentPage is 6 => minPage = 6, maxPage = 10 => range: 6, 7, 8, 9, 10
    //* if currentPage is 10 => minPage = 6, maxPage = 10 => range: 6, 7, 8, 9, 10
    //* if currentPage is 11 => minPage = 11, maxPage = 11 => range: 11
    const minPage = calculateMinPage(currentPage, maxPagesToShow)
    const maxPage = calculateMaxPage(minPage, maxPagesToShow, totalPages)

    return range(minPage, maxPage)
  }, [currentPage, maxPagesToShow, totalPages])

  return paginationRange
}

const range = (start: number, end: number) => {
  const length = end - start + 1

  //* Create an array of certain length and set the elements
  //* within it from start value to end value.

  return Array.from({ length }, (_, index) => index + start)
}

export const calculateMinPage = (currentPage: number, maxPagesToShow: number): number => {
  return Math.max(Math.floor((currentPage - 1) / maxPagesToShow) * maxPagesToShow + 1, FIRST_PAGE)
}

export const calculateMaxPage = (
  minPage: number,
  maxPagesToShow: number,
  totalPages: number,
): number => {
  return Math.min(minPage + maxPagesToShow - 1, totalPages)
}

export default usePagination
