export const TwoFactorAuthenticationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.34 15.08L20 12.55L27.66 15.08V18.78C27.66 23.31 25.61 26.75 20 28.88C14.39 26.75 12.34 23.31 12.34 18.78V15.08ZM23.77 17.9L20 16.65L16.23 17.9V18.79C16.23 21.17 16.91 23.11 20 24.65C23.09 23.12 23.77 21.18 23.77 18.79V17.9Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.96L20 5L35 9.96V17.21C35 26.09 30.99 32.83 20 37C9.01 32.83 5 26.08 5 17.21V9.96ZM31 12.85L20 9.21L9 12.85V17.21C9 24.71 12.31 29.41 20 32.69C27.69 29.4 31 24.71 31 17.21V12.85Z"
        fill="black"
      />
    </svg>
  )
}
