export const UserProfileIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.75 33.3297C32.4764 33.3297 32.2029 33.2311 31.9782 33.0339C31.4994 32.6002 31.4506 31.8608 31.8805 31.3679C32.9649 30.1356 33.5902 28.9527 34.0397 27.3162C34.2155 26.6853 34.8603 26.3206 35.4759 26.498C36.1012 26.6755 36.4626 27.3162 36.2868 27.9472C35.7397 29.9385 34.9483 31.4271 33.6293 32.9353C33.3948 33.2015 33.0724 33.3297 32.75 33.3297Z"
        fill="black"
      />
      <path
        d="M22.1592 13.3277C22.2862 13.9487 22.3644 14.5797 22.3644 15.2401C22.3644 15.4472 22.3448 15.664 22.3351 15.8711C23.5368 15.2007 24.9437 14.8064 26.5264 14.8064C31.0207 14.8064 34.6747 18.513 34.6747 23.0773C34.6747 23.7279 35.1925 24.2504 35.8374 24.2504C36.4822 24.2504 37 23.7279 37 23.0773C37 15.4672 28.9922 10.3949 22.1592 13.3277Z"
        fill="black"
      />
      <path
        d="M32.369 23.0379C32.2949 19.6678 29.7897 17.2019 26.5167 17.2019C25.2172 17.2019 23.996 17.6159 22.9701 18.4144C21.8075 19.3115 21.0259 20.5931 20.7621 22.0422C20.6448 22.683 21.0649 23.2942 21.7 23.4125C22.3351 23.5308 22.9408 23.1069 23.058 22.4661C23.2144 21.5986 23.6931 20.8198 24.3868 20.2875C25.0023 19.8143 25.7448 19.558 26.5264 19.558C28.5879 19.558 29.9069 20.9184 30.0534 23.1956C30.2391 26.0643 28.2557 28.9921 25.9695 30.53C26.1845 31.3285 26.331 32.1565 26.3897 33.0043C27.6018 32.3474 32.5 29 32.369 23.0379Z"
        fill="black"
      />
      <path
        d="M25.0218 28.0556C26.6241 27.0008 27.6891 25.1869 27.6891 23.1167C27.6891 22.4661 27.1713 21.9436 26.5264 21.9436C25.8816 21.9436 25.3638 22.4661 25.3638 23.1167C25.3638 24.3391 24.7483 25.4136 23.8103 26.0544C24.2656 26.6669 24.7411 27.341 25.0218 28.0556Z"
        fill="black"
      />
      <path
        d="M13.2489 21.4803C16.6644 21.4803 19.4333 18.6865 19.4333 15.2401C19.4333 11.7938 16.6644 9 13.2489 9C9.83326 9 7.06437 11.7938 7.06437 15.2401C7.06437 18.6865 9.83326 21.4803 13.2489 21.4803Z"
        fill="black"
      />
      <path
        d="M23.4977 34C23.4977 28.2922 18.9057 23.6589 13.2489 23.6589C7.59195 23.6589 3 28.2922 3 34H23.4977Z"
        fill="black"
      />
    </svg>
  )
}
