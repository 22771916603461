export const MarketingXSmallIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23_1264)">
        <circle
          cx="9.99805"
          cy="9.99922"
          r="7.75"
          transform="rotate(45 9.99805 9.99922)"
          fill="black"
        />
        <circle
          cx="9.99805"
          cy="10.0008"
          r="5.25"
          transform="rotate(45 9.99805 10.0008)"
          fill="#4268FB"
        />
        <circle
          cx="9.99805"
          cy="9.99858"
          r="3.5"
          transform="rotate(45 9.99805 9.99858)"
          fill="black"
        />
        <path
          d="M9.9977 9.99965L15.5662 7.08284L13.3918 6.60554L12.9145 4.43119L9.9977 9.99965Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1264">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
