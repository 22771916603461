export const SupportXLargeIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.75 13.2241H40.25V37.5241C40.25 38.0764 39.8023 38.5241 39.25 38.5241H6.75C6.19772 38.5241 5.75 38.0764 5.75 37.5241V13.2241Z"
        fill="black"
      />
      <path
        d="M5.75 8.47412C5.75 7.92184 6.19772 7.47412 6.75 7.47412H39.25C39.8023 7.47412 40.25 7.92184 40.25 8.47412V13.2241H5.75V8.47412Z"
        fill="#4268FB"
      />
      <path d="M28.75 21.8085L32.8159 25.8743L28.75 29.9402" stroke="white" strokeWidth="2.3" />
      <path d="M17.25 21.8085L13.1841 25.8743L17.25 29.9402" stroke="white" strokeWidth="2.3" />
    </svg>
  )
}
