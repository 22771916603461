import { useTranslation } from 'react-i18next'

import { Outlunk } from '@/components/icons'
import { i18nLanguage } from '@/i18n'
import LinkTo from '@/layout/header/link-to'
import { splitStringByIndices } from '@/utils/splitStringByIndices'

import styles from './hero-main.module.scss'

const HeroMain = () => {
  const { t } = useTranslation()
  const locale = i18nLanguage.main.heroSection
  const heroTxt = (splitAt: number[]) => {
    const output = splitStringByIndices(t(locale.title), splitAt)
    return (
      <>
        {output.map((item) => (
          <span key={item} data-text={item}>
            {item}
          </span>
        ))}
      </>
    )
  }
  return (
    <div className={styles['hero-wrapper']}>
      <div className={styles['container']}>
        <h1 className={styles['hero-txt']} data-testid="hero-main-title">
          {heroTxt([4])}
          {/* <span data-text={'Scale up the quality GameScale'}>
            Scale up the quality
            <br />
            GameScale
          </span> */}
        </h1>
        <LinkTo
          to={t(locale.link)}
          isExternal
          target="_blank"
          className={styles['hero-btn']}
          data-testid="hero-main-button"
        >
          {t(locale.button)}
          <Outlunk />
        </LinkTo>
      </div>
    </div>
  )
}
export default HeroMain
