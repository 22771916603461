import styles from './BoxIcon.module.scss'

type BoxIconProps = {
  Icon1: () => JSX.Element
  Icon2: () => JSX.Element
  Icon3: () => JSX.Element
  Icon4: () => JSX.Element
}

export const BoxIcon = ({ Icon1, Icon2, Icon3, Icon4 }: BoxIconProps) => {
  return (
    <div data-testid="data-test-id-box-icon" className={styles.box}>
      <Icon1 />
      <Icon2 />
      <Icon3 />
      <Icon4 />
    </div>
  )
}
