export const CheatDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.27 22.38V12.39H16.93V22.38H21.27Z" fill="black" />
      <path
        d="M19.1 24.31C17.9 24.31 16.93 25.28 16.93 26.48C16.93 27.68 17.9 28.65 19.1 28.65C20.3 28.65 21.27 27.68 21.27 26.48C21.27 25.28 20.3 24.31 19.1 24.31Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.19 29.12L36 33.93L32.93 37L28.12 32.19C25.45 34.19 22.28 35.2 19.1 35.2C15.23 35.2 11.37 33.73 8.42 30.78C2.52 24.88 2.52 15.32 8.42 9.42C11.37 6.47 15.23 5 19.1 5C22.97 5 26.83 6.47 29.78 9.42C35.15 14.8 35.61 23.21 31.19 29.12ZM11.49 12.5C7.29999 16.69 7.29999 23.52 11.49 27.71C13.53 29.74 16.22 30.86 19.1 30.86C21.98 30.86 24.68 29.74 26.71 27.71C30.9 23.52 30.9 16.69 26.71 12.5C24.67 10.47 21.98 9.35 19.1 9.35C16.22 9.35 13.52 10.47 11.49 12.5Z"
        fill="black"
      />
    </svg>
  )
}
