import logo from '@/assets/images/img_data.png'
import {
  DataAnalysisIcon,
  DataExtractionIcon,
  MonolakeIcon,
  NXLogReportIcon,
  PcCafeAffiliationIcon,
  TRENDiIcon,
  UserProfileIcon,
  UxAnalysisIcon,
  UxResearchPlatformIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.analytics

export const analyticsBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const analyticsSEO = i18n.seo

const data: SolutionDetailData = {
  id: 'data',
  name: i18n.data.name,
  title: [i18n.data.title],
  description: i18n.data.description,
  featureItems: [
    {
      icon: <DataExtractionIcon />,
      title: i18n.data.features.dataExtractionTool,
      link: i18n.data.features.dataExtractionToolLink,
    },
    {
      icon: <UserProfileIcon />,
      title: i18n.data.features.userProfile,
      link: i18n.data.features.userProfileLink,
    },
    {
      icon: <MonolakeIcon />,
      title: i18n.data.features.monolake,
      link: i18n.data.features.monolakeLink,
    },
    {
      icon: <UxResearchPlatformIcon />,
      title: i18n.data.features.uxResearchPlatform,
      link: i18n.data.features.uxResearchPlatformLink,
    },
  ],
  featureDetails: [],
}

const analysis: SolutionDetailData = {
  id: 'analysis',
  name: i18n.analysis.name,
  title: [i18n.analysis.title],
  description: i18n.analysis.description,
  featureItems: [
    {
      icon: <DataAnalysisIcon />,
      title: i18n.analysis.features.dataAnalysis,
      link: i18n.analysis.features.dataAnalysisLink,
    },
    {
      icon: <UxAnalysisIcon />,
      title: i18n.analysis.features.uxAnalysis,
      link: i18n.analysis.features.uxAnalysisLink,
    },
  ],
  featureDetails: [],
}

const report: SolutionDetailData = {
  id: 'report',
  name: i18n.report.name,
  title: [i18n.report.title],
  description: i18n.report.description,
  featureItems: [
    {
      icon: <NXLogReportIcon />,
      title: i18n.report.features.nxLogReport,
      link: i18n.report.features.nxLogReportLink,
    },
  ],
  featureDetails: [],
}

const trends: SolutionDetailData = {
  id: 'trends',
  name: i18n.trends.name,
  title: [i18n.trends.title],
  description: i18n.trends.description,
  featureItems: [
    {
      icon: <TRENDiIcon />,
      title: i18n.trends.features.trendi,
      link: i18n.trends.features.trendiLink,
    },
    {
      icon: <PcCafeAffiliationIcon />,
      title: i18n.trends.features.pcCafeAffiliation,
      link: i18n.trends.features.pcCafeAffiliationLink,
    },
  ],
  featureDetails: [],
}

export const analyticsNav: NavSectionItem[] = [
  {
    id: data.id,
    name: data.name,
  },
  {
    id: analysis.id,
    name: analysis.name,
  },
  {
    id: report.id,
    name: report.name,
  },
  {
    id: trends.id,
    name: trends.name,
  },
]

export const analyticsSolution: SolutionDetailData[] = [data, analysis, report, trends]
