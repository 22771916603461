import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import en from './locales/en'
import ko from './locales/ko'

const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
}

type NestedObject = {
  [key: string]: string | NestedObject
}

function convertKeys(inputObject: NestedObject): NestedObject {
  function convert(obj: NestedObject, parentKey = ''): NestedObject {
    const result: NestedObject = {}

    for (const [key, value] of Object.entries(obj)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key

      if (typeof value === 'object' && value !== null) {
        result[key] = convert(value, newKey)
      } else {
        result[key] = newKey
      }
    }

    return result
  }

  return convert(inputObject)
}

function createObjectFromKeysAndValues(
  keysObject: NestedObject,
  inputObject: NestedObject,
): Record<string, string> {
  const result: NestedObject = {}

  function populateResult(obj: NestedObject, parentKey = '', inputParentObj: NestedObject): void {
    for (const [key, value] of Object.entries(obj)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key

      if (typeof value === 'object' && value !== null) {
        populateResult(value, newKey, inputParentObj[key] as NestedObject)
      } else {
        result[newKey] = inputParentObj[key]
      }
    }
  }

  populateResult(keysObject, '', inputObject)
  return result as Record<string, string>
}
export const i18nLanguage = convertKeys(en) as typeof en
export const flatKeyAndValueOfLocaleEn = createObjectFromKeysAndValues(i18nLanguage, en)

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      lookupCookie: 'i18nextLng',
      lookupLocalStorage: 'i18nextLng',
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.', // we do not use keys in form messages.welcome
    react: {
      useSuspense: false,
    },
  })

export default i18n
