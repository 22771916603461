export const NexonLoginIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 15.6109V31.7358L33 37V3L7 15.6109ZM11 18.1165V28.4645L17.3567 29.7516L18.7694 24.8071C17.1624 24.2881 16 22.7798 16 21C16 18.7909 17.7909 17 20 17C22.2091 17 24 18.7909 24 21C24 22.7798 22.8376 24.2881 21.2306 24.8071L22.9679 30.8877L29 32.109V9.38583L11 18.1165Z"
        fill="black"
      />
    </svg>
  )
}
