import { createBrowserRouter, redirect } from 'react-router-dom'

import { locales } from '@/configs/locales'
import { PathRoutes } from '@/configs/paths'
import i18n from '@/i18n/i18n'
import MainLayout from '@/layout/main-layout'
import ErrorPage from '@/pages/error'
import HomePage from '@/pages/home/home'
import NewsPage from '@/pages/news'
import NewsDetail from '@/pages/news-detail'
import { SolutionDetail } from '@/pages/solution-detail'
import { getNewsByNewsId } from '@/services/news'
import { Locale } from '@/types/locales'
import { userLang } from '@/utils/user-lang'

const router = () =>
  createBrowserRouter([
    {
      element: <MainLayout />,
      loader: ({ params }) => {
        if (params.lang) return null
        if (userLang().isKo) {
          return redirect(`/${locales.KO}`)
        }
        return redirect(`/${locales.EN}`)
      },
      errorElement: <ErrorPage />,
      children: [
        {
          path: ':lang',
          loader: ({ params }) => {
            if (params.lang === locales.KO || params.lang === locales.EN) {
              i18n.changeLanguage(params.lang)
              return null
            }
            return redirect(`/${locales.EN}`)
          },
          children: [
            {
              path: PathRoutes.MAIN,
              element: <HomePage />,
            },
            {
              path: PathRoutes.NEWS,
              element: <NewsPage />,
            },
            {
              path: PathRoutes.SOLUTION_DETAIL,
              element: <SolutionDetail />,
            },
            {
              path: PathRoutes.NEWS_DETAIL,
              loader: async ({ params }) => {
                const { id, lang } = params
                try {
                  const { detail } = await getNewsByNewsId(id as string, lang as Locale)
                  if (!detail.news) {
                    throw new Response('Page Not Found', { status: 404 })
                  }
                  return null
                } catch (error) {
                  throw new Response('Page Not Found', { status: 404 })
                }
              },
              element: <NewsDetail />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      loader: ({ params }) => {
        if (params['*']) throw new Response('Page Not Found', { status: 404 })
        if (userLang().isKo) {
          return redirect(`/${locales.KO}`)
        }
        return redirect(`/${locales.EN}`)
      },
      errorElement: <ErrorPage />,
    },
  ])

export default router
