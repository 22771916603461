import logo from '@/assets/images/img_marketing.png'
import {
  ADCreatorIcon,
  CouponsIcon,
  CreatorsIcon,
  CustomPortalIcon,
  DataExtractionIcon,
  GuideRecommendationIcon,
  NexonAdvertisementIcon,
  NotificationsIcon,
  PcCafeAffiliationIcon,
  PersonalInformationCollectionIcon,
  RetentionKeeperIcon,
  TRENDiIcon,
  UserProfileIcon,
  UxResearchPlatformIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.marketing

export const marketingBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const marketingSEO = i18n.seo

const targetAnalysis: SolutionDetailData = {
  id: 'targetAnalysis',
  name: i18n.targetAnalysis.name,
  title: [i18n.targetAnalysis.title1, i18n.targetAnalysis.title2],
  description: i18n.targetAnalysis.description,
  featureItems: [
    {
      icon: <UserProfileIcon />,
      title: i18n.targetAnalysis.features.userProfile,
      link: i18n.targetAnalysis.features.userProfileLink,
    },
    {
      icon: <DataExtractionIcon />,
      title: i18n.targetAnalysis.features.dataExtractionTool,
      link: i18n.targetAnalysis.features.dataExtractionToolLink,
    },
  ],
  featureDetails: [],
}

const adCreation: SolutionDetailData = {
  id: 'adCreation',
  name: i18n.adCreation.name,
  title: [i18n.adCreation.title1, i18n.adCreation.title2],
  description: i18n.adCreation.description,
  featureItems: [
    {
      icon: <ADCreatorIcon />,
      title: i18n.adCreation.features.adCreator,
      link: i18n.adCreation.features.adCreatorLink,
    },
  ],
  featureDetails: [],
}

const marketingMedia: SolutionDetailData = {
  id: 'marketingMedia',
  name: i18n.marketingMedia.name,
  title: [i18n.marketingMedia.title],
  description: i18n.marketingMedia.description,
  featureItems: [
    {
      icon: <NexonAdvertisementIcon />,
      title: i18n.marketingMedia.features.nexonAdvertisement,
      link: i18n.marketingMedia.features.nexonAdvertisementLink,
    },
    {
      icon: <CustomPortalIcon />,
      title: i18n.marketingMedia.features.customPortal,
      link: i18n.marketingMedia.features.customPortalLink,
    },
    {
      icon: <PcCafeAffiliationIcon />,
      title: i18n.marketingMedia.features.pcCafeAffiliation,
      link: i18n.marketingMedia.features.pcCafeAffiliationLink,
    },
    {
      icon: <NotificationsIcon />,
      title: i18n.marketingMedia.features.notifications,
      link: i18n.marketingMedia.features.notificationsLink,
    },
  ],
  featureDetails: [],
}

const retention: SolutionDetailData = {
  id: 'retention',
  name: i18n.retention.name,
  title: [i18n.retention.title],
  description: i18n.retention.description,
  featureItems: [
    {
      icon: <RetentionKeeperIcon />,
      title: i18n.retention.features.retentionKeeper,
      link: i18n.retention.features.retentionKeeperLink,
    },
    {
      icon: <GuideRecommendationIcon />,
      title: i18n.retention.features.guideRecommendation,
      link: i18n.retention.features.guideRecommendationLink,
    },
  ],
  featureDetails: [],
}

const creatorMarketing: SolutionDetailData = {
  id: 'creatorMarketing',
  name: i18n.creatorMarketing.name,
  title: [i18n.creatorMarketing.title],
  description: i18n.creatorMarketing.description,
  featureItems: [
    {
      icon: <CreatorsIcon />,
      title: i18n.creatorMarketing.features.creators,
      link: i18n.creatorMarketing.features.creatorsLink,
    },
  ],
  featureDetails: [],
}

const promotionManagement: SolutionDetailData = {
  id: 'promotionManagement',
  name: i18n.promotionManagement.name,
  title: [i18n.promotionManagement.title1, i18n.promotionManagement.title2],
  description: i18n.promotionManagement.description,
  featureItems: [
    {
      icon: <PersonalInformationCollectionIcon />,
      title: i18n.promotionManagement.features.personalInformationCollectionSystem,
      link: i18n.promotionManagement.features.personalInformationCollectionSystemLink,
    },
    {
      icon: <CouponsIcon />,
      title: i18n.promotionManagement.features.coupons,
      link: i18n.promotionManagement.features.couponsLink,
    },
  ],
  featureDetails: [],
}

const marketInsights: SolutionDetailData = {
  id: 'marketInsights',
  name: i18n.marketInsights.name,
  title: [i18n.marketInsights.title],
  description: i18n.marketInsights.description,
  featureItems: [
    {
      icon: <TRENDiIcon />,
      title: i18n.marketInsights.features.trendi,
      link: i18n.marketInsights.features.trendiLink,
    },
    {
      icon: <PcCafeAffiliationIcon />,
      title: i18n.marketInsights.features.pcCafeAffiliation,
      link: i18n.marketInsights.features.pcCafeAffiliationLink,
    },
    {
      icon: <UxResearchPlatformIcon />,
      title: i18n.marketInsights.features.uxResearchPlatform,
      link: i18n.marketInsights.features.uxResearchPlatformLink,
    },
  ],
  featureDetails: [],
}

export const marketingNav: NavSectionItem[] = [
  {
    id: targetAnalysis.id,
    name: targetAnalysis.name,
  },
  {
    id: adCreation.id,
    name: adCreation.name,
  },
  {
    id: marketingMedia.id,
    name: marketingMedia.name,
  },
  {
    id: retention.id,
    name: retention.name,
  },
  {
    id: creatorMarketing.id,
    name: creatorMarketing.name,
  },
  {
    id: promotionManagement.id,
    name: promotionManagement.name,
  },
  {
    id: marketInsights.id,
    name: marketInsights.name,
  },
]

export const marketingSolution: SolutionDetailData[] = [
  targetAnalysis,
  adCreation,
  marketingMedia,
  retention,
  creatorMarketing,
  promotionManagement,
  marketInsights,
]
