import {
  AiImage,
  CreatorImage,
  GameServiceOperationImage,
  MatchMakingImage,
  PersonalizedImage,
  RiskImage,
} from '@/assets/images'
import { i18nLanguage } from '@/i18n'
import {
  AnalysisIcon,
  BusinessIcon,
  CommunityIcon,
  MarketingIcon,
  QaIcon,
  SupportIcon,
  UseCaseIcon,
} from '@/pages/solution-detail/components'

import { PathRoutes } from './paths'
import { SolutionDetailId } from './solution-detail'

export const solutionList = [
  {
    id: 1,
    title: i18nLanguage.solution.main.risk_management,
    description: i18nLanguage.solution.main.risk_management_description,
    image: RiskImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.riskManagement}`,
  },
  {
    id: 2,
    title: i18nLanguage.solution.main.personalized_recommendation,
    description: i18nLanguage.solution.main.personalized_recommendation_description,
    image: PersonalizedImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.personalizedRecommendation}`,
  },
  {
    id: 3,
    title: i18nLanguage.solution.main.creators,
    description: i18nLanguage.solution.main.creators_description,
    image: CreatorImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.creator}`,
  },
  {
    id: 4,
    title: i18nLanguage.solution.main.matchmaking,
    description: i18nLanguage.solution.main.matchmaking_description,
    image: MatchMakingImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.matchmaking}`,
  },
  {
    id: 5,
    title: i18nLanguage.solution.main.game_scale_ai,
    description: i18nLanguage.solution.main.game_scale_ai_description,
    image: AiImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.gamescaleAI}`,
  },
  {
    id: 6,
    title: i18nLanguage.solution.main.operation,
    description: i18nLanguage.solution.main.operation_description,
    image: GameServiceOperationImage,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.operation}`,
  },
]

export const solutionListMore = [
  {
    id: 1,
    title: i18nLanguage.solution.main.qa,
    description: i18nLanguage.solution.main.qa_description,
    Icon: QaIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.qa}`,
  },
  {
    id: 2,
    title: i18nLanguage.solution.main.user_care,
    description: i18nLanguage.solution.main.user_care_description,
    Icon: UseCaseIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.userCare}`,
  },
  {
    id: 3,
    title: i18nLanguage.solution.main.community,
    description: i18nLanguage.solution.main.community_description,
    Icon: CommunityIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.community}`,
  },
  {
    id: 4,
    title: i18nLanguage.solution.main.analytics,
    description: i18nLanguage.solution.main.analytics_description,
    Icon: AnalysisIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.analytics}`,
  },
  {
    id: 5,
    title: i18nLanguage.solution.main.business,
    description: i18nLanguage.solution.main.business_description,
    Icon: BusinessIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.business}`,
  },
  {
    id: 6,
    title: i18nLanguage.solution.main.marketing,
    description: i18nLanguage.solution.main.marketing_description,
    Icon: MarketingIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.marketing}`,
  },
  {
    id: 7,
    title: i18nLanguage.solution.main.development_support,
    description: i18nLanguage.solution.main.development_support_description,
    Icon: SupportIcon,
    link: `/${PathRoutes.SOLUTION}/${SolutionDetailId.developmentSupport}`,
  },
]
