import { ElementRef, ReactNode, useCallback, useEffect, useRef } from 'react'

import { HEADER_HEIGHT_DESKTOP } from '@/configs/common'

import classes from './styles.module.scss'

export interface FixedIntoViewProps {
  topToFixed?: number
  children?: ReactNode
  fixedElement: ReactNode
}

export function FixedIntoView({ topToFixed = 0, children, fixedElement }: FixedIntoViewProps) {
  const containerRef = useRef<ElementRef<'div'>>(null)
  const fixedElementRef = useRef<ElementRef<'div'>>(null)

  const handleScroll = useCallback(() => {
    if (!containerRef.current || !fixedElementRef.current) return

    const rect = containerRef.current.getBoundingClientRect()
    const top = rect.top
    const bottom = rect.bottom

    // 250 (marketing padding bottom) + 200 (top) + 138 (fixed element height)
    const fixedBottomPosition = 588
    const paddingBottom = 250

    if (top <= HEADER_HEIGHT_DESKTOP && bottom >= fixedBottomPosition) {
      fixedElementRef.current.style.position = 'fixed'
      fixedElementRef.current.style.top = `${topToFixed + HEADER_HEIGHT_DESKTOP}px`
      fixedElementRef.current.style.bottom = 'unset'
      return
    }

    if (top > HEADER_HEIGHT_DESKTOP) {
      fixedElementRef.current.style.position = 'absolute'
      fixedElementRef.current.style.top = `${topToFixed}px`
      fixedElementRef.current.style.bottom = 'unset'
      return
    }

    fixedElementRef.current.style.position = 'absolute'
    fixedElementRef.current.style.top = 'unset'
    fixedElementRef.current.style.bottom = `${paddingBottom}px`
  }, [topToFixed])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div ref={containerRef} className={classes.container}>
      <div ref={fixedElementRef} className={classes['fixed-element']}>
        {fixedElement}
      </div>
      {children}
    </div>
  )
}
