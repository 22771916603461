export const CreatorsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6309 16.0427L6.17165 13.1992C6.08582 13.1798 6.00276 13.2462 6.00276 13.3348V31.1847C6.00276 31.2484 6.04706 31.3066 6.11074 31.3204L21.8703 34.8837C21.9035 34.8921 21.9395 34.8837 21.9699 34.8699L33.928 28.1502C34.0388 28.0893 34.0138 27.9232 33.8892 27.8955L18.7361 24.5121V16.1783C18.7361 16.1146 18.6918 16.0565 18.6281 16.0427H18.6309Z"
        fill="black"
      />
      <path
        d="M33.8559 8.51079L18.401 5.00283C18.3677 4.99452 18.3345 5.00283 18.3041 5.01667L6.07188 11.6034C5.96114 11.6644 5.98052 11.8305 6.10511 11.8609L21.3441 15.5018C21.3773 15.5101 21.4133 15.5046 21.441 15.4879L33.8892 8.76551C34.0027 8.7046 33.9778 8.5357 33.8532 8.50802L33.8559 8.51079Z"
        fill="black"
      />
    </svg>
  )
}
