export const CouponsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 9H5V17C6.65685 17 8 18.3431 8 20C8 21.6569 6.65685 23 5 23V31H24V9Z"
        fill="black"
      />
      <path
        d="M27 9V31H35V23C33.3431 23 32 21.6569 32 20C32 18.3431 33.3431 17 35 17V9H27Z"
        fill="black"
      />
    </svg>
  )
}
