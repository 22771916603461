export const DataAnalysisIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18H12V25H15V18Z" fill="black" />
      <path d="M17.5 13H20.5V25H17.5V13Z" fill="black" />
      <path d="M26 16H23V25H26V16Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.195 28.12L36.005 32.93L32.935 36L28.125 31.19C25.455 33.19 22.285 34.2 19.105 34.2C15.235 34.2 11.375 32.73 8.425 29.78C2.525 23.88 2.525 14.32 8.425 8.42C11.375 5.47 15.235 4 19.105 4C22.975 4 26.835 5.47 29.785 8.42C35.155 13.8 35.615 22.21 31.195 28.12ZM11.495 11.5C7.305 15.69 7.305 22.52 11.495 26.71C13.535 28.74 16.225 29.86 19.105 29.86C21.985 29.86 24.685 28.74 26.715 26.71C30.905 22.52 30.905 15.69 26.715 11.5C24.675 9.47 21.985 8.35 19.105 8.35C16.225 8.35 13.525 9.47 11.495 11.5Z"
        fill="black"
      />
    </svg>
  )
}
