export const GuideRecommendationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.5 24H19.5V33H23.5V24Z" fill="black" />
      <path d="M17 21.5001H24.5L26.87 16.5001H31V6L5 6V28.43H17V21.5001Z" fill="black" />
      <path
        d="M30.5 24.0001L30.65 18.5H28.5L26.01 24.0001V33H35.67L36 24.0001H30.5Z"
        fill="black"
      />
      <path d="M9 10H18V13H9V10Z" fill="white" />
      <path d="M9 16H22V19H9V16Z" fill="white" />
      <path d="M9 22H14V25H9V22Z" fill="white" />
    </svg>
  )
}
