export const ServerBuildingLibraryIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.72 29.57L15.48 31.23C16.02 31.45 16.58 31.63 17.16 31.77L17.85 36H22.12L22.81 31.77C23.39 31.63 23.95 31.45 24.49 31.23L23.25 29.57H16.72Z"
        fill="black"
      />
      <path
        d="M31.76 17.17C31.46 15.92 30.97 14.75 30.31 13.68L32.81 10.2L29.79 7.18L26.31 9.68C25.24 9.03 24.07 8.53 22.82 8.23L22.13 4H17.86L17.17 8.23C15.92 8.53 14.75 9.02 13.68 9.68L10.2 7.18L7.18 10.2L9.68 13.68C9.03 14.75 8.53 15.92 8.23 17.17L4 17.86V22.13L7.85 22.76L8.48 20.97C9.13 18.84 10.86 17.23 12.95 16.64C14.21 14 16.89 12.17 20.01 12.17C23.13 12.17 25.82 14 27.07 16.64C29.16 17.23 30.89 18.84 31.54 20.97L32.17 22.76L36.02 22.13V17.86L31.79 17.17H31.76Z"
        fill="black"
      />
      <path
        d="M29.85 21.52C29.25 19.52 27.41 18.15 25.33 18.15H14.66C12.57 18.15 10.73 19.52 10.14 21.52L7.64 28.67C7.13 30.39 8.17 32.2 9.92 32.61C10.19 32.67 10.46 32.71 10.71 32.71C11.67 32.71 12.52 32.25 13.26 31.26C14.2 30 15.84 27.82 15.84 27.82H24.13C24.13 27.82 25.77 30 26.71 31.26C27.46 32.25 28.3 32.71 29.26 32.71C29.52 32.71 29.78 32.68 30.05 32.61C31.8 32.2 32.85 30.4 32.33 28.67L29.83 21.52H29.85ZM17.3 23.85H15.47V25.68H13.97V23.85H12.14V22.35H13.97V20.52H15.47V22.35H17.3V23.85ZM23.46 23.96C22.99 23.96 22.6 23.58 22.6 23.1C22.6 22.62 22.98 22.24 23.46 22.24C23.94 22.24 24.32 22.62 24.32 23.1C24.32 23.58 23.94 23.96 23.46 23.96ZM25.24 25.74C24.77 25.74 24.38 25.36 24.38 24.88C24.38 24.4 24.76 24.02 25.24 24.02C25.72 24.02 26.1 24.4 26.1 24.88C26.1 25.36 25.72 25.74 25.24 25.74ZM25.24 22.18C24.77 22.18 24.38 21.8 24.38 21.32C24.38 20.84 24.76 20.46 25.24 20.46C25.72 20.46 26.1 20.84 26.1 21.32C26.1 21.8 25.72 22.18 25.24 22.18ZM27.02 23.96C26.55 23.96 26.16 23.58 26.16 23.1C26.16 22.62 26.54 22.24 27.02 22.24C27.5 22.24 27.88 22.62 27.88 23.1C27.88 23.58 27.5 23.96 27.02 23.96Z"
        fill="black"
      />
    </svg>
  )
}
