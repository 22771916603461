export const SupportLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 12.0742H36.75V34.2612C36.75 34.7654 36.3412 35.1742 35.837 35.1742H6.16304C5.65878 35.1742 5.25 34.7654 5.25 34.2612V12.0742Z"
        fill="black"
      />
      <path
        d="M5.25 7.73726C5.25 7.233 5.65878 6.82422 6.16304 6.82422H35.837C36.3412 6.82422 36.75 7.233 36.75 7.73726V12.0742H5.25V7.73726Z"
        fill="#4268FB"
      />
      <path d="M26.25 19.9121L29.9623 23.6244L26.25 27.3367" stroke="white" strokeWidth="2.1" />
      <path d="M15.75 19.9121L12.0377 23.6244L15.75 27.3367" stroke="white" strokeWidth="2.1" />
    </svg>
  )
}
