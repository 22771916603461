import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import clsx from 'clsx'

import { NavSectionItem } from '@/configs/solution-detail'
import { useActiveSection } from '@/hooks/use-active-section'

import classes from './styles.module.scss'

type NavSectionProps = ComponentPropsWithoutRef<'ul'> & {
  offsetTop?: number
  data: NavSectionItem[]
}

export const NavSection = forwardRef<ElementRef<'ul'>, NavSectionProps>(
  ({ offsetTop = 0, data, ...ulProps }, ref) => {
    const activeId = useActiveSection(data, offsetTop)

    const scrollToElement = (item: NavSectionItem) => {
      const element = document.querySelector<HTMLElement>(`#${item.id}`)
      if (!element) return

      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY + 1 + offsetTop, // +1 to make sure the element is in the viewport
        left: 0,
        behavior: 'smooth',
      })
    }

    return (
      <ul ref={ref} {...ulProps}>
        {data.map((item) => (
          <li key={item.id} className={classes['nav-item']}>
            <button
              className={clsx(classes['nav-link'], { [classes.active]: item.id === activeId })}
              onClick={() => scrollToElement(item)}
            >
              {item.name}
            </button>
          </li>
        ))}
      </ul>
    )
  },
)

if (import.meta.env.DEV) NavSection.displayName = 'NavSection'
