export const DisclosureOfLootBoxOddsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 35C11.7125 35 5 28.2875 5 20C5 11.7125 11.7125 5 20 5C28.2875 5 35 11.7125 35 20C35 28.2875 28.2875 35 20 35ZM20 8.74063C26.2062 8.74063 31.2594 13.7937 31.2594 20H20V8.74063ZM20 31.2594C13.7937 31.2594 8.74063 26.2062 8.74063 20H20V31.2594Z"
        fill="black"
      />
    </svg>
  )
}
