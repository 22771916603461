import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import 'swiper/css'

import { i18nLanguage } from '@/i18n/i18n'
import { NewsHeroSection, NewsList } from '@/pages/news/components'

import styles from './news.module.scss'

const NewsPage = () => {
  const seo = useMemo(() => i18nLanguage.header.seo.news, [i18nLanguage])
  const { i18n, t } = useTranslation()
  return (
    <div className={styles.newsPageWrapper}>
      <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
        <title>{t(seo.title)}</title>
        <meta name="description" content={t(seo.description)} />
        <meta property="og:title" content={t(seo.ogTitle)} />
        <meta property="og:description" content={t(seo.ogDescription)} />
        <meta property="og:url" content={`https://gamescale.io/news/${i18n.language}`} />
        <meta property="og:image" content={'https://gamescale.io/og-white.png'} />
      </Helmet>
      <NewsHeroSection />
      <NewsList />
    </div>
  )
}

export default NewsPage
