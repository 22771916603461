import { useCallback, useEffect, useState } from 'react'

import { NavSectionItem } from '@/configs/solution-detail'
import { getDocumentHeight } from '@/utils/get-document-height'

export const useActiveSection = (data: NavSectionItem[], offsetTop = 0) => {
  const [activeId, setActiveId] = useState<string | null>(null)

  const handleScroll = useCallback(() => {
    const scrollHeight = getDocumentHeight() - window.innerHeight
    const isBottomDocument = window.scrollY >= scrollHeight

    if (isBottomDocument) return setActiveId(data[data.length - 1].id)

    const currentItem = data.find((item, index) => {
      const element = document.getElementById(item.id)
      if (!element) return

      const rect = element.getBoundingClientRect()
      const isFirstElement = index === 0 && rect.top > 0
      const isLastElement = index === data.length - 1
      const isInViewport = rect.top + offsetTop <= 0 && rect.bottom + offsetTop >= 0

      return isFirstElement || isLastElement || isInViewport
    })

    if (currentItem) return setActiveId(currentItem.id)
    setActiveId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return activeId
}
