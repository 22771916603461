import { Link } from 'react-router-dom'

import clsx from 'clsx'

import { ArrowDirection } from '@/components/icons'
import useBuildT from '@/hooks/use-locale'
import i18n from '@/i18n/i18n'

import { dataTestId } from './SolutionCard.constant'
import styles from './SolutionCard.module.scss'

export type SolutionCardProp = {
  title: string
  description: string
  link: string
  image?: string
  Icon?: () => JSX.Element
}

export const SolutionCard = ({ title, description, image, link, Icon }: SolutionCardProp) => {
  const t = useBuildT()
  const currentLang = i18n.language

  if (Icon) {
    return (
      <Link to={`/${currentLang}${link}`}>
        <div className={clsx(styles.card, styles.minimal)}>
          <div data-testid="data-test-id-card-expended-wrapper" className={styles.row}>
            <div className={styles.col} data-testid="data-test-id-card-expended-col">
              <Icon />
              <ArrowDirection />
              <p data-testid={dataTestId.title} className={styles.title}>
                {t(title)}
              </p>
            </div>
            <p data-testid={dataTestId.description} className={styles.description}>
              {t(description)}
            </p>
            <div data-testid="data-test-id-direction-box">
              <ArrowDirection />
            </div>
          </div>
          <p data-testid={dataTestId.description} className={styles.description}>
            {t(description)}
          </p>
        </div>
      </Link>
    )
  }

  return (
    <Link to={`/${currentLang}${link}`}>
      <div className={styles.card}>
        <div data-testid="data-test-id-card-wrapper" className={styles.row_top}>
          <p data-testid={dataTestId.title} className={styles.title}>
            {t(title)}
          </p>
          <p data-testid={dataTestId.description} className={styles.description}>
            {t(description)}
          </p>
        </div>
        <div className={styles.row_bottom}>
          <ArrowDirection />
          <img src={image} alt="solution card" className={styles.image} />
        </div>
      </div>
    </Link>
  )
}
