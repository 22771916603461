import { BaseIconProps } from '../base-icon'

function Outlunk({ ...passProps }: BaseIconProps) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={passProps.className}
    >
      <mask
        id="mask0_23_859"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="15"
        height="16"
      >
        <rect y="0.5" width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_23_859)"></g>
      <g clipPath="url(#clip0_23_859)">
        <mask
          id="mask1_23_859"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="15"
          height="16"
        >
          <rect y="0.5" width="15" height="15" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_23_859)">
          <path
            d="M6.6286 4.25L1.875 9.0036L3.20082 10.3294L7.96873 5.56153L7.96873 8.9375H9.84373V2.375H3.28123V4.25H6.6286Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_23_859">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Outlunk
