import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { OutlineIcon } from '@/components/icons'
import { NavbarLink } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import LanguageSwitcher from '@/layout/header/language-switcher'
import LinkTo from '@/layout/header/link-to'

import styles from './navbar.module.scss'

type NavbarProps = {
  navbarOpen: boolean
  handleCloseNavbar: () => void
}

function Navbar({ navbarOpen, handleCloseNavbar }: NavbarProps) {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.container, { [styles.active]: navbarOpen })}>
        <LanguageSwitcher handleCloseNavbar={handleCloseNavbar} />
        <div className={styles.linkWrapper}>
          <LinkTo className={styles.link} to={NavbarLink.NEWS} onClick={() => handleCloseNavbar()}>
            {t(i18nLanguage.common.news)}
          </LinkTo>
          <LinkTo
            className={styles.link}
            to={NavbarLink.DOCS}
            onClick={() => handleCloseNavbar()}
            isExternal
          >
            {t(i18nLanguage.common.docs)}
            <div className={styles.icon}>
              <OutlineIcon />
            </div>
          </LinkTo>
        </div>
      </div>
    </div>
  )
}

export default Navbar
