export const CurrencyIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.071 20L20 12.929L12.929 20L20 27.071L27.071 20Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 20C37 29.3889 29.3889 37 20 37C10.6111 37 3 29.3889 3 20C3 10.6111 10.6111 3 20 3C29.3889 3 37 10.6111 37 20ZM11 11H29V29H11V11Z"
        fill="black"
      />
    </svg>
  )
}
