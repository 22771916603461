export const QaAutomationAndOptimizationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8 15.5L13 18.3L20 25.3001L27 18.3001L24.2 15.5001L20.0001 19.7L15.8 15.5Z"
        fill="black"
      />
      <path d="M15.725 10V12.9L21.775 7.95L15.725 3V6H6V34H16V30H10V10H15.725Z" fill="black" />
      <path
        d="M24 10H30V30H24.275V26.9999L18.225 31.9499L24.275 36.8999V34H34V6H24V10Z"
        fill="black"
      />
    </svg>
  )
}
