import { Fragment } from 'react/jsx-runtime'

import clsx from 'clsx'

import { FeatureDetail } from '@/configs/solution-detail'

import classes from './styles.module.scss'

interface SolutionFeatureDetailsProps {
  data: FeatureDetail[]
}

export function SolutionFeatureDetails({ data }: SolutionFeatureDetailsProps) {
  return (
    <>
      {data.map((feature, index) => (
        <div key={index} className={classes['feature-detail']}>
          <div className={clsx(classes['feature-item'], classes['full-width'])}>
            <h3 className={classes['feature-title']}>{feature.title}</h3>
          </div>
          {feature.description.map((item, index) => {
            const isLastItem = index === feature.description.length - 1

            return (
              <Fragment key={index}>
                <div
                  className={clsx(classes['feature-item'], classes['item-container'], {
                    [classes['last-item']]: isLastItem,
                  })}
                >
                  <h3 className={classes['content-title']}>{item.title}</h3>
                </div>
                <div
                  className={clsx(classes['feature-item'], classes['item-container'], {
                    [classes['last-item']]: isLastItem,
                  })}
                >
                  <ul
                    className={clsx(classes['content-list'], {
                      [classes['last-list']]: isLastItem,
                    })}
                  >
                    {item.contents.map((content, index) => (
                      <li key={index} className={classes['content-item']}>
                        {content}
                      </li>
                    ))}
                  </ul>
                </div>
              </Fragment>
            )
          })}
        </div>
      ))}
    </>
  )
}
