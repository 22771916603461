export const AntiTamperingIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 15.5V23.5C16.91 21.96 16.23 20.02 16.23 17.64V16.75L20 15.5Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4L5 8.96V16.21C5 25.08 9.01 31.83 20 36C30.99 31.83 35 25.09 35 16.21V8.96L20 4ZM20 8.21L31 11.85V16.21C31 23.71 27.69 28.4 20 31.69V27.8798C25.61 25.7498 27.66 22.3098 27.66 17.7798V14.0798L20 11.5498V8.21ZM20 15.5V11.5498L12.34 14.0798V17.7798C12.34 22.3098 14.39 25.7498 20 27.8798V23.5C23.09 21.97 23.77 20.03 23.77 17.64V16.75L20 15.5Z"
        fill="black"
      />
    </svg>
  )
}
