import { useRef, useState } from 'react'

import clsx from 'clsx'
import dayjs from 'dayjs'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'

import { NavigationLeftIcon, NavigationRightIcon } from '@/components/icons'
import { PathRoutes } from '@/configs/paths'
import { useLocale } from '@/hooks/use-locale'
import LinkTo from '@/layout/header/link-to'
import { useGetNewsHeroList } from '@/services/news'
import { Locale } from '@/types/locales'

import styles from './hero-section.module.scss'

const DELAY_TIME_SWIPE = 3000

const NewsHeroSection = () => {
  const lang = useLocale()
  const { data } = useGetNewsHeroList(lang as Locale)

  const swiperRef = useRef<SwiperType | null>(null)

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleSlideChange = (swiper: SwiperType) => {
    setCurrentSlide(swiper.realIndex)
  }

  const handlePrev = () => {
    swiperRef.current?.slidePrev()
  }
  const handleNext = () => {
    swiperRef.current?.slideNext()
  }

  const handleDotClick = (index: number) => {
    swiperRef.current?.slideToLoop(index)
  }

  return (
    <>
      <div className={styles.heroSectionWrapper} id="news-banner">
        <div className={styles.backgroundGradient}></div>
        <h1 data-test-id="pageTitle" className={styles.pageTitle}>
          NEWS
        </h1>
        {data && data.newsList.length > 0 && (
          <>
            <div className={styles.backgrounds}>
              {data.newsList.map((item, index) => {
                return (
                  <img
                    src={item.thumbnail}
                    key={item.id}
                    alt=""
                    className={clsx({ [styles.active]: index === currentSlide })}
                  />
                )
              })}
            </div>
            <div data-test-id="swiperWrapper" className={styles.swiperWrapper}>
              <Swiper
                autoplay={{
                  delay: DELAY_TIME_SWIPE,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop
                modules={[Autoplay]}
                className={styles.swiper}
                onSlideChange={handleSlideChange}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
              >
                {data.newsList.map((item) => (
                  <SwiperSlide key={item.id} className={styles.swiperSlide}>
                    <LinkTo to={`${PathRoutes.NEWS}/${item.id}`} className={styles.contentWrapper}>
                      <div data-test-id="mainImage" className={styles.image}>
                        <img data-test-id="image" src={item.thumbnail} alt="news" />
                        <div data-test-id="gradient" className={styles.gradient}></div>
                      </div>

                      <div data-test-id="content" className={styles.content}>
                        <p data-test-id="category" className={styles.category}>
                          {item.category.name}
                        </p>
                        <h1 data-test-id="title" className={styles.title}>
                          {item.title}
                        </h1>
                        <p data-test-id="date" className={styles.date}>
                          {dayjs(item.createdDate).format('YYYY-MM-DD')}
                        </p>
                      </div>
                    </LinkTo>
                  </SwiperSlide>
                ))}
              </Swiper>
              <button className={styles.swiperButtonPrev} onClick={handlePrev}>
                <NavigationLeftIcon />
              </button>
              <button className={styles.swiperButtonNext} onClick={handleNext}>
                <NavigationRightIcon />
              </button>
            </div>
            <div data-test-id="dots" className={styles.dots}>
              {data.newsList.map((_, index) => (
                <button
                  className={clsx(styles.dot, { [styles.active]: currentSlide === index })}
                  key={index}
                  onClick={() => handleDotClick(index)}
                ></button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default NewsHeroSection
