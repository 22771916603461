export const AnalysisXSmallIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.99609" cy="10" r="7.75" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7461 10.0001H9.99609V2.25C14.2763 2.25 17.7461 5.71979 17.7461 10C17.7461 10 17.7461 10 17.7461 10.0001Z"
        fill="#4268FB"
      />
      <circle cx="9.99805" cy="10" r="3" fill="white" />
    </svg>
  )
}
