export const QaXLargeIcon = () => {
  return (
    <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315886">
        <path id="Rectangle 34625573" d="M18 10.0281H32.375V33.675H18V10.0281Z" fill="black" />
        <path
          id="Rectangle 34625572"
          d="M0.75 1.4752C0.75 0.84007 1.26487 0.325195 1.9 0.325195H28.35C28.9851 0.325195 29.5 0.840068 29.5 1.4752V33.6752H4.2C2.29462 33.6752 0.75 32.1306 0.75 30.2252V1.4752Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="32.375" cy="30.7996" r="2.875" fill="#4268FB" />
        <path
          id="Rectangle 34625573_2"
          d="M29.5 7.79956H34.1C34.7351 7.79956 35.25 8.31443 35.25 8.94956V30.7996H29.5V7.79956Z"
          fill="#4268FB"
        />
        <path
          id="Vector 4"
          d="M9.375 15.2476L13.6095 19.4821L20.875 12.2166"
          stroke="white"
          strokeWidth="2.3"
        />
      </g>
    </svg>
  )
}
