export const AutoCallingSystemIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8906 18.1332L22.8668 26.1093C24.1789 27.4215 26.3064 27.4215 27.6185 26.1093L28.7782 24.9497C29.1687 24.5592 29.1687 23.926 28.7782 23.5355L25.9497 20.707C25.5592 20.3165 24.9261 20.3165 24.5355 20.707L23.1213 22.1213L18.8787 17.8786L20.2929 16.4644C20.6834 16.0739 20.6834 15.4407 20.2929 15.0502L17.4645 12.2218C17.0739 11.8312 16.4408 11.8312 16.0502 12.2218L14.8906 13.3814C13.5784 14.6936 13.5784 16.821 14.8906 18.1332Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 20C36 28.2843 29.2843 35 21 35C12.7157 35 6 28.2843 6 20C6 11.7157 12.7157 5 21 5C29.2843 5 36 11.7157 36 20ZM32 20C32 26.0751 27.0751 31 21 31C14.9249 31 10 26.0751 10 20C10 13.9249 14.9249 9 21 9C27.0751 9 32 13.9249 32 20Z"
        fill="black"
      />
    </svg>
  )
}
