import logo from '@/assets/images/img_user care.png'
import {
  AccountTrustRatingIcon,
  CouponsIcon,
  CreatorsIcon,
  CustomerCenterIcon,
  DisclosureOfLootBoxOddsIcon,
  DiscordBotIcon,
  MatchMobIcon,
  MessengerIcon,
  OfficialCommunityIcon,
  PcCafeAffiliationIcon,
  ProtectionOfMinorsIcon,
  SocialRecommendationIcon,
  TwoFactorAuthenticationIcon,
  UxResearchPlatformIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.userCare

export const userCareBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const userCareSEO = i18n.seo

const userFeedback: SolutionDetailData = {
  id: 'userFeedback',
  name: i18n.userFeedback.name,
  title: [i18n.userFeedback.title1, i18n.userFeedback.title2],
  description: i18n.userFeedback.description,
  featureItems: [
    {
      icon: <UxResearchPlatformIcon />,
      title: i18n.userFeedback.features.uxResearchPlatform,
      link: i18n.userFeedback.features.uxResearchPlatformLink,
    },
    {
      icon: <OfficialCommunityIcon />,
      title: i18n.userFeedback.features.officialCommunity,
      link: i18n.userFeedback.features.officialCommunityLink,
    },
    {
      icon: <CustomerCenterIcon />,
      title: i18n.userFeedback.features.customerCenter,
      link: i18n.userFeedback.features.customerCenterLink,
    },
  ],
  featureDetails: [],
}

const userTrust: SolutionDetailData = {
  id: 'userTrust',
  name: i18n.userTrust.name,
  title: [i18n.userTrust.title1, i18n.userTrust.title2, i18n.userTrust.title3],
  description: i18n.userTrust.description,
  featureItems: [
    {
      icon: <DisclosureOfLootBoxOddsIcon />,
      title: i18n.userTrust.features.disclosureOfLootBoxOdds,
      link: i18n.userTrust.features.disclosureOfLootBoxOddsLink,
    },
    {
      icon: <ProtectionOfMinorsIcon />,
      title: i18n.userTrust.features.protectionOfMinors,
      link: i18n.userTrust.features.protectionOfMinorsLink,
    },
    {
      icon: <TwoFactorAuthenticationIcon />,
      title: i18n.userTrust.features.twoFactorAuthentication,
      link: i18n.userTrust.features.twoFactorAuthenticationLink,
    },
    {
      icon: <AccountTrustRatingIcon />,
      title: i18n.userTrust.features.accountTrustRating,
      link: i18n.userTrust.features.accountTrustRatingLink,
    },
  ],
  featureDetails: [],
}

const connection: SolutionDetailData = {
  id: 'connection',
  name: i18n.connection.name,
  title: [i18n.connection.title1, i18n.connection.title2],
  description: i18n.connection.description,
  featureItems: [
    {
      icon: <MatchMobIcon />,
      title: i18n.connection.features.matchMob,
      link: i18n.connection.features.matchMobLink,
    },
    {
      icon: <SocialRecommendationIcon />,
      title: i18n.connection.features.socialRecommendation,
      link: i18n.connection.features.socialRecommendationLink,
    },
    {
      icon: <MessengerIcon />,
      title: i18n.connection.features.messenger,
      link: i18n.connection.features.messengerLink,
    },
  ],
  featureDetails: [],
}

const participationBoosting: SolutionDetailData = {
  id: 'participationBoosting',
  name: i18n.participationBoosting.name,
  title: [i18n.participationBoosting.title1, i18n.participationBoosting.title2],
  description: i18n.participationBoosting.description,
  featureItems: [
    {
      icon: <PcCafeAffiliationIcon />,
      title: i18n.participationBoosting.features.pcCafeAffiliation,
      link: i18n.participationBoosting.features.pcCafeAffiliationLink,
    },
    {
      icon: <CouponsIcon />,
      title: i18n.participationBoosting.features.coupons,
      link: i18n.participationBoosting.features.couponsLink,
    },
    {
      icon: <DiscordBotIcon />,
      title: i18n.participationBoosting.features.discordBot,
      link: i18n.participationBoosting.features.discordBotLink,
    },
    {
      icon: <CreatorsIcon />,
      title: i18n.participationBoosting.features.creators,
      link: i18n.participationBoosting.features.creatorsLink,
    },
  ],
  featureDetails: [],
}

export const userCareNav: NavSectionItem[] = [
  {
    id: userFeedback.id,
    name: userFeedback.name,
  },
  {
    id: userTrust.id,
    name: userTrust.name,
  },
  {
    id: connection.id,
    name: connection.name,
  },
  {
    id: participationBoosting.id,
    name: participationBoosting.name,
  },
]

export const userCareSolution: SolutionDetailData[] = [
  userFeedback,
  userTrust,
  connection,
  participationBoosting,
]
