export const ClientPerformanceMeasurementIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1511 27.3445L14.2473 20.1593L4.76772 25.5L3 22.4571L6.46407 20.4932V7.85829L27.7677 7.85828L24.9356 11.5726L10.1784 11.5726V18.3876L14.8717 15.7269L20.8433 21.9446L32.971 6L35.7677 8.13709L32.4641 12.4784V33.8583H6.46407V27.7761L10.1784 25.6219V30.144H28.7498V17.3592L21.1511 27.3445Z"
        fill="black"
      />
    </svg>
  )
}
