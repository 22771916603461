export const MarketingMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23_1173)">
        <circle cx="15" cy="14.9988" r="11.625" transform="rotate(45 15 14.9988)" fill="black" />
        <circle cx="15" cy="15.0002" r="7.875" transform="rotate(45 15 15.0002)" fill="#4268FB" />
        <circle cx="15" cy="14.9988" r="5.25" transform="rotate(45 15 14.9988)" fill="black" />
        <path
          d="M14.9985 15.0005L23.3512 10.6252L20.0897 9.90929L19.3737 6.64776L14.9985 15.0005Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1173">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
