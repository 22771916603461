import { useNavigate } from 'react-router-dom'

import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'

import styles from './ShowListButton.module.scss'

type ShowListButtonProps = {
  pathname: string
}

export const ShowListButton = ({ pathname }: ShowListButtonProps) => {
  const navigate = useNavigate()
  const t = useBuildT()

  const goToList = () => {
    navigate(pathname)
  }
  return (
    <button
      onClick={() => goToList()}
      className={styles.button}
      data-testid="data-test-id-show-list-button"
    >
      <p data-testid="data-test-id-show-list-button-title" className={styles.text}>
        {t(i18nLanguage.newsDetail.showListButton)}
      </p>
    </button>
  )
}
