export const UseCaseXlargeIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315887">
        <path
          id="Polygon 2"
          d="M20.8613 39.0516C20.3276 39.3597 19.67 39.3597 19.1363 39.0516L3.93053 30.2725C3.39682 29.9644 3.06803 29.3949 3.06803 28.7786L3.06803 11.2205C3.06803 10.6042 3.39682 10.0347 3.93053 9.72659L19.1363 0.947523C19.67 0.63938 20.3276 0.63938 20.8613 0.94752L36.0671 9.72659C36.6008 10.0347 36.9296 10.6042 36.9296 11.2205L36.9296 28.7786C36.9296 29.3949 36.6008 29.9644 36.0671 30.2725L20.8613 39.0516Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="20.0006" cy="17.4343" r="4.025" fill="white" />
        <path
          id="Intersect"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0415 39.2843C20.325 39.2773 20.6071 39.2004 20.8611 39.0537L31.6696 32.8134C29.8183 28.1816 25.2899 24.9093 19.9971 24.9093C14.705 24.9093 10.1769 28.1809 8.3252 32.812L19.1361 39.0537C19.3902 39.2004 19.6723 39.2773 19.9558 39.2843H20.0415Z"
          fill="#4268FB"
        />
      </g>
    </svg>
  )
}
