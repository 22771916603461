import { BaseIconProps } from '../base-icon'

function NavigationRightIcon({ ...passProps }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={passProps.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 39.7952L33.6644 32L26 24.2048L28.1678 22L38 32L28.1678 42L26 39.7952Z"
        fill="white"
      />
    </svg>
  )
}

export default NavigationRightIcon
