export const MemberChannelingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M24 7C22.6 7 21.26 7.23 20 7.63C18.74 7.22 17.4 7 16 7C8.82 7 3 12.82 3 20C3 27.18 8.82 33 16 33C17.4 33 18.74 32.77 20 32.37C21.26 32.78 22.6 33 24 33C31.18 33 37 27.18 37 20C37 12.82 31.18 7 24 7ZM15.91 11.81C18.26 11.81 20.16 13.71 20.16 16.06C20.16 18.41 18.26 20.31 15.91 20.31C13.56 20.31 11.66 18.41 11.66 16.06C11.66 13.71 13.56 11.81 15.91 11.81ZM16 29.28C13.27 29.28 10.83 28.09 9.13 26.21C10.41 23.71 13.01 22 16.01 22C19.01 22 21.6 23.71 22.88 26.21C21.18 28.09 18.73 29.28 16 29.28ZM25.19 29.2C27.55 26.85 29 23.59 29 20C29 16.41 27.54 13.15 25.19 10.8C29.75 11.39 33.29 15.28 33.29 20C33.29 24.72 29.75 28.61 25.19 29.2Z"
        fill="black"
      />
    </svg>
  )
}
