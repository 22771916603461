import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import clsx from 'clsx'

import { SolutionDetailData } from '@/configs/solution-detail'
import { useTranslateData } from '@/hooks/use-translate-data'
import { SolutionFeatureDetails } from '@/pages/solution-detail/components/solution-detail-section/solution-feature-details'
import { SolutionFeatureItems } from '@/pages/solution-detail/components/solution-detail-section/solution-feature-items'
import { translateSolutionDetailData } from '@/utils/translate-solution-detail-data'

import classes from './styles.module.scss'

type SolutionDetailSectionProps = ComponentPropsWithoutRef<'section'> & {
  solutionId: string
  data: SolutionDetailData
}

export const SolutionDetailSection = forwardRef<ElementRef<'section'>, SolutionDetailSectionProps>(
  ({ solutionId, data, className, ...sectionProps }, ref) => {
    const translatedData: SolutionDetailData = useTranslateData(data, translateSolutionDetailData)

    return (
      <section
        ref={ref}
        {...sectionProps}
        className={clsx(classes.wrapper, classes[solutionId], className)}
        data-testid="section-wrapper"
        id={translatedData.id}
      >
        <div data-testid="title-container" className={classes['title-container']}>
          <h3 data-testid="name-tag" className={classes.name}>
            {translatedData.name}
          </h3>
          <h4 data-testid="title" className={clsx(classes.title, classes[translatedData.id])}>
            {translatedData.title.map((part) => (
              <span key={part}>{part}</span>
            ))}
          </h4>
          <p data-testid="description" className={classes.description}>
            {translatedData.description}
          </p>
        </div>
        {!!translatedData.featureItems.length && (
          <SolutionFeatureItems data={translatedData.featureItems} />
        )}
        <SolutionFeatureDetails data={translatedData.featureDetails} />
      </section>
    )
  },
)

if (import.meta.env.DEV) SolutionDetailSection.displayName = 'SolutionDetailSection'
