export const TextDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.005 32.93L31.195 28.12C35.615 22.21 35.155 13.8 29.785 8.42C26.835 5.47 22.975 4 19.105 4C15.235 4 11.375 5.47 8.425 8.42C2.525 14.32 2.525 23.88 8.425 29.78C11.375 32.73 15.235 34.2 19.105 34.2C22.285 34.2 25.455 33.19 28.125 31.19L32.935 36L36.005 32.93ZM11.495 26.71C7.305 22.52 7.305 15.69 11.495 11.5C13.525 9.47 16.225 8.35 19.105 8.35C21.985 8.35 24.675 9.47 26.715 11.5C30.905 15.69 30.905 22.52 26.715 26.71C24.685 28.74 21.985 29.86 19.105 29.86C16.225 29.86 13.535 28.74 11.495 26.71Z"
        fill="black"
      />
      <path d="M12.915 17.28H17.185V26.25H21.025V17.28H25.305V13.53H12.915V17.28Z" fill="black" />
    </svg>
  )
}
