export function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_879_5894"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
        display="block"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_879_5894)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.799 11.9992L19.1988 6.59944L17.3988 4.79944L11.999 10.1992L6.59961 4.79983L4.79961 6.59983L10.199 11.9992L4.79883 17.3994L6.59883 19.1994L11.999 13.7992L17.3996 19.1998L19.1996 17.3998L13.799 11.9992Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
