export const GameAnomalyDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 6C13 6 7.3 11.7 7.3 18.7V26H11.73V18.7C11.73 14.14 15.44 10.43 20 10.43C24.56 10.43 28.27 14.14 28.27 18.7V26H32.7V18.7C32.7 11.7 27 6 20 6Z"
        fill="black"
      />
      <path d="M35 29H5V33.5H35V29Z" fill="black" />
      <path
        d="M24.33 18.7V26H15.67V18.7C15.67 16.31 17.61 14.37 20 14.37C22.39 14.37 24.33 16.31 24.33 18.7Z"
        fill="black"
      />
    </svg>
  )
}
