import { PropsWithChildren } from 'react'

import styles from './body.module.scss'

function Body({ children }: PropsWithChildren) {
  return (
    <main className={styles.wrapper} data-testid="app">
      {children}
    </main>
  )
}

export default Body
