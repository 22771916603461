export const IntegratedAccountLinkingIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4233 6L28.129 6.00955C32.4657 6.00955 36 9.54388 36 13.8806V19.631C36 23.729 32.8478 27.1009 28.8358 27.4639V23.1272C30.4693 22.7928 31.7015 21.3504 31.7015 19.6215V13.871C31.7015 11.9033 30.0967 10.2985 28.129 10.2985H21.4233C19.6974 10.2985 18.2508 11.533 17.9212 13.1641H18.5767C22.9134 13.1641 26.4478 16.6984 26.4478 21.0351V26.7856C26.4478 31.1223 22.9134 34.6566 18.5767 34.6566H11.871C7.53433 34.6566 4 31.1223 4 26.7856V21.0351C4 16.9372 7.15224 13.5557 11.1642 13.1927V17.5294C9.53075 17.8638 8.29851 19.3062 8.29851 21.0351V26.7856C8.29851 28.7533 9.90328 30.3581 11.871 30.3581H18.5767C20.3026 30.3581 21.7492 29.1236 22.0788 27.4925H21.4233C17.0866 27.4925 13.5522 23.9582 13.5522 19.6215V13.871C13.5522 9.53433 17.0866 6 21.4233 6ZM22.1493 23.194V21.0351C22.1493 19.0673 20.5445 17.4626 18.5767 17.4626H17.8508V19.6215C17.8508 21.5893 19.4555 23.194 21.4233 23.194H22.1493Z"
        fill="black"
      />
    </svg>
  )
}
