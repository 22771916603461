export function MoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0_2686_595"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" transform="matrix(0 1 1 0 0 0)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2686_595)">
        <path
          d="M7.57742 8.78271L3.7947 5L2.18604 6.60867L7.57737 12L12.9688 6.60867L11.3601 5L7.57742 8.78271Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
