export const RealTimeWeightControlIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9 4V9H21.1V4H18.9Z" fill="black" />
      <path
        d="M31.2422 8.20216L27.7122 11.7322L29.2678 13.2878L32.7978 9.7578L31.2422 8.20216Z"
        fill="black"
      />
      <path d="M32 19.9H37V22.1H32V19.9Z" fill="black" />
      <path
        d="M32.7978 32.2422L29.2678 28.7122L27.7122 30.2678L31.2422 33.7978L32.7978 32.2422Z"
        fill="black"
      />
      <path
        d="M12.2878 30.2678L8.75781 33.7978L7.20218 32.2422L10.7322 28.7122L12.2878 30.2678Z"
        fill="black"
      />
      <path d="M3 22.1H8V19.9H3V22.1Z" fill="black" />
      <path
        d="M10.7322 13.2878L7.20218 9.7578L8.75781 8.20216L12.2878 11.7322L10.7322 13.2878Z"
        fill="black"
      />
      <path
        d="M20 31C25.5228 31 30 26.5228 30 21C30 18.6349 29.1789 16.4615 27.8062 14.7494L20.7778 21.7778L19.2222 20.2222L26.2506 13.1938C24.5385 11.8211 22.3651 11 20 11C14.4772 11 10 15.4772 10 21C10 26.5228 14.4772 31 20 31Z"
        fill="black"
      />
    </svg>
  )
}
