import { useCallback, useRef, useState } from 'react'

import clsx from 'clsx'

import { CloseIcon, HamburgerIcon, Logo } from '@/components/icons'
import { PathRoutes } from '@/configs/paths'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import useOnClickOutside from '@/hooks/use-on-click-outside'
import LinkTo from '@/layout/header/link-to'
import Navbar from '@/layout/header/navbar'

import styles from './header.module.scss'

function Header() {
  const isDesktop = useMediaQuery(mediaQueryBreakpoints.up('sm2'))
  const [navbarOpen, setNavbarOpen] = useState(false)
  const ref = useRef<HTMLElement>(null)

  const toggleNavbar = useCallback((open = false) => {
    setNavbarOpen(() => open)
  }, [])

  if (isDesktop && navbarOpen) {
    toggleNavbar()
  }

  useOnClickOutside(ref, () => toggleNavbar())

  return (
    <>
      {!isDesktop && navbarOpen && <div className={styles.overlay}></div>}
      <header className={styles.wrapper} ref={ref}>
        <div className={styles.header}>
          <LinkTo to={PathRoutes.MAIN} className={styles.logo} onClick={() => toggleNavbar()}>
            <Logo />
          </LinkTo>
          {!isDesktop && (
            <button
              className={clsx(styles.hamburgerIcon, navbarOpen && styles.active)}
              onClick={() => toggleNavbar(!navbarOpen)}
            >
              {navbarOpen ? <CloseIcon /> : <HamburgerIcon />}
            </button>
          )}
        </div>
        <Navbar navbarOpen={navbarOpen} handleCloseNavbar={toggleNavbar} />
      </header>
    </>
  )
}

export default Header
