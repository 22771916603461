export const CrashReportIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9816 5H18.0184V16.4345H21.9816V5Z" fill="black" />
      <path
        d="M28.0948 10.5194L25.7764 13.7296C28.3425 15.5527 30.0368 18.535 30.0368 21.9235C30.0368 27.4522 25.5386 31.9603 20 31.9603C14.4614 31.9603 9.9632 27.4621 9.9632 21.9235C9.9632 18.5449 11.6476 15.5527 14.2236 13.7296L11.9052 10.5194C8.33829 13.0658 6 17.2172 6 21.9235C6 29.6418 12.2817 35.9235 20 35.9235C27.7183 35.9235 34 29.6418 34 21.9235C34 17.2172 31.6617 13.0658 28.0948 10.5194Z"
        fill="black"
      />
      <path
        d="M22.8139 21.9236C22.8139 23.4777 21.5541 24.7375 20 24.7375C18.446 24.7375 17.1862 23.4777 17.1862 21.9236C17.1862 20.3696 18.446 19.1097 20 19.1097C21.5541 19.1097 22.8139 20.3696 22.8139 21.9236Z"
        fill="black"
      />
    </svg>
  )
}
