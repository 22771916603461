import logo from '@/assets/images/img_game service opertion.png'
import {
  ADCreatorIcon,
  AutoCallingSystemIcon,
  CheatDetectionIcon,
  CouponsIcon,
  CustomerCenterIcon,
  DataExtractionIcon,
  DisclosureOfLootBoxOddsIcon,
  DiscordBotIcon,
  ExecutionRestrictionIcon,
  GameAnomalyDetectionIcon,
  GameProtectionIcon,
  ImageDetectionIcon,
  NotificationsIcon,
  PersonalInformationCollectionIcon,
  PersonalInformationManagementIcon,
  ProtectionOfMinorsIcon,
  TextDetectionIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.operation

export const operationBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const operationSEO = i18n.seo

const operationTools: SolutionDetailData = {
  id: 'operation-tools',
  name: i18n.operationTools.name,
  title: [i18n.operationTools.title1, i18n.operationTools.title2],
  description: i18n.operationTools.description,
  featureItems: [
    {
      icon: <GameProtectionIcon />,
      title: i18n.operationTools.features.gameOperationTool,
      link: i18n.operationTools.features.gameOperationToolLink,
    },
    {
      icon: <CustomerCenterIcon />,
      title: i18n.operationTools.features.customerCenter,
      link: i18n.operationTools.features.customerCenterLink,
    },
    {
      icon: <AutoCallingSystemIcon />,
      title: i18n.operationTools.features.autoCallingSystem,
      link: i18n.operationTools.features.autoCallingSystemLink,
    },
    {
      icon: <DataExtractionIcon />,
      title: i18n.operationTools.features.dataExtractionTool,
      link: i18n.operationTools.features.dataExtractionToolLink,
    },
    {
      icon: <NotificationsIcon />,
      title: i18n.operationTools.features.notification,
      link: i18n.operationTools.features.notificationLink,
    },
    {
      icon: <DiscordBotIcon />,
      title: i18n.operationTools.features.discordBot,
      link: i18n.operationTools.features.discordBotLink,
    },
  ],
  featureDetails: [],
}

const restriction: SolutionDetailData = {
  id: 'restriction',
  name: i18n.restriction.name,
  title: [i18n.restriction.title1, i18n.restriction.title2],
  description: i18n.restriction.description,
  featureItems: [
    {
      icon: <CheatDetectionIcon />,
      title: i18n.restriction.features.cheatDetection,
      link: i18n.restriction.features.cheatDetectionLink,
    },
    {
      icon: <GameAnomalyDetectionIcon />,
      title: i18n.restriction.features.gameAnomalyDetection,
      link: i18n.restriction.features.gameAnomalyDetectionLink,
    },
    {
      icon: <TextDetectionIcon />,
      title: i18n.restriction.features.textDetection,
      link: i18n.restriction.features.textDetectionLink,
    },
    {
      icon: <ImageDetectionIcon />,
      title: i18n.restriction.features.imageDetection,
      link: i18n.restriction.features.imageDetectionLink,
    },
    {
      icon: <ExecutionRestrictionIcon />,
      title: i18n.restriction.features.executionRestrictionSystem,
      link: i18n.restriction.features.executionRestrictionSystemLink,
    },
  ],
  featureDetails: [],
}

const retention: SolutionDetailData = {
  id: 'retention',
  name: i18n.retention.name,
  title: [i18n.retention.title1, i18n.retention.title2],
  description: i18n.retention.description,
  featureItems: [
    {
      icon: <CouponsIcon />,
      title: i18n.retention.features.coupons,
      link: i18n.retention.features.couponsLink,
    },
    {
      icon: <ADCreatorIcon />,
      title: i18n.retention.features.adCreator,
      link: i18n.retention.features.adCreatorLink,
    },
  ],
  featureDetails: [],
}

const compliance: SolutionDetailData = {
  id: 'compliance',
  name: i18n.compliance.name,
  title: [i18n.compliance.title1, i18n.compliance.title2],
  description: i18n.compliance.description,
  featureItems: [
    {
      icon: <PersonalInformationCollectionIcon />,
      title: i18n.compliance.features.personalInformationCollectionSystem,
      link: i18n.compliance.features.personalInformationCollectionSystemLink,
    },
    {
      icon: <PersonalInformationManagementIcon />,
      title: i18n.compliance.features.personalInformationManagementSystem,
      link: i18n.compliance.features.personalInformationManagementSystemLink,
    },
    {
      icon: <ProtectionOfMinorsIcon />,
      title: i18n.compliance.features.protectionOfMinors,
      link: i18n.compliance.features.protectionOfMinorsLink,
    },
    {
      icon: <DisclosureOfLootBoxOddsIcon />,
      title: i18n.compliance.features.disclosureOfLootBoxOdds,
      link: i18n.compliance.features.disclosureOfLootBoxOddsLink,
    },
  ],
  featureDetails: [],
}

export const operationNav: NavSectionItem[] = [
  {
    id: operationTools.id,
    name: operationTools.name,
  },
  {
    id: restriction.id,
    name: restriction.name,
  },
  {
    id: retention.id,
    name: retention.name,
  },
  {
    id: compliance.id,
    name: compliance.name,
  },
]

export const operationSolution: SolutionDetailData[] = [
  operationTools,
  restriction,
  retention,
  compliance,
]
