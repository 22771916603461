export const header = {
  meta: {
    title: 'GameScale | Game service solution',
    description:
      'GameScale provides A-Z solutions for game development, covering everything from game security and marketing to operations.',
    keywords: 'game ai, game solution, game release, nexon',
    ogTitle: 'Game service solution',
    ogDescription:
      'GameScale provides A-Z solutions for game development, covering everything from game security and marketing to operations.',
  },
  seo: {
    news: {
      title: 'GameScale | News',
      description: 'Check the latest news on GameScale.',
      ogTitle: 'News',
      ogDescription: 'Check the latest news on GameScale.',
    },
  },
}
