export const QaSupportToolsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8H29V32H5V8ZM9 12H25V28H9V12Z"
        fill="black"
      />
      <path d="M37 12L31 17V23L37 28V12Z" fill="black" />
    </svg>
  )
}
