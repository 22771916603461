export const NotificationsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.2423 16.2007C30.1529 19.301 31.2392 23 34 23V28.03H6V23C8.75474 23 9.84237 19.3171 10.7567 16.221C10.9908 15.4283 11.2136 14.6741 11.45 14.03C12.68 10.63 14.6 8.81 17.55 8.21V5H22.46V8.21C25.41 8.81 27.32 10.63 28.56 14.03C28.7924 14.6686 29.0117 15.4154 29.2423 16.2007Z"
        fill="black"
      />
      <path
        d="M25.06 31.6C25.06 34.4 22.79 36.66 20 36.66C17.2 36.66 14.94 34.39 14.94 31.6H25.06Z"
        fill="black"
      />
    </svg>
  )
}
