export const VisionHackDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.93 20.49C24.1585 20.4304 24.387 20.3905 24.6253 20.39C26.1028 20.3928 27.29 21.5818 27.29 23.06C27.29 24.54 26.09 25.73 24.62 25.73C23.45 25.73 22.47 24.97 22.11 23.92H16.08C15.73 24.97 14.74 25.73 13.57 25.73C12.09 25.73 10.9 24.54 10.9 23.06C10.9 21.58 12.1 20.39 13.57 20.39C13.82 20.39 14.05 20.43 14.27 20.49L16.98 16.47C16.64 16.02 16.43 15.47 16.43 14.86C16.43 13.38 17.62 12.19 19.1 12.19C20.58 12.19 21.77 13.39 21.77 14.86C21.77 15.47 21.56 16.02 21.22 16.47L23.93 20.49ZM18.4 17.43L15.68 21.45C15.85 21.67 15.99 21.93 16.08 22.2H22.11C22.2 21.93 22.34 21.68 22.51 21.45L19.8 17.43C19.57 17.49 19.35 17.53 19.1 17.53C18.85 17.53 18.62 17.49 18.4 17.43Z"
        fill="black"
      />
      <path d="M24.6253 20.39L24.63 20.39H24.62L24.6253 20.39Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.19 29.12L36 33.93L32.93 37L28.12 32.19C25.45 34.19 22.28 35.2 19.1 35.2C15.23 35.2 11.37 33.73 8.42 30.78C2.52 24.88 2.52 15.32 8.42 9.42C11.37 6.47 15.23 5 19.1 5C22.97 5 26.83 6.47 29.78 9.42C35.15 14.8 35.61 23.21 31.19 29.12ZM11.49 12.5C7.29999 16.69 7.29999 23.52 11.49 27.71C13.53 29.74 16.22 30.86 19.1 30.86C21.98 30.86 24.68 29.74 26.71 27.71C30.9 23.52 30.9 16.69 26.71 12.5C24.67 10.47 21.98 9.35 19.1 9.35C16.22 9.35 13.52 10.47 11.49 12.5Z"
        fill="black"
      />
    </svg>
  )
}
