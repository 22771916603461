export function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_879_5895"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_879_5895)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4016 13.2008H3.60156V10.8008H20.4016V13.2008Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4016 7.20078H3.60156V4.80078H20.4016V7.20078Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4016 19.2008H3.60156V16.8008H20.4016V19.2008Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
