export const DoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.013 5.85129L7.24087 15.3273L2.15625 10.3967L3.31649 9.20024L7.24087 13.0057L15.8527 4.65479L17.013 5.85129Z"
        fill="white"
      />
    </svg>
  )
}
