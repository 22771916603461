export const ExecutionRestrictionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.78 14.16V11.8C28.78 6.95 24.84 3 20 3C15.16 3 11.22 6.95 11.22 11.8V14.16H7V33H33V14.16H28.78ZM15.37 11.8C15.37 9.24 17.45 7.16 20 7.16C22.55 7.16 24.63 9.24 24.63 11.8V14.16H15.37V11.8ZM21.29 28.08H18.7V24.45C17.57 23.95 16.77 22.82 16.77 21.5C16.77 19.72 18.21 18.27 19.99 18.27C21.77 18.27 23.21 19.72 23.21 21.5C23.21 22.82 22.42 23.95 21.28 24.45L21.29 28.08Z"
        fill="black"
      />
    </svg>
  )
}
