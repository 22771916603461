import { PropsWithChildren } from 'react'
import { Helmet, HelmetProvider as ReactHelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { locales } from '@/configs/locales'
import { i18nLanguage } from '@/i18n'

function HelmetProvider({ children }: PropsWithChildren) {
  const { i18n, t } = useTranslation()

  return (
    <ReactHelmetProvider>
      <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
        <title>{t(i18nLanguage.header.meta.title)}</title>
        <meta name="description" content={t(i18nLanguage.header.meta.description)} />
        <meta name="keywords" content={t(i18nLanguage.header.meta.keywords)} />
        <meta property="og:title" content={t(i18nLanguage.header.meta.ogTitle)} />
        <meta property="og:description" content={t(i18nLanguage.header.meta.ogDescription)} />
        <meta property="og:locale" content={i18n.language === locales.EN ? 'en_US' : 'ko_KR'} />
        <meta property="og:url" content={`https://gamescale.io/${i18n.language}`} />
        <meta property="og:image" content={'https://gamescale.io/og-black.png'} />
      </Helmet>
      {children}
    </ReactHelmetProvider>
  )
}

export default HelmetProvider
