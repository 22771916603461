export const PersonalInformationManagementIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 21C22.2091 21 24 19.2091 24 17C24 14.7909 22.2091 13 20 13C17.7909 13 16 14.7909 16 17C16 19.2091 17.7909 21 20 21Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 3L18.5 3L17.2757 6.26491C15.4335 6.62823 13.7212 7.35291 12.2155 8.36203L9.03983 6.91854L6.91851 9.03986L8.362 12.2155C7.35289 13.7212 6.62823 15.4335 6.26491 17.2757L3 18.5L3 21.5L6.26491 22.7243C6.62823 24.5665 7.35291 26.2788 8.36203 27.7845L6.91854 30.9602L9.03986 33.0815L12.2155 31.638C13.7212 32.6471 15.4335 33.3718 17.2757 33.7351L18.5 37H21.5L22.7243 33.7351C24.5665 33.3718 26.2788 32.6471 27.7845 31.638L30.9602 33.0815L33.0815 30.9601L31.638 27.7845C32.6471 26.2788 33.3718 24.5665 33.7351 22.7243L37 21.5V18.5L33.7351 17.2757C33.3718 15.4335 32.6471 13.7212 31.638 12.2155L33.0815 9.03983L30.9601 6.91851L27.7845 8.362C26.2788 7.35289 24.5665 6.62823 22.7243 6.26491L21.5 3ZM20.0747 22.4782C16.9038 22.4782 14.1878 24.4387 13.0736 27.2128C11.1791 25.3932 10 22.8343 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C30 22.7807 28.865 25.2963 27.0331 27.1089C25.8934 24.3899 23.2058 22.4782 20.0747 22.4782Z"
        fill="black"
      />
    </svg>
  )
}
