import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'

const axiosInstance = axios.create({
  baseURL: '/api/v1',
})

axiosInstance.interceptors.request.use((config) => {
  return config
})

export class ApiClient {
  /**
   * Sends a GET request to the specified URL with optional configuration options.
   *
   * @param {string} url - The URL to which the GET request will be sent.
   * @param {AxiosRequestConfig<D>} config - (Optional) Configuration options for the GET request.
   *
   * @returns {Promise<R>} - A Promise that resolves with the response data of the GET request.
   */
  static get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return axiosInstance.get(url, {
      ...config,
      paramsSerializer: ApiClient.getParamsSerializer(),
    })
  }

  /**
   * Sends a POST request to the specified URL with the provided data and optional configuration options.
   *
   * @param {string} url - The URL to which the POST request will be sent.
   * @param {D} data - The data payload to be sent with the POST request.
   * @param {AxiosRequestConfig<D>} config - (Optional) Configuration options for the POST request.
   *
   * @returns {Promise<R>} - A Promise that resolves with the response data of the POST request.
   */
  static post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return axiosInstance.post(url, data, config)
  }

  /**
   * Sends a PUT request to the specified URL with the provided data and optional configuration options.
   *
   * @param {string} url - The URL to which the PUT request will be sent.
   * @param {D} data - The data payload to be sent with the PUT request.
   * @param {AxiosRequestConfig<D>} config - (Optional) Configuration options for the PUT request.
   *
   * @returns {Promise<R>} - A Promise that resolves with the response data of the PUT request.
   */
  static put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return axiosInstance.put(url, data, config)
  }

  /**
   * Sends a PATCH request to the specified URL with the provided data and configuration options.
   *
   * @param {string} url - The URL to which the PATCH request will be sent.
   * @param {D} data - The data to be sent with the PATCH request.
   * @param {AxiosRequestConfig<D>} config - (Optional) Configuration options for the PATCH request.
   *
   * @returns {Promise<R>} - A Promise that resolves with the response data of the PATCH request.
   */
  static patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return axiosInstance.patch(url, data, config)
  }

  /**
   * Asynchronous function to perform a DELETE request using Axios instance.
   *
   * @param url The URL to which the DELETE request is sent.
   * @param config Optional Axios request configuration such as headers, parameters, etc.
   * @returns A Promise that resolves to the AxiosResponse for the DELETE request.
   *          The generic types 'T', 'R', and 'D' represent the expected response data type,
   *          the AxiosResponse type, and the request data type respectively.
   *          By default, 'T' is set to 'any', 'R' is set to 'AxiosResponse<T>', and 'D' is set to 'any'.
   */
  static delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return axiosInstance.delete(url, config)
  }

  /**
   * Returns a function for serializing query parameters into a string format.
   *
   * This function serves as a parameter serializer for Axios requests, particularly useful for GET requests
   * where query parameters need to be converted into a format suitable for URL encoding.
   *
   * @returns {(params: Record<string, any>) => string} - A function that takes an object containing query parameters
   * and returns a serialized string representation of those parameters.
   */
  private static getParamsSerializer = () => (params: Record<string, any>) => {
    return qs.stringify(params, { encode: false, indices: false })
  }
}

export default ApiClient
