export const MatchMobIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 15.9C18.2 15.32 17.25 14.99 16.23 14.99C13.56 14.99 11.39 17.23 11.39 19.99C11.39 22.75 13.56 24.99 16.23 24.99C17.25 24.99 18.2 24.66 19 24.08V28.49H3V11.49H9.47L9.43 9.25C8.57 8.75 8 7.86 8 6.84C8 5.27 9.34 4 11 4C12.66 4 14 5.27 14 6.84C14 7.86 13.42 8.75 12.57 9.25L12.61 11.49H19V15.9Z"
        fill="black"
      />
      <path
        d="M34.64 18.42C34.14 17.56 33.25 16.99 32.23 16.99C30.66 16.99 29.39 18.33 29.39 19.99C29.39 21.65 30.66 22.99 32.23 22.99C33.25 22.99 34.14 22.42 34.64 21.56H37V28.49H30.57V30.73C31.43 31.23 32 32.12 32 33.14C32 34.71 30.66 35.98 29 35.98C27.34 35.98 26 34.71 26 33.14C26 32.12 26.58 31.23 27.43 30.73V28.49H21V21.56H18.64C18.14 22.42 17.25 22.99 16.23 22.99C14.66 22.99 13.39 21.65 13.39 19.99C13.39 18.33 14.66 16.99 16.23 16.99C17.25 16.99 18.14 17.57 18.64 18.42H21V11.49H37V18.42H34.64Z"
        fill="black"
      />
    </svg>
  )
}
