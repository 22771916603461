export const SocialRecommendationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4897 21.8692H25.2051C26.3692 19.2244 27.2543 15.9183 27.6776 11.7468C27.5333 11.7565 27.389 11.7662 27.2447 11.7662C24.0698 11.7662 21.501 9.1894 21.4433 6H9.23435C9.18624 9.1894 6.60784 11.7662 3.43294 11.7662C3.28863 11.7662 3.14431 11.7565 3 11.7468C4.31806 24.8058 10.1387 29.5413 15.334 31.3207C16.3731 30.961 17.4314 30.4845 18.48 29.8525L18.4897 21.8692Z"
        fill="black"
      />
      <path d="M24.8571 24.1636H20.9714V32.9999H24.8571V24.1636Z" fill="black" />
      <path
        d="M31.8029 18.7636L31.6571 24.1637H37L36.6794 33H27.2954V24.1637L29.7143 18.7636H31.8029Z"
        fill="black"
      />
    </svg>
  )
}
