import { ReactNode } from 'react'

export interface SolutionBanner {
  title: string
  subtitle: string
  description: string
  logo: string
}

export interface SEO {
  title: string
  description: string
  ogTitle: string
  ogDescription: string
}

export interface SolutionDetailData {
  id: string
  name: string
  title: string[]
  description: string
  featureItems: FeatureItem[]
  featureDetails: FeatureDetail[]
}

export interface NavSectionItem {
  id: string
  name: string
}

export interface SolutionDetail {
  id: string
  banner: SolutionBanner
  navSection: NavSectionItem[]
  data: SolutionDetailData[]
  seo: SEO
}

export interface FeatureDetail {
  title: string
  description: {
    title: string
    contents: string[]
  }[]
}

export interface FeatureItem {
  icon: ReactNode
  title: string
  link?: string
}

export const SolutionDetailId = {
  riskManagement: 'risk-management',
  personalizedRecommendation: 'personalized-recommendation',
  operation: 'operation',
  matchmaking: 'matchmaking',
  marketing: 'marketing',
  gamescaleAI: 'gamescale-ai',
  creator: 'creator',
  qa: 'qa',
  analytics: 'analytics',
  userCare: 'user-care',
  business: 'business',
  community: 'community',
  developmentSupport: 'development-support',
}
