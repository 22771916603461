import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { GlobalIcon, MoreIcon } from '@/components/icons'
import { locales } from '@/configs/locales'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import useOnClickOutside from '@/hooks/use-on-click-outside'
import { i18nLanguage } from '@/i18n'

import styles from './language-switcher.module.scss'

type LanguageSwitcherProps = {
  handleCloseNavbar: () => void
}

function LanguageSwitcher({ handleCloseNavbar }: LanguageSwitcherProps) {
  const isDesktop = useMediaQuery(mediaQueryBreakpoints.up('sm2'))
  const {
    i18n: { language: currentLanguage, changeLanguage },
    t,
  } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [active, setActive] = useState(currentLanguage)
  const ref = useRef<HTMLDivElement>(null)

  const toggleDropdown = useCallback(
    (open = false) => {
      setDropdownOpen(() => isDesktop && open)
    },
    [isDesktop],
  )

  if (!isDesktop && dropdownOpen) {
    toggleDropdown()
  }

  const handleChangeLanguage = (lang: string) => {
    handleCloseNavbar()
    toggleDropdown()
    changeLanguage(lang)
    const arrayPath = location.pathname.split('/')
    arrayPath[1] = lang
    navigate(
      {
        pathname: arrayPath.join('/'),
        hash: location.hash,
        search: location.search,
      },
      {
        state: location.state,
      },
    )
  }

  useOnClickOutside(ref, () => toggleDropdown())

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styles.wrapper}
      ref={ref}
      onMouseEnter={() => toggleDropdown(true)}
      onMouseLeave={() => toggleDropdown()}
    >
      <button className={styles.dropdownButton} onClick={() => toggleDropdown(!dropdownOpen)}>
        <div className={styles.globalIcon}>
          <GlobalIcon />
        </div>
        {isDesktop && (
          <div className={clsx(styles.moreIcon, dropdownOpen && styles.reverse)}>
            <MoreIcon />
          </div>
        )}
      </button>
      <div className={clsx(styles.dropdownWrapper, { [styles.open]: isDesktop && dropdownOpen })}>
        <button
          className={clsx(styles.button, locales.KO === active && styles.active)}
          onClick={() => handleChangeLanguage(locales.KO)}
          onMouseEnter={() => setActive(() => locales.KO)}
          onMouseLeave={() => setActive(() => currentLanguage)}
        >
          {t(i18nLanguage.common.ko)}
        </button>
        {!isDesktop && <div className={styles.bar}></div>}
        <button
          className={clsx(styles.button, locales.EN === active && styles.active)}
          onClick={() => handleChangeLanguage(locales.EN)}
          onMouseEnter={() => setActive(() => locales.EN)}
          onMouseLeave={() => setActive(() => currentLanguage)}
        >
          {t(i18nLanguage.common.en)}
        </button>
      </div>
    </div>
  )
}

export default LanguageSwitcher
