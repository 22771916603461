export const PaymentAnomalyDetectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1925 28.12L36.0025 32.9301L32.9325 36L28.1225 31.1899C25.4525 33.1899 22.2825 34.2 19.1025 34.2C15.2325 34.2 11.3725 32.73 8.4225 29.78C2.5225 23.88 2.5225 14.3199 8.4225 8.42004C11.3725 5.46997 15.2325 4 19.1025 4C22.9725 4 26.8325 5.46997 29.7825 8.42004C35.1525 13.8 35.6125 22.21 31.1925 28.12ZM11.4925 11.5C7.30249 15.6899 7.30249 22.52 11.4925 26.71C12.2464 27.4602 13.0892 28.0862 13.9975 28.5773V19.5H29.8478C29.8782 18.6635 29.812 17.8239 29.649 17H13.9975V13H27.9628C27.5986 12.4718 27.1818 11.9694 26.7125 11.5C24.6725 9.46997 21.9825 8.34998 19.1025 8.34998C16.2225 8.34998 13.5225 9.46997 11.4925 11.5ZM16.9975 22.5H20.9975V25.5H16.9975V22.5Z"
        fill="black"
      />
    </svg>
  )
}
