import { useCallback, useState } from 'react'

function useBoolean(initialValue: boolean | (() => boolean) = false) {
  const [value, setValue] = useState<boolean>(initialValue)

  const setTrue = useCallback(() => {
    setValue(true)
  }, [])

  const setFalse = useCallback(() => {
    setValue(false)
  }, [])

  const setToggle = useCallback(() => {
    setValue((prev) => !prev)
  }, [])

  return {
    value,
    setTrue,
    setFalse,
    setToggle,
    setValue,
  }
}

export default useBoolean

export type UseBooleanReturn = ReturnType<typeof useBoolean>
