import { ElementRef, useRef } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'

import clsx from 'clsx'
import useResizeObserver from 'use-resize-observer'

import { PathRoutes } from '@/configs/paths'
import useBoolean from '@/hooks/use-boolean'
import useEventListener from '@/hooks/use-event-listener'

import { ArrowUpIcon } from '../icons'
import { TOP_POSITION } from './scroll-to-top-button.config'
import { scrollToTop } from './scroll-to-top-button.helper'
import styles from './scroll-to-top-button.module.scss'
import { ScrollToTopButtonProps } from './scroll-to-top-button.type'

function ScrollToTopButton({
  top = TOP_POSITION,
  isSmooth = true,
  ...passProps
}: ScrollToTopButtonProps) {
  const btnRef = useRef<ElementRef<'button'>>(null)
  const isVisible = useBoolean(false)
  const location = useLocation()
  const pathname = location.pathname.substring(3)
  const isSolutionDetail = !!matchRoutes([{ path: PathRoutes.SOLUTION_DETAIL }], pathname)
  const isNewsDetail = !!matchRoutes([{ path: PathRoutes.NEWS_DETAIL }], pathname)

  const updateScrollToTopPosition = () => {
    const footer = document.querySelector<HTMLDivElement>('footer')

    if (!footer || !btnRef.current) return
    const footerTop = footer.getBoundingClientRect().top

    if (footerTop > window.innerHeight) {
      btnRef.current.style.position = 'fixed'
    } else {
      btnRef.current.style.position = 'absolute'
    }
  }

  const handleScroll = () => {
    const isTopPosition = document.documentElement.scrollTop >= top
    isVisible.setValue(isTopPosition)
    updateScrollToTopPosition()
  }

  useResizeObserver({
    ref: document.documentElement,
    onResize: updateScrollToTopPosition,
  })

  useEventListener({
    eventType: 'scroll',
    handler: handleScroll,
  })

  const handleScrollToTop = () => {
    scrollToTop(isSmooth)
    isVisible.setFalse()
  }

  return (
    <button
      ref={btnRef}
      className={clsx(styles['scroll-to-top-btn'], {
        [styles.active]: isVisible.value,
        [styles.solutionDetail]: isSolutionDetail,
        [styles.newsDetail]: isNewsDetail,
      })}
      onClick={handleScrollToTop}
      {...passProps}
    >
      <ArrowUpIcon className={styles.icon} />
    </button>
  )
}

export default ScrollToTopButton
