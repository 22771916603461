import { useTranslation } from 'react-i18next'
import { matchRoutes, useLocation } from 'react-router-dom'

import clsx from 'clsx'

import { PathRoutes } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'

import styles from './footer.module.scss'

const MODE = import.meta.env.DEV ? 'Dev' : ''

function Footer() {
  const { t } = useTranslation()

  const location = useLocation()
  const pathname = location.pathname.substring(3)
  const isMain = !!matchRoutes([{ path: PathRoutes.MAIN }], pathname)
  const isNewsDetail = !!matchRoutes([{ path: PathRoutes.NEWS_DETAIL }], pathname)

  return (
    <footer
      className={clsx(styles.wrapper, isMain && styles.main, isNewsDetail && styles.newsDetail)}
    >
      <div className={styles.footer}>
        {t(i18nLanguage.common.footer)} {MODE}
      </div>
    </footer>
  )
}

export default Footer
