import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'

import { TResponse } from '@/types/response'

import ApiClient from '../apiClient'
import { TCategoryResponse, TGetCategoryParams } from './category.type'

export const CATEGORY_BASE_URL = '/categories'

const useGetCategory = (params: TGetCategoryParams) => {
  const endPoint = `${CATEGORY_BASE_URL}?${queryString.stringify(params)}`

  return useQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return (await ApiClient.get<TResponse<TCategoryResponse>>(endPoint)).data.data
    },
    select: (data) => data.categories,
  })
}

export { useGetCategory }
