export const BusinessXLargeIcon = () => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315889">
        <path
          id="Rectangle 34625572"
          d="M0.75 5.22519C0.75 4.59007 1.26487 4.0752 1.9 4.0752H34.1C34.7351 4.0752 35.25 4.59007 35.25 5.2252V14.4252H0.75V5.22519Z"
          fill="#4268FB"
        />
        <path
          id="Rectangle 34625574"
          d="M0.75 28.225C0.75 28.8601 1.26487 29.375 1.9 29.375H34.1C34.7351 29.375 35.25 28.8601 35.25 28.225V14.425H0.75V28.225Z"
          fill="black"
        />
        <rect
          id="Rectangle 34625579"
          x="20.3018"
          y="12.125"
          width="4.6"
          height="4.6"
          rx="0.575"
          transform="rotate(90 20.3018 12.125)"
          fill="white"
        />
        <path
          id="Rectangle 34625602"
          d="M9.375 1.775C9.375 1.13987 9.88987 0.625 10.525 0.625H25.475C26.1101 0.625 26.625 1.13987 26.625 1.775V4.075H9.375V1.775Z"
          fill="black"
        />
      </g>
    </svg>
  )
}
