import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { PathRoutes } from '@/configs/paths'
import LinkTo from '@/layout/header/link-to'
import { NewsDto } from '@/services/news'
import { shortContent } from '@/utils/short-content'

import styles from './news-list-item.module.scss'

interface NewsListItemProps {
  data: NewsDto
}
const NewsListItem = ({ data }: NewsListItemProps) => {
  const [description, setDescription] = useState('')

  useEffect(() => {
    const doc = new DOMParser().parseFromString(data.detail || '', 'text/html')
    setDescription(shortContent(doc))
  }, [data.detail])

  return (
    <LinkTo
      to={`${PathRoutes.NEWS}/${data.id}`}
      className={styles.cardWrapper}
      data-testid="news-list-item-wrapper"
    >
      <div data-testid="news-list-item-badge" className={styles.badge}>
        {data.isNew && (
          <div data-testid="news-list-item-badge-new" className={styles.badgeNew}>
            New
          </div>
        )}
      </div>
      <div data-testid="news-list-item-category" className={styles.category}>
        {data.category.name}
      </div>
      <h1 data-testid="news-list-item-title" className={styles.title}>
        {data.title}
      </h1>
      <div data-testid="news-list-item-thumbnail" className={styles['thumbnail-container']}>
        <img className={styles.thumbnail} src={data.thumbnail} alt="news-thumbnail"></img>
      </div>
      <p data-testid="news-list-item-date" className={styles.date}>
        {dayjs(data.createdDate).format('YYYY-MM-DD')}
      </p>
      {data.description ? (
        <p data-testid="news-list-item-description" className={styles.description}>
          {data.description}
        </p>
      ) : (
        <p
          data-testid="news-list-item-description"
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
    </LinkTo>
  )
}

export default NewsListItem
