export const ProtectionOfMinorsIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.522 15.6611C24.6996 18.1567 22.8205 20.3236 20.3249 20.5012C17.8294 20.6788 15.6624 18.7997 15.4848 16.3042C15.3073 13.8086 17.1864 11.6416 19.6819 11.4641C22.1774 11.2865 24.3444 13.1656 24.522 15.6611Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10.96L20 3L35 10.96V28.43L20 36.39L5 28.43V10.96ZM27.71 27.77L31 26.03V13.37L20 7.53L9 13.37V26.03L12.29 27.77C13.31 24.48 16.38 22.1 20 22.1C23.62 22.1 26.68 24.49 27.71 27.77Z"
        fill="black"
      />
    </svg>
  )
}
