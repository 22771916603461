import { BaseIconProps } from '../base-icon'

function NavigationLeftIcon({ ...passProps }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      className={passProps.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.7952L4.33563 10L12 2.20481L9.83219 0L0 10L9.83219 20L12 17.7952Z"
        fill="white"
      />
    </svg>
  )
}

export default NavigationLeftIcon
