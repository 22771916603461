export const AnalysisXLargeIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.9998" cy="23.0018" r="17.825" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8248 23.0017H22.998V5.17676C22.9986 5.17676 22.9992 5.17676 22.9998 5.17676C32.8442 5.17676 40.8247 13.1572 40.8248 23.0017Z"
        fill="#4268FB"
      />
      <circle cx="23.0016" cy="23.0013" r="6.9" fill="white" />
    </svg>
  )
}
