export const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7745 5.35422L8.51453 9.62422L4.22453 5.32422L3.14453 6.33422L8.51453 11.6742L13.8545 6.36422L12.7745 5.35422Z"
        fill="currentColor"
      />
    </svg>
  )
}
