export const ChatIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7C7.68629 7 5 9.68629 5 13V25C5 28.3137 7.68629 31 11 31H16.1111L19.1258 36.4265C19.5068 37.1123 20.4932 37.1123 20.8742 36.4265L23.8889 31H29C32.3137 31 35 28.3137 35 25V13C35 9.68629 32.3137 7 29 7H11Z"
        fill="black"
      />
      <path
        d="M13 21C14.1046 21 15 20.1046 15 19C15 17.8954 14.1046 17 13 17C11.8954 17 11 17.8954 11 19C11 20.1046 11.8954 21 13 21Z"
        fill="white"
      />
      <path
        d="M20 21C21.1046 21 22 20.1046 22 19C22 17.8954 21.1046 17 20 17C18.8954 17 18 17.8954 18 19C18 20.1046 18.8954 21 20 21Z"
        fill="white"
      />
      <path
        d="M27 21C28.1046 21 29 20.1046 29 19C29 17.8954 28.1046 17 27 17C25.8954 17 25 17.8954 25 19C25 20.1046 25.8954 21 27 21Z"
        fill="white"
      />
    </svg>
  )
}
