import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { backToTop } from '@/utils/back-to-top'

function useBackToTopNewPage() {
  const { pathname } = useLocation()

  useEffect(() => {
    backToTop()
  }, [pathname])
}

export default useBackToTopNewPage
