export const RealTimeBehavioralRestrictionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0512 6L20.7636 17.2875C20.6758 17.2875 20.5879 17.2778 20.5 17.2778C20.0216 17.2778 19.5529 17.3852 19.1135 17.5902L15.1394 13.6162L13.6259 15.1296L17.6 19.1037C17.3461 19.6212 17.2485 20.1875 17.2973 20.7539L12.0636 25.9875C9.52492 22.0818 9.95455 16.8091 13.3818 13.3818C15.3444 11.4192 17.9125 10.4428 20.4902 10.4428C21.2909 10.4428 22.0916 10.5404 22.8727 10.7259L26.1047 7.49394C24.3178 6.72256 22.4138 6.34175 20.5098 6.34175C16.8872 6.34175 13.2646 7.72828 10.5013 10.4916C5.46296 15.5202 5.02357 23.3805 9.13434 28.9168L6 32.0512L8.94882 35L20.2364 23.7125C20.3242 23.7125 20.4121 23.7222 20.5 23.7222C21.3202 23.7222 22.1502 23.4195 22.7751 22.7848C23.1754 22.3845 23.4391 21.8963 23.5855 21.3886H28.6825V19.6017H24.3374L28.9266 15.0125C31.4653 18.9182 31.0357 24.1909 27.6084 27.6182C25.6458 29.5808 23.0778 30.5572 20.5 30.5572C19.6993 30.5572 18.8987 30.4596 18.1175 30.2741L14.8855 33.5061C16.6724 34.2774 18.5764 34.6582 20.4805 34.6582C24.103 34.6582 27.7256 33.2717 30.4889 30.5084C35.5175 25.4798 35.9569 17.6293 31.8461 12.0832L34.9805 8.94882L32.0512 6Z"
        fill="black"
      />
    </svg>
  )
}
