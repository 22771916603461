export const UseCaseLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7871 38.3949C21.2998 38.6763 20.6994 38.6763 20.2121 38.3949L6.32856 30.3793C5.84125 30.0979 5.54105 29.578 5.54105 29.0153L5.54106 12.9839C5.54106 12.4212 5.84125 11.9013 6.32855 11.6199L20.2121 3.60427C20.6994 3.32292 21.2998 3.32292 21.7871 3.60427L35.6707 11.6199C36.158 11.9013 36.4582 12.4212 36.4582 12.9839L36.4582 29.0153C36.4582 29.578 36.158 30.0979 35.6707 30.3793L21.7871 38.3949Z"
        fill="black"
      />
      <circle cx="21.0012" cy="18.6574" r="3.675" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0383 38.6074C21.2971 38.601 21.5547 38.5308 21.7867 38.3969L31.6553 32.6992C29.9649 28.4702 25.8303 25.4824 20.9978 25.4824C16.1658 25.4824 12.0315 28.4696 10.3408 32.6979L20.2117 38.3969C20.4437 38.5308 20.7012 38.601 20.9601 38.6074H21.0383Z"
        fill="#4268FB"
      />
    </svg>
  )
}
