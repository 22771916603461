export const MarketingLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23_1341)">
        <circle cx="21" cy="21.0007" r="16.275" transform="rotate(45 21 21.0007)" fill="black" />
        <circle cx="21" cy="21.0019" r="11.025" transform="rotate(45 21 21.0019)" fill="#4268FB" />
        <circle cx="21" cy="20.9999" r="7.35" transform="rotate(45 21 20.9999)" fill="black" />
        <path
          d="M20.9991 21.003L32.6929 14.8777L28.1267 13.8753L27.1244 9.3092L20.9991 21.003Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_1341">
          <rect width="42" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
