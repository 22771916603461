export const BusinessLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 12.0751C5.25 11.4952 5.7201 11.0251 6.3 11.0251H35.7C36.2799 11.0251 36.75 11.4952 36.75 12.0751V20.4751H5.25V12.0751Z"
        fill="#4268FB"
      />
      <path
        d="M5.25 33.075C5.25 33.6549 5.7201 34.125 6.3 34.125H35.7C36.2799 34.125 36.75 33.6549 36.75 33.075V20.475H5.25V33.075Z"
        fill="black"
      />
      <rect
        x="23.1016"
        y="18.375"
        width="4.2"
        height="4.2"
        rx="0.525"
        transform="rotate(90 23.1016 18.375)"
        fill="white"
      />
      <path
        d="M13.125 8.925C13.125 8.3451 13.5951 7.875 14.175 7.875H27.825C28.4049 7.875 28.875 8.3451 28.875 8.925V11.025H13.125V8.925Z"
        fill="black"
      />
    </svg>
  )
}
