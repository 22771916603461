import { SolutionDetail, SolutionDetailId } from '@/configs/solution-detail'
import {
  analyticsBanner,
  analyticsNav,
  analyticsSEO,
  analyticsSolution,
} from '@/pages/solution-detail/configs/analytics'
import {
  businessBanner,
  businessNav,
  businessSEO,
  businessSolution,
} from '@/pages/solution-detail/configs/business'
import {
  communityBanner,
  communityNav,
  communitySEO,
  communitySolution,
} from '@/pages/solution-detail/configs/community'
import { creatorNav, creatorSEO } from '@/pages/solution-detail/configs/creator'
import {
  developmentBanner,
  developmentSEO,
  developmentSupportNav,
  developmentSupportSolution,
} from '@/pages/solution-detail/configs/development-support'
import {
  gamescaleAIBanner,
  gamescaleAINav,
  gamescaleAISEO,
  gamescaleAISolution,
} from '@/pages/solution-detail/configs/gamescale-ai'
import {
  marketingBanner,
  marketingNav,
  marketingSEO,
  marketingSolution,
} from '@/pages/solution-detail/configs/marketing'
import {
  matchmakingBanner,
  matchmakingNav,
  matchmakingSEO,
  matchmakingSolution,
} from '@/pages/solution-detail/configs/matchmaking'
import {
  operationBanner,
  operationNav,
  operationSEO,
  operationSolution,
} from '@/pages/solution-detail/configs/operation'
import {
  personalizeSEO,
  personalizedRecommendationBanner,
  personalizedRecommendationNav,
  personalizedRecommendationSolution,
} from '@/pages/solution-detail/configs/personalized-recommendation'
import { qaBanner, qaNav, qaSEO, qaSolution } from '@/pages/solution-detail/configs/qa'
import {
  riskManagementBanner,
  riskManagementNav,
  riskManagementSEO,
  riskManagementSolution,
} from '@/pages/solution-detail/configs/riskManagement'
import {
  userCareBanner,
  userCareNav,
  userCareSEO,
  userCareSolution,
} from '@/pages/solution-detail/configs/user-care'

import { creatorBanner, creatorSolution } from './creator'

export const SolutionDetailContent: SolutionDetail[] = [
  {
    id: SolutionDetailId.personalizedRecommendation,
    banner: personalizedRecommendationBanner,
    navSection: personalizedRecommendationNav,
    data: personalizedRecommendationSolution,
    seo: personalizeSEO,
  },
  {
    id: SolutionDetailId.riskManagement,
    banner: riskManagementBanner,
    navSection: riskManagementNav,
    data: riskManagementSolution,
    seo: riskManagementSEO,
  },
  {
    id: SolutionDetailId.operation,
    banner: operationBanner,
    navSection: operationNav,
    data: operationSolution,
    seo: operationSEO,
  },
  {
    id: SolutionDetailId.creator,
    banner: creatorBanner,
    navSection: creatorNav,
    data: creatorSolution,
    seo: creatorSEO,
  },
  {
    id: SolutionDetailId.matchmaking,
    banner: matchmakingBanner,
    navSection: matchmakingNav,
    data: matchmakingSolution,
    seo: matchmakingSEO,
  },
  {
    id: SolutionDetailId.gamescaleAI,
    banner: gamescaleAIBanner,
    navSection: gamescaleAINav,
    data: gamescaleAISolution,
    seo: gamescaleAISEO,
  },
  {
    id: SolutionDetailId.marketing,
    banner: marketingBanner,
    navSection: marketingNav,
    data: marketingSolution,
    seo: marketingSEO,
  },
  {
    id: SolutionDetailId.qa,
    banner: qaBanner,
    navSection: qaNav,
    data: qaSolution,
    seo: qaSEO,
  },
  {
    id: SolutionDetailId.analytics,
    banner: analyticsBanner,
    navSection: analyticsNav,
    data: analyticsSolution,
    seo: analyticsSEO,
  },
  {
    id: SolutionDetailId.userCare,
    banner: userCareBanner,
    navSection: userCareNav,
    data: userCareSolution,
    seo: userCareSEO,
  },
  {
    id: SolutionDetailId.business,
    banner: businessBanner,
    navSection: businessNav,
    data: businessSolution,
    seo: businessSEO,
  },
  {
    id: SolutionDetailId.community,
    banner: communityBanner,
    navSection: communityNav,
    data: communitySolution,
    seo: communitySEO,
  },
  {
    id: SolutionDetailId.developmentSupport,
    banner: developmentBanner,
    navSection: developmentSupportNav,
    data: developmentSupportSolution,
    seo: developmentSEO,
  },
]
