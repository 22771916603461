import { useEffect, useRef } from 'react'

import throttle from 'lodash.throttle'

import { getDocumentHeight } from '@/utils/get-document-height'

const MIN_PERCENT = 0
const MAX_PERCENT = 100

export function useScrollIndicator<TRef extends HTMLElement>(throttleTime = 100) {
  const ref = useRef<TRef>(null)

  const calculateScrollPercent = () => {
    if (!ref.current) return

    const documentHeight = getDocumentHeight()
    const scrollY = window.scrollY
    const scrollHeight = documentHeight - window.innerHeight
    let scrollPercent = (scrollY / scrollHeight) * MAX_PERCENT

    if (scrollPercent < MIN_PERCENT) scrollPercent = MIN_PERCENT
    if (scrollPercent > MAX_PERCENT) scrollPercent = MAX_PERCENT

    ref.current.style.width = `${scrollPercent}%`
  }

  useEffect(() => {
    const calculateScrollPercentThrottle = throttle(
      () => requestAnimationFrame(calculateScrollPercent),
      throttleTime,
      {
        leading: false,
        trailing: true,
      },
    )

    window.addEventListener('scroll', calculateScrollPercentThrottle)
    window.addEventListener('resize', calculateScrollPercentThrottle)

    return () => {
      window.removeEventListener('scroll', calculateScrollPercentThrottle)
      window.removeEventListener('resize', calculateScrollPercentThrottle)
    }
  }, [throttleTime])

  return ref
}
