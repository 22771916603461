import Container from '@/components/container'
import { solutionList, solutionListMore } from '@/configs/solution'
import { ExpandedSolutionCard } from '@/pages/home/components/expanded-solution-card'
import { SolutionCardGroup } from '@/pages/home/components/solution-card-group'

import style from './SolutionSection.module.scss'

export const SolutionSection = () => {
  return (
    <div className={style.container}>
      <Container data-testid="container-test">
        <p className={style.header}>GameScale Solutions</p>
        <SolutionCardGroup list={solutionList} />
        <div data-testid="data-test-id-divider" className={style.divider}></div>
        <ExpandedSolutionCard list={solutionListMore} />
      </Container>
    </div>
  )
}
