export const UseCaseMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5625 27.4252C15.2144 27.6262 14.7856 27.6262 14.4375 27.4252L4.52068 21.6998C4.1726 21.4988 3.95818 21.1274 3.95818 20.7255L3.95818 9.27452C3.95818 8.87259 4.1726 8.5012 4.52068 8.30024L14.4375 2.57476C14.7856 2.3738 15.2144 2.3738 15.5625 2.57476L25.4793 8.30024C25.8274 8.5012 26.0418 8.87259 26.0418 9.27452L26.0418 20.7255C26.0418 21.1274 25.8274 21.4988 25.4793 21.6998L15.5625 27.4252Z"
        fill="black"
      />
      <circle cx="15.002" cy="13.3262" r="2.625" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4397 27.4254C14.7877 27.6263 15.2166 27.6263 15.5647 27.4254L22.614 23.3554C21.4064 20.335 18.4533 18.2012 15.0017 18.2012C11.5504 18.2012 8.59731 20.3348 7.38965 23.355L14.4397 27.4254Z"
        fill="#4268FB"
      />
    </svg>
  )
}
