import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'

import { queryClient } from '@/configs/react-query'
import { Locale } from '@/types/locales'
import { TResponse } from '@/types/response'

import ApiClient from '../apiClient'
import { TGetNewsListParams, TNewsDetailResponse, TNewsListResponse } from './news.type'

export const NEWS_BASE_URL = '/news'

const useGetMainNewsList = (lang: Locale) => {
  const endPoint = `${NEWS_BASE_URL}/latest?langType=${lang}&limit=4`

  return useQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return (await ApiClient.get<TResponse<TNewsListResponse>>(endPoint)).data.data
    },
  })
}

const useGetNewsHeroList = (lang: Locale) => {
  const endPoint = `${NEWS_BASE_URL}/fixed/?langType=${lang}`

  return useQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return (await ApiClient.get<TResponse<TNewsListResponse>>(endPoint)).data.data
    },
  })
}

const getNewsByNewsId = (id: string, lang: Locale) => {
  const endPoint = `${NEWS_BASE_URL}/${id}?langType=${lang}`

  return queryClient.fetchQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return (await ApiClient.get<TResponse<TNewsDetailResponse>>(endPoint)).data.data
    },
  })
}

const useGetNewsByNewsId = (id: string, lang: Locale) => {
  const endPoint = `${NEWS_BASE_URL}/${id}?langType=${lang}`

  return useQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return (await ApiClient.get<TResponse<TNewsDetailResponse>>(endPoint)).data.data
    },
    select: (response) => {
      return response.detail
    },
  })
}
const useGetNewsList = (params: TGetNewsListParams, options: { enabled?: boolean }) => {
  const endPoint = `${NEWS_BASE_URL}?${queryString.stringify(params)}`

  return useQuery({
    queryKey: [endPoint],
    queryFn: async () => {
      return await ApiClient.get<TResponse<TNewsListResponse>>(endPoint)
    },
    select: (response) => {
      return {
        newsList: response.data.data.newsList,
        pagination: response.data.meta,
      }
    },
    ...options,
  })
}

export {
  useGetMainNewsList,
  useGetNewsHeroList,
  useGetNewsList,
  getNewsByNewsId,
  useGetNewsByNewsId,
}
