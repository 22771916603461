export const CustomerCenterIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2403 32.0171C12.1454 30.779 9.69007 28.2746 8.51591 25.1503C5.46759 25.1498 3.00003 22.682 3.00003 19.6336C3.00003 16.7312 5.23682 14.3552 8.082 14.1338C9.32448 8.90616 14.0378 5 19.6372 5C25.2307 5 29.9399 8.89778 31.1885 14.117C34.2304 14.1249 36.6905 16.5898 36.6905 19.6336C36.6905 22.6822 34.2226 25.1502 31.174 25.1502L27.6412 25.1539V16.8753C27.6412 12.462 24.0506 8.87134 19.6372 8.87134C15.2239 8.87134 11.6333 12.462 11.6333 16.8753V20.9855C11.6333 24.1143 13.4379 26.8296 16.061 28.1452C16.8898 27.0806 18.1835 26.3958 19.6372 26.3958C22.1388 26.3958 24.1667 28.4237 24.1667 30.9252C24.1667 33.4268 22.1388 35.4547 19.6372 35.4547C17.5121 35.4547 15.7288 33.9912 15.2403 32.0171Z"
        fill="black"
      />
    </svg>
  )
}
