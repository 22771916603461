export const shortContent = (doc: Document) => {
  const images = doc.querySelectorAll('img')
  images.forEach((img) => img.remove())

  const brs = doc.querySelectorAll('br')
  brs.forEach((br) => br.remove())

  const pres = doc.querySelectorAll('pre')
  pres.forEach((pre) => pre.remove())

  let extractedText = ''
  doc.body.childNodes.forEach((node) => {
    if (node.nodeName !== 'IMG') {
      extractedText += node.textContent?.trim() + '<drop>'
    }
  })

  return extractedText.split('<drop>').slice(0, 5).join('')
}
