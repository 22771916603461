export const solution = {
  main: {
    risk_management: 'Risk Management',
    risk_management_description:
      'Boosts game stability with thorough security measures including protection, detection, and sanctions.',
    personalized_recommendation: 'Personalized Recommendation',
    personalized_recommendation_description:
      'Precisely analyzes data to provide users with personalized content at just the right moments.',
    matchmaking: 'Matchmaking',
    matchmaking_description:
      'Analyzes game rules and individual user situations to match with the optimal opponent, enhancing the gaming experience.',
    creators: 'Creators',
    creators_description:
      'Expands market and content share through strengthened collaboration with creators.',
    operation: 'Operation',
    operation_description:
      'Enhances user satisfaction by supporting stable game operations through various tools and systems.',
    game_scale_ai: 'GameScale AI',
    game_scale_ai_description:
      'Predicts, analyzes, and decides what your game needs, and recommends and creates content in real time.',
    qa: 'QA',
    qa_description:
      'Manages the optimal gameplay environments through professional testing utilizing various support tools.',
    user_care: 'User Care',
    user_care_description:
      'Creates a trusted gaming environment with user feedback channels and systematic management features.',
    community: 'Community',
    community_description:
      'Fosters a healthy and smooth communication environment to aid in game development.',
    analytics: 'Analytics',
    analytics_description:
      'Provides in-depth insights through specialized data analysis focused on games and IP content.',
    business: 'Business',
    business_description:
      'Presents a roadmap where positive player experiences lead to increased game revenue.',
    marketing: 'Marketing',
    marketing_description:
      'Supports comprehensive marketing to attract, retain, and reactivate users.',
    development_support: 'Development Support',
    development_support_description:
      'Supports development of various features for game implementation and release, enhancing development efficiency.',
  },
}
