export const PcCafeAffiliationIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 10H9V22H31V10ZM9 6H5V10V22V26H9H31H35V22V10V6H31H9ZM17 28H23V31H27V34H13V31H17V28Z"
        fill="#030303"
      />
    </svg>
  )
}
