import { type RefObject } from 'react'

import useEventListener from '@/hooks/use-event-listener'

export default function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
) {
  const handlers = (event: MouseEvent | TouchEvent | FocusEvent) => {
    const target = event.target as Node

    const isOutside = ref.current && !ref.current.contains(target)

    if (isOutside) {
      handler(event)
    }
  }
  useEventListener({ eventType: 'mousedown', handler: handlers })
}
