export const AnalysisLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.9996" cy="21.0016" r="16.275" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2746 21.0015H20.998V4.72656C20.9986 4.72656 20.9991 4.72656 20.9996 4.72656C29.988 4.72656 37.2746 12.0131 37.2746 21.0015Z"
        fill="#4268FB"
      />
      <circle cx="21.0012" cy="21.0012" r="6.3" fill="white" />
    </svg>
  )
}
