export const CommunityLargeIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="37.8008"
        y="12.5996"
        width="22.05"
        height="28.35"
        rx="1.05"
        transform="rotate(90 37.8008 12.5996)"
        fill="#4268FB"
      />
      <rect
        x="32.5508"
        y="7.34961"
        width="22.05"
        height="28.35"
        rx="1.05"
        transform="rotate(90 32.5508 7.34961)"
        fill="black"
      />
      <circle cx="18.3746" cy="18.3746" r="1.8375" fill="white" />
      <circle cx="25.1988" cy="18.3746" r="1.8375" fill="white" />
      <circle cx="11.5504" cy="18.3746" r="1.8375" fill="white" />
    </svg>
  )
}
