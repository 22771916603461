import logo from '@/assets/images/img_development.png'
import {
  ClientPerformanceMeasurementIcon,
  CrashReportIcon,
  CurrencyIcon,
  DeploymentIcon,
  IntegratedAccountLinkingIcon,
  LauncherIcon,
  MemberChannelingIcon,
  NexonLoginIcon,
  NexonMemberIcon,
  PaymentsIcon,
  ServerBuildingLibraryIcon,
  StoreIcon,
  WebviewSupportIcon,
  WithdrawalIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.developmentSupport

export const developmentBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}
export const developmentSEO = i18n.seo

const authentication: SolutionDetailData = {
  id: 'authentication',
  name: i18n.authentication.name,
  title: [i18n.authentication.title1, i18n.authentication.title2],
  description: i18n.authentication.description,
  featureItems: [
    {
      icon: <NexonLoginIcon />,
      title: i18n.authentication.features.nexonLogin,
      link: i18n.authentication.features.nexonLoginLink,
    },
    {
      icon: <NexonMemberIcon />,
      title: i18n.authentication.features.nexonMembers,
      link: i18n.authentication.features.nexonMembersLink,
    },
    {
      icon: <IntegratedAccountLinkingIcon />,
      title: i18n.authentication.features.integratedAccountLinking,
      link: i18n.authentication.features.integratedAccountLinkingLink,
    },
    {
      icon: <MemberChannelingIcon />,
      title: i18n.authentication.features.memberChanneling,
      link: i18n.authentication.features.memberChannelingLink,
    },
  ],
  featureDetails: [],
}

const commerce: SolutionDetailData = {
  id: 'commerce',
  name: i18n.commerce.name,
  title: [i18n.commerce.title1, i18n.commerce.title2],
  description: i18n.commerce.description,
  featureItems: [
    {
      icon: <PaymentsIcon />,
      title: i18n.commerce.features.payments,
      link: i18n.commerce.features.paymentsLink,
    },
    {
      icon: <StoreIcon />,
      title: i18n.commerce.features.store,
      link: i18n.commerce.features.storeLink,
    },
    {
      icon: <CurrencyIcon />,
      title: i18n.commerce.features.currency,
      link: i18n.commerce.features.currencyLink,
    },
    {
      icon: <WithdrawalIcon />,
      title: i18n.commerce.features.withdrawal,
      link: i18n.commerce.features.withdrawalLink,
    },
  ],
  featureDetails: [],
}

const clientAndServerUtilites: SolutionDetailData = {
  id: 'clientAndServerUtilites',
  name: i18n.clientAndServerUtilites.name,
  title: [i18n.clientAndServerUtilites.title1, i18n.clientAndServerUtilites.title2],
  description: i18n.clientAndServerUtilites.description,
  featureItems: [
    {
      icon: <ServerBuildingLibraryIcon />,
      title: i18n.clientAndServerUtilites.features.serverBuildingLibrary,
      link: i18n.clientAndServerUtilites.features.serverBuildingLibraryLink,
    },
    {
      icon: <LauncherIcon />,
      title: i18n.clientAndServerUtilites.features.launcher,
      link: i18n.clientAndServerUtilites.features.launcherLink,
    },
    {
      icon: <DeploymentIcon />,
      title: i18n.clientAndServerUtilites.features.deployment,
      link: i18n.clientAndServerUtilites.features.deploymentLink,
    },
    {
      icon: <CrashReportIcon />,
      title: i18n.clientAndServerUtilites.features.crashReport,
      link: i18n.clientAndServerUtilites.features.crashReportLink,
    },
    {
      icon: <ClientPerformanceMeasurementIcon />,
      title: i18n.clientAndServerUtilites.features.clientPerformanceMeasurement,
      link: i18n.clientAndServerUtilites.features.clientPerformanceMeasurementLink,
    },
  ],
  featureDetails: [],
}

const developmentModule: SolutionDetailData = {
  id: 'developmentModule',
  name: i18n.developmentModule.name,
  title: [i18n.developmentModule.title1, i18n.developmentModule.title2],
  description: i18n.developmentModule.description,
  featureItems: [
    {
      icon: <WebviewSupportIcon />,
      title: i18n.developmentModule.features.webViewSupport,
      link: i18n.developmentModule.features.webViewSupportLink,
    },
  ],
  featureDetails: [],
}

export const developmentSupportNav: NavSectionItem[] = [
  {
    id: authentication.id,
    name: authentication.name,
  },
  {
    id: commerce.id,
    name: commerce.name,
  },
  {
    id: clientAndServerUtilites.id,
    name: clientAndServerUtilites.name,
  },
  {
    id: developmentModule.id,
    name: developmentModule.name,
  },
]

export const developmentSupportSolution: SolutionDetailData[] = [
  authentication,
  commerce,
  clientAndServerUtilites,
  developmentModule,
]
