export function OutlineIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      display="block"
    >
      <g clipPath="url(#clip0_2686_588)">
        <mask
          id="mask0_2686_588"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2686_588)">
          <path
            d="M7.07051 4L2 9.07051L3.41421 10.4847L8.49998 5.39896L8.49998 9H10.5V2H3.49998V4H7.07051Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2686_588">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
