import logo from '@/assets/images/img_risk.png'
import {
  AccountProtectionIcon,
  AccountTrustRatingIcon,
  AntiTamperingIcon,
  CheatDetectionIcon,
  ExecutionRestrictionIcon,
  GameAnomalyDetectionIcon,
  GameProtectionIcon,
  ImageDetectionIcon,
  NetworkBlockingIcon,
  PaymentAnomalyDetectionIcon,
  RealTimeBehavioralRestrictionIcon,
  RealTimeWeightControlIcon,
  TextDetectionIcon,
  TwoFactorAuthenticationIcon,
  VisionHackDetectionIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.riskManagement

export const riskManagementBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const riskManagementSEO = i18n.seo

const authenticationSecurity: SolutionDetailData = {
  id: 'authentication-security',
  name: i18n.authenticationSecurity.name,
  title: [
    i18n.authenticationSecurity.title1,
    i18n.authenticationSecurity.title2,
    i18n.authenticationSecurity.title3,
  ],
  description: i18n.authenticationSecurity.description,
  featureItems: [
    {
      icon: <AccountProtectionIcon />,
      title: i18n.authenticationSecurity.features.accountProtectionSystem,
      link: i18n.authenticationSecurity.features.accountProtectionSystemLink,
    },
    {
      icon: <TwoFactorAuthenticationIcon />,
      title: i18n.authenticationSecurity.features.twoFactorAuthentication,
      link: i18n.authenticationSecurity.features.twoFactorAuthenticationLink,
    },
    {
      icon: <AccountTrustRatingIcon />,
      title: i18n.authenticationSecurity.features.accountTrustRating,
      link: i18n.authenticationSecurity.features.accountTrustRatingLink,
    },
  ],
  featureDetails: [],
}

const integrityObfuscation: SolutionDetailData = {
  id: 'integrity-obfuscation',
  name: i18n.integrityObfuscation.name,
  title: [
    i18n.integrityObfuscation.title1,
    i18n.integrityObfuscation.title2,
    i18n.integrityObfuscation.title3,
  ],
  description: i18n.integrityObfuscation.description,
  featureItems: [
    {
      icon: <GameProtectionIcon />,
      title: i18n.integrityObfuscation.features.gameProtectionModule,
      link: i18n.integrityObfuscation.features.gameProtectionModuleLink,
    },
    {
      icon: <AntiTamperingIcon />,
      title: i18n.integrityObfuscation.features.antiTamperingTechnology,
      link: i18n.integrityObfuscation.features.antiTamperingTechnologyLink,
    },
  ],
  featureDetails: [],
}

const detection: SolutionDetailData = {
  id: 'detection',
  name: i18n.detection.name,
  title: [i18n.detection.title1, i18n.detection.title2, i18n.detection.title3],
  description: i18n.detection.description,
  featureItems: [
    {
      icon: <GameAnomalyDetectionIcon />,
      title: i18n.detection.features.gameAnomalyDetection,
      link: i18n.detection.features.gameAnomalyDetectionLink,
    },
    {
      icon: <CheatDetectionIcon />,
      title: i18n.detection.features.cheatDetection,
      link: i18n.detection.features.cheatDetectionLink,
    },
    {
      icon: <VisionHackDetectionIcon />,
      title: i18n.detection.features.visionHackDetection,
      link: i18n.detection.features.visionHackDetectionLink,
    },
    {
      icon: <TextDetectionIcon />,
      title: i18n.detection.features.textDetection,
      link: i18n.detection.features.textDetectionLink,
    },
    {
      icon: <ImageDetectionIcon />,
      title: i18n.detection.features.imageDetection,
      link: i18n.detection.features.imageDetectionLink,
    },
  ],
  featureDetails: [],
}

const safeSecureEnvironment: SolutionDetailData = {
  id: 'safe-secure-environment',
  name: i18n.safeSecureEnvironment.name,
  title: [
    i18n.safeSecureEnvironment.title1,
    i18n.safeSecureEnvironment.title2,
    i18n.safeSecureEnvironment.title3,
  ],
  description: i18n.safeSecureEnvironment.description,
  featureItems: [
    {
      icon: <PaymentAnomalyDetectionIcon />,
      title: i18n.safeSecureEnvironment.features.paymentAnomalyDetection,
      link: i18n.safeSecureEnvironment.features.paymentAnomalyDetectionLink,
    },
    {
      icon: <NetworkBlockingIcon />,
      title: i18n.safeSecureEnvironment.features.networkBlockingSystem,
      link: i18n.safeSecureEnvironment.features.networkBlockingSystemLink,
    },
  ],
  featureDetails: [],
}

const restrictionManagement: SolutionDetailData = {
  id: 'restriction-management',
  name: i18n.restrictionManagement.name,
  title: [i18n.restrictionManagement.title1, i18n.restrictionManagement.title2],
  description: i18n.restrictionManagement.description,
  featureItems: [
    {
      icon: <ExecutionRestrictionIcon />,
      title: i18n.restrictionManagement.features.executionRestrictionSystem,
      link: i18n.restrictionManagement.features.executionRestrictionSystemLink,
    },
    {
      icon: <RealTimeBehavioralRestrictionIcon />,
      title: i18n.restrictionManagement.features.realtimeBehavioralRestrictionSystem,
      link: i18n.restrictionManagement.features.realtimeBehavioralRestrictionSystemLink,
    },
    {
      icon: <RealTimeWeightControlIcon />,
      title: i18n.restrictionManagement.features.realtimeWeightControlSystem,
      link: i18n.restrictionManagement.features.realtimeWeightControlSystemLink,
    },
  ],
  featureDetails: [],
}

export const riskManagementNav: NavSectionItem[] = [
  {
    id: authenticationSecurity.id,
    name: authenticationSecurity.name,
  },
  {
    id: integrityObfuscation.id,
    name: integrityObfuscation.name,
  },
  {
    id: detection.id,
    name: detection.name,
  },
  {
    id: safeSecureEnvironment.id,
    name: safeSecureEnvironment.name,
  },
  {
    id: restrictionManagement.id,
    name: restrictionManagement.name,
  },
]

export const riskManagementSolution: SolutionDetailData[] = [
  authenticationSecurity,
  integrityObfuscation,
  detection,
  safeSecureEnvironment,
  restrictionManagement,
]
