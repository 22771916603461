export const UxResearchPlatformIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2489 18.4803C16.6645 18.4803 19.4334 15.6865 19.4334 12.2401C19.4334 8.79381 16.6645 6 13.2489 6C9.83328 6 7.06439 8.79381 7.06439 12.2401C7.06439 15.6865 9.83328 18.4803 13.2489 18.4803Z"
        fill="black"
      />
      <path
        d="M23.4977 31C23.4977 25.2922 18.9057 20.6589 13.2489 20.6589C7.59195 20.6589 3 25.2922 3 31H23.4977Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4334 12.2401C22.4334 14.9811 21.2425 17.4554 19.3447 19.1522C20.3115 19.658 21.2081 20.2801 22.0166 21H35V23H23.8496C24.5326 23.9164 25.101 24.9237 25.5339 26H35V28H26.1604C26.3811 28.965 26.4977 29.9693 26.4977 31H37V9H21.8519C22.228 10.0097 22.4334 11.1019 22.4334 12.2401ZM29.4078 14.6482L32.056 12L33.8159 13.7599L29.4084 18.1674L25 13.7603L26.7599 12.0004L29.4078 14.6482Z"
        fill="black"
      />
    </svg>
  )
}
