export const BusinessMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 8.625C3.75 8.21079 4.08579 7.875 4.5 7.875H25.5C25.9142 7.875 26.25 8.21079 26.25 8.625V14.625H3.75V8.625Z"
        fill="#4268FB"
      />
      <path
        d="M3.75 23.625C3.75 24.0392 4.08579 24.375 4.5 24.375H25.5C25.9142 24.375 26.25 24.0392 26.25 23.625V14.625H3.75V23.625Z"
        fill="black"
      />
      <rect
        x="16.502"
        y="13.125"
        width="3"
        height="3"
        rx="0.375"
        transform="rotate(90 16.502 13.125)"
        fill="white"
      />
      <path
        d="M9.375 6.375C9.375 5.96079 9.71079 5.625 10.125 5.625H19.875C20.2892 5.625 20.625 5.96079 20.625 6.375V7.875H9.375V6.375Z"
        fill="black"
      />
    </svg>
  )
}
