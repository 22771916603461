export const SupportMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 8.62549H26.25V24.4733C26.25 24.8335 25.958 25.1255 25.5978 25.1255H4.40217C4.04199 25.1255 3.75 24.8335 3.75 24.4733V8.62549Z"
        fill="black"
      />
      <path
        d="M3.75 5.52742C3.75 5.16723 4.04199 4.87524 4.40217 4.87524H25.5978C25.958 4.87524 26.25 5.16723 26.25 5.52742V8.62524H3.75V5.52742Z"
        fill="#4268FB"
      />
      <path d="M18.748 14.2229L21.3997 16.8746L18.748 19.5262" stroke="white" strokeWidth="1.5" />
      <path d="M11.248 14.2229L8.5964 16.8746L11.248 19.5262" stroke="white" strokeWidth="1.5" />
    </svg>
  )
}
