import { BaseIconProps } from '../base-icon'

function RightIcon({ ...passProps }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      className={passProps.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5501 16.4909L15.848 12.8127L17.5221 11.0234L22.9409 16.4909V18.9764H3.05859V16.4909H19.5501Z"
        fill="black"
      />
    </svg>
  )
}

export default RightIcon
