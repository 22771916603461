import logo from '@/assets/images/img_business.png'
import {
  CreatorsIcon,
  DataExtractionIcon,
  NXLogReportIcon,
  PaymentsIcon,
  PcCafeAffiliationIcon,
  ProductRecommendationIcon,
  RetentionKeeperIcon,
  StoreIcon,
  UserProfileIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.business

export const businessBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const businessSEO = i18n.seo

const insights: SolutionDetailData = {
  id: 'insights',
  name: i18n.insights.name,
  title: [i18n.insights.title1, i18n.insights.title2],
  description: i18n.insights.description,
  featureItems: [
    {
      icon: <NXLogReportIcon />,
      title: i18n.insights.features.nxLogReport,
      link: i18n.insights.features.nxLogReportLink,
    },
    {
      icon: <UserProfileIcon />,
      title: i18n.insights.features.userProfile,
      link: i18n.insights.features.userProfileLink,
    },
    {
      icon: <DataExtractionIcon />,
      title: i18n.insights.features.dataExtractionTool,
      link: i18n.insights.features.dataExtractionToolLink,
    },
  ],
  featureDetails: [],
}

const retentionStrategy: SolutionDetailData = {
  id: 'retentionStrategy',
  name: i18n.retentionStrategy.name,
  title: [i18n.retentionStrategy.title1, i18n.retentionStrategy.title2],
  description: i18n.retentionStrategy.description,
  featureItems: [
    {
      icon: <RetentionKeeperIcon />,
      title: i18n.retentionStrategy.features.retentionKeeper,
      link: i18n.retentionStrategy.features.retentionKeeperLink,
    },
    {
      icon: <CreatorsIcon />,
      title: i18n.retentionStrategy.features.creators,
      link: i18n.retentionStrategy.features.creatorsLink,
    },
  ],
  featureDetails: [],
}

const commerce: SolutionDetailData = {
  id: 'commerce',
  name: i18n.commerce.name,
  title: [i18n.commerce.title1, i18n.commerce.title2],
  description: i18n.commerce.description,
  featureItems: [
    {
      icon: <ProductRecommendationIcon />,
      title: i18n.commerce.features.productRecommendation,
      link: i18n.commerce.features.productRecommendationLink,
    },
    {
      icon: <StoreIcon />,
      title: i18n.commerce.features.store,
      link: i18n.commerce.features.storeLink,
    },
    {
      icon: <PaymentsIcon />,
      title: i18n.commerce.features.payments,
      link: i18n.commerce.features.paymentsLink,
    },
  ],
  featureDetails: [],
}

const pcCafe: SolutionDetailData = {
  id: 'pcCafe',
  name: i18n.pcCafe.name,
  title: [i18n.pcCafe.title1, i18n.pcCafe.title2],
  description: i18n.pcCafe.description,
  featureItems: [
    {
      icon: <PcCafeAffiliationIcon />,
      title: i18n.pcCafe.features.pcCafeAffiliation,
      link: i18n.pcCafe.features.pcCafeAffiliationLink,
    },
  ],
  featureDetails: [],
}

export const businessNav: NavSectionItem[] = [
  {
    id: insights.id,
    name: insights.name,
  },
  {
    id: retentionStrategy.id,
    name: retentionStrategy.name,
  },
  {
    id: commerce.id,
    name: commerce.name,
  },
  {
    id: pcCafe.id,
    name: pcCafe.name,
  },
]

export const businessSolution: SolutionDetailData[] = [
  insights,
  retentionStrategy,
  commerce,
  pcCafe,
]
