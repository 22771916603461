import clsx from 'clsx'

import styles from './skeleton.module.scss'

interface SkeletonProps {
  className?: string
  width?: number
  height?: number
}

const Skeleton = ({ className, width, height }: SkeletonProps) => {
  return (
    <div
      style={{ width: `${width}px`, height: `${height}px` }}
      className={clsx(styles.skeleton, className)}
    ></div>
  )
}

export default Skeleton
