import clsx from 'clsx'

import Container from '@/components/container'
import { locales } from '@/configs/locales'
import { SolutionDetailId } from '@/configs/solution-detail'
import { useLocale } from '@/hooks/use-locale'
import { Locale } from '@/types/locales'

import styles from './banner-solution.module.scss'

type BannerSolutionProps = {
  id: string
  title: string
  subtitle: string
  description: string
  logo: string
}
const BannerSolution = ({ title, subtitle, description, logo, id }: BannerSolutionProps) => {
  const locale = useLocale()
  const lang = locale as Locale
  return (
    <div className={styles.wrapper}>
      <Container isSubPage className={styles['container']}>
        <div className={styles['image-overlay']}>
          <img src={logo} alt="overlay" />
        </div>
        <div className={styles['banner-content']}>
          {lang === locales.EN ? (
            <>
              <h3 className={clsx(styles['banner-content--title'], styles[`${id}-title-en`])}>
                {title}
              </h3>
              <p className={clsx(styles['banner-content--subtitle'], styles[`${id}-subtitle-en`])}>
                {subtitle}
              </p>
              <p
                className={clsx(
                  styles['banner-content--description'],
                  styles[`${id}-description-en`],
                )}
              >
                {description}
              </p>
            </>
          ) : (
            <>
              <p
                className={clsx(styles['banner-content--subtitle'], styles[`${id}-subtitle-ko`], {
                  [styles['banner-content--subtitle-personalized']]:
                    id === SolutionDetailId.personalizedRecommendation,
                })}
              >
                {subtitle}
              </p>
              <h3 className={clsx(styles['banner-content--title'], styles[`${id}-title-ko`])}>
                {title}
              </h3>
              <p
                className={clsx(
                  styles['banner-content--description'],
                  styles[`${id}-description-ko`],
                  {
                    [styles['banner-content--description-personalized']]:
                      id === SolutionDetailId.personalizedRecommendation,
                  },
                )}
              >
                {description}
              </p>
            </>
          )}
        </div>
        <div className={styles['banner-logo']}>
          <img src={logo} alt="logo" />
        </div>
      </Container>
    </div>
  )
}
export default BannerSolution
