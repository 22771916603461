import logo from '@/assets/images/img_qa.png'
import {
  ClientPerformanceMeasurementIcon,
  CrashReportIcon,
  QaAutomationAndOptimizationIcon,
  QaSupportToolsIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.qa

export const qaBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const qaSEO = i18n.seo

const advancedTools: SolutionDetailData = {
  id: 'advancedTools',
  name: i18n.advancedTools.name,
  title: [i18n.advancedTools.title],
  description: i18n.advancedTools.description,
  featureItems: [
    {
      icon: <QaAutomationAndOptimizationIcon />,
      title: i18n.advancedTools.features.qaAutomation,
      link: i18n.advancedTools.features.qaAutomationLink,
    },
    {
      icon: <QaSupportToolsIcon />,
      title: i18n.advancedTools.features.qaSupportTools,
      link: i18n.advancedTools.features.qaSupportToolsLink,
    },
    {
      icon: <ClientPerformanceMeasurementIcon />,
      title: i18n.advancedTools.features.clientPerformanceMeasurement,
      link: i18n.advancedTools.features.clientPerformanceMeasurementLink,
    },
    {
      icon: <CrashReportIcon />,
      title: i18n.advancedTools.features.crashReport,
      link: i18n.advancedTools.features.crashReportLink,
    },
  ],
  featureDetails: [],
}

const qualityVerification: SolutionDetailData = {
  id: 'qualityVerification',
  name: i18n.qualityVerification.name,
  title: [i18n.qualityVerification.title1, i18n.qualityVerification.title2],
  description: i18n.qualityVerification.description,
  featureDetails: [
    {
      title: i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification.title,
      description: [
        {
          title:
            i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification
              .hardwareCompatibilityTest.title,
          contents: [
            i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification
              .hardwareCompatibilityTest.content1,
            i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification
              .hardwareCompatibilityTest.content2,
          ],
        },
        {
          title:
            i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification
              .performanceTest.title,
          contents: [
            i18n.qualityVerification.featureDetails.hardwareCompatibilityVerification
              .performanceTest.content,
          ],
        },
      ],
    },
    {
      title: i18n.qualityVerification.featureDetails.softwareCompatibilityVerification.title,
      description: [
        {
          title:
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .creationOfArtificialEntryBarriers.title,
          contents: [
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .creationOfArtificialEntryBarriers.content,
          ],
        },
        {
          title:
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .verificationOfExecutableOSVersions.title,
          contents: [
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .verificationOfExecutableOSVersions.content,
          ],
        },
        {
          title:
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .antivirusCompatibilityVerification.title,
          contents: [
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .antivirusCompatibilityVerification.content,
          ],
        },
        {
          title:
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .worldwideAntiVirusTest.title,
          contents: [
            i18n.qualityVerification.featureDetails.softwareCompatibilityVerification
              .worldwideAntiVirusTest.content,
          ],
        },
      ],
    },
  ],
  featureItems: [],
}

export const qaNav: NavSectionItem[] = [
  {
    id: advancedTools.id,
    name: advancedTools.name,
  },
  {
    id: qualityVerification.id,
    name: qualityVerification.name,
  },
]

export const qaSolution: SolutionDetailData[] = [advancedTools, qualityVerification]
