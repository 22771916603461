import logo from '@/assets/images/img_match making.png'
import { CheatDetectionIcon, MatchMobIcon, UserProfileIcon } from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.matchmaking

export const matchmakingBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const matchmakingSEO = i18n.seo

const matchmaking: SolutionDetailData = {
  id: 'matchmaking-section',
  name: i18n.matchmaking.name,
  title: [i18n.matchmaking.title1, i18n.matchmaking.title2],
  description: i18n.matchmaking.description,
  featureItems: [
    {
      icon: <MatchMobIcon />,
      title: i18n.matchmaking.features.matchMob,
      link: i18n.matchmaking.features.matchMobLink,
    },
  ],
  featureDetails: [],
}

const optimization: SolutionDetailData = {
  id: 'optimization',
  name: i18n.optimization.name,
  title: [i18n.optimization.title1, i18n.optimization.title2, i18n.optimization.title3],
  description: i18n.optimization.description,
  featureItems: [
    {
      icon: <UserProfileIcon />,
      title: i18n.optimization.features.userProfile,
      link: i18n.optimization.features.userProfileLink,
    },
    {
      icon: <CheatDetectionIcon />,
      title: i18n.optimization.features.cheatDetection,
      link: i18n.optimization.features.cheatDetectionLink,
    },
  ],
  featureDetails: [],
}

export const matchmakingNav: NavSectionItem[] = [
  {
    id: matchmaking.id,
    name: matchmaking.name,
  },
  {
    id: optimization.id,
    name: optimization.name,
  },
]

export const matchmakingSolution: SolutionDetailData[] = [matchmaking, optimization]
