type ChevronRightIconType = {
  width?: number
  height?: number
  className?: string
}

export const ChevronRightIcon = ({ width = 16, height = 16, className }: ChevronRightIconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05709 12.6666L9.72376 7.9999L5.05709 3.33323L5.9999 2.39042L11.6094 7.9999L5.9999 13.6094L5.05709 12.6666Z"
        fill="#777D8A"
      />
    </svg>
  )
}
