import clsx from 'clsx'

import { ArrowDirection } from '@/components/icons'
import { FeatureItem } from '@/configs/solution-detail'
import LinkTo from '@/layout/header/link-to'

import styles from './solution-feature-items.module.scss'

type SolutionFeatureItemsProps = {
  data: FeatureItem[]
}

export const SolutionFeatureItems = ({ data }: SolutionFeatureItemsProps) => {
  const isFullWidth = data.length === 1
  return (
    <ul data-testid="feature-list" className={styles['feature-list']}>
      {data.map((feature, index) => (
        <LinkTo
          key={index}
          to={feature.link ?? ''}
          disabled={!feature.link}
          isExternal
          data-testid="feature-item"
          className={clsx(styles['feature-item'], isFullWidth && styles['full-width'])}
        >
          <div className={styles['item-container']}>
            <span data-testid="feature-icon" className={clsx(styles['feature-icon'], styles.icon)}>
              {feature.icon}
            </span>
            <span data-testid="feature-title" className={styles['feature-title']}>
              {feature.title}
            </span>
            <span data-testid="arrow-icon" className={clsx(styles['arrow-icon'], styles.icon)}>
              <ArrowDirection />
            </span>
          </div>
        </LinkTo>
      ))}
    </ul>
  )
}
