import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { PathRoutes } from '@/configs/paths'
import { useLocale } from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n/i18n'
import {
  NewsDetailHeroSection,
  RelatedNews,
  RenderEditor,
  ShowListButton,
} from '@/pages/news-detail/components'
import { useGetNewsByNewsId } from '@/services/news'
import { Locale } from '@/types/locales'
import { shortContent } from '@/utils/short-content'

import styles from './NewsDetail.module.scss'

function NewsDetail() {
  const lang = useLocale()
  const { i18n, t } = useTranslation()
  const { id } = useParams()

  const { data, isLoading } = useGetNewsByNewsId(id as string, lang as Locale)

  const news = data?.news

  const shortDescription = useMemo(() => {
    const doc = new DOMParser().parseFromString(news?.detail || '', 'text/html')
    return shortContent(doc)
  }, [news?.detail])

  return (
    <>
      {news ? (
        <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
          <title>{news.title}</title>
          <meta name="description" content={shortDescription} />
          <meta property="og:title" content={t(i18nLanguage.header.seo.news.ogTitle)} />
          <meta property="og:description" content={shortDescription} />
          <meta property="og:url" content={`https://gamescale.io/news/${i18n.language}`} />
          <meta property="og:image" content={'https://gamescale.io/og-white.png'} />
        </Helmet>
      ) : (
        ''
      )}
      <NewsDetailHeroSection data={data?.news} />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.detail}>
            <RenderEditor data={data?.news.detail} />
          </div>
          <RelatedNews
            previousNews={data?.navigation.previousNews}
            nextNews={data?.navigation.nextNews}
          />
          {!isLoading && <ShowListButton pathname={`/${lang}/${PathRoutes.NEWS}`} />}
        </div>
      </div>
    </>
  )
}

export default NewsDetail
