export const NexonMemberIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 3L7 15.6109V31.7358L12.2988 32.8087C13.2461 29.4169 16.337 26.9281 20 26.9281C24.4156 26.9281 27.9999 30.5447 28 35V35.9877L33 37V3ZM24.8274 19.8709C24.8274 22.561 22.6661 24.7418 20 24.7418C17.3338 24.7418 15.1725 22.561 15.1725 19.8709C15.1725 17.1808 17.3338 15 20 15C22.6661 15 24.8274 17.1808 24.8274 19.8709Z"
        fill="black"
      />
    </svg>
  )
}
