export const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 46 46"
      fill="none"
    >
      <circle cx="23" cy="23" r="23" fill="currentColor" />
      <mask
        id="mask0_354_10134"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="38"
        height="38"
      >
        <rect
          x="4.10547"
          y="22.6943"
          width="26.2857"
          height="26.2857"
          transform="rotate(-45 4.10547 22.6943)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_354_10134)">
        <path
          d="M25.9987 27.8771L23.065 30.8107C21.8908 31.985 20.4761 32.5722 18.821 32.5724C17.1658 32.5726 15.7512 31.9856 14.5772 30.8116C13.4032 29.6376 12.816 28.2227 12.8156 26.567C12.8152 24.9113 13.4021 23.4963 14.5764 22.3221L17.51 19.3884L19.5623 21.4407L16.6334 24.3697C16.0232 24.9799 15.7176 25.7117 15.7167 26.5651C15.7158 27.4184 16.0198 28.1495 16.6287 28.7584C17.2376 29.3673 17.9687 29.6713 18.8221 29.6704C19.6754 29.6695 20.4072 29.3639 21.0174 28.7537L23.9464 25.8248L25.9987 27.8771ZM20.2054 26.9057L18.4814 25.1817L25.1805 18.4826L26.9045 20.2066L20.2054 26.9057ZM27.8759 25.9999L25.8236 23.9476L28.7525 21.0186C29.3627 20.4084 29.6683 19.6766 29.6692 18.8233C29.6701 17.9699 29.3661 17.2388 28.7572 16.6299C28.1483 16.021 27.4172 15.717 26.5639 15.7179C25.7105 15.7188 24.9787 16.0244 24.3685 16.6346L21.4395 19.5635L19.3872 17.5112L22.3209 14.5776C23.4951 13.4033 24.9098 12.8161 26.565 12.8159C28.2201 12.8158 29.6347 13.4027 30.8087 14.5767C31.9827 15.7507 32.5699 17.1656 32.5704 18.8213C32.5708 20.477 31.9838 21.892 30.8095 23.0662L27.8759 25.9999Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
