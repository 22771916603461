export const UseCaseXSmallIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1321315887">
        <path
          id="Polygon 2"
          d="M9.37305 17.2835C9.141 17.4175 8.8551 17.4175 8.62305 17.2835L2.01183 13.4665C1.77978 13.3325 1.63683 13.0849 1.63683 12.817L1.63683 5.18301C1.63683 4.91506 1.77978 4.66747 2.01183 4.53349L8.62305 0.716506C8.8551 0.582532 9.141 0.58253 9.37305 0.716506L15.9843 4.53349C16.2163 4.66747 16.3593 4.91506 16.3593 5.18301L16.3593 12.817C16.3593 13.0849 16.2163 13.3325 15.9843 13.4665L9.37305 17.2835Z"
          fill="black"
        />
        <circle id="Ellipse 255" cx="9" cy="7.88477" r="1.75" fill="white" />
        <path
          id="Intersect"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.62503 17.2815C8.85708 17.4155 9.14298 17.4155 9.37503 17.2815L14.0749 14.5681C13.2696 12.5549 11.3011 11.1328 9.0004 11.1328C6.69958 11.1328 4.73096 12.5551 3.92578 14.5684L8.62503 17.2815Z"
          fill="#4268FB"
        />
      </g>
    </svg>
  )
}
