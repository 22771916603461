export const LauncherIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.99 16.41C26.9 17.56 26.74 19.11 26.56 20.74L33 26.48L32 30H8L7 26.48L13.44 20.74C13.26 19.1 13.1 17.56 13.01 16.41C12.51 9.93 15.78 6.15 20 4C24.22 6.15 27.49 9.92 26.99 16.41ZM17 14.58C17 16.24 18.34 17.58 20 17.58C21.66 17.58 23 16.24 23 14.58C23 12.92 21.66 11.58 20 11.58C18.34 11.58 17 12.92 17 14.58Z"
        fill="black"
      />
      <path d="M25 32L24 36H16L15 32H25Z" fill="black" />
    </svg>
  )
}
