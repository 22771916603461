import clsx from 'clsx'

import { SolutionCard, SolutionCardProp } from '@/pages/home/components/solution-card/SolutionCard'

import styles from './SolutionCardGroup.module.scss'

export type SolutionCardGroupProps = {
  list: (SolutionCardProp & { id: number })[]
  onlyRow?: boolean
}

export const SolutionCardGroup = ({ list, onlyRow }: SolutionCardGroupProps) => {
  return (
    <div
      data-testid="data-test-id-expend-card-group"
      className={clsx(styles.wrapper, { [styles.onlyRow]: onlyRow })}
    >
      {list.map((card) => (
        <SolutionCard
          title={card.title}
          description={card.description}
          link={card.link}
          Icon={card.Icon}
          image={card.image}
          key={card.id}
        />
      ))}
    </div>
  )
}
