export const BusinessXSmallIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 5.75C2.5 5.47386 2.72386 5.25 3 5.25H17C17.2761 5.25 17.5 5.47386 17.5 5.75V9.75H2.5V5.75Z"
        fill="#4268FB"
      />
      <path
        d="M2.5 15.75C2.5 16.0261 2.72386 16.25 3 16.25H17C17.2761 16.25 17.5 16.0261 17.5 15.75V9.75H2.5V15.75Z"
        fill="black"
      />
      <rect
        x="11"
        y="8.75"
        width="2"
        height="2"
        rx="0.25"
        transform="rotate(90 11 8.75)"
        fill="white"
      />
      <path
        d="M6.25 4.25C6.25 3.97386 6.47386 3.75 6.75 3.75H13.25C13.5261 3.75 13.75 3.97386 13.75 4.25V5.25H6.25V4.25Z"
        fill="black"
      />
    </svg>
  )
}
