import cx from 'clsx'

import { ChevronLeftIcon } from '@/components/icons/chevron-left'
import { ChevronRightIcon } from '@/components/icons/chevron-right'
import { FirstPageIcon } from '@/components/icons/first-page'
import { LastPageIcon } from '@/components/icons/last-page'

import { FIRST_PAGE, MAX_PAGES_TO_SHOW } from './pagination.config'
import usePagination from './pagination.hook'
import styles from './pagination.module.scss'

interface PaginationProps {
  totalCount: number
  pageSize: number
  onPageChange: (pageIndex: number) => void
  siblingCount?: number
  currentPage?: number
  maxPagesToShow?: number
}

export function Pagination({
  totalCount,
  pageSize,
  onPageChange,
  currentPage = 1,
  maxPagesToShow = MAX_PAGES_TO_SHOW,
  ...passProps
}: Readonly<PaginationProps>) {
  const totalPages = Math.ceil(totalCount / pageSize)

  const paginationRange = usePagination({
    totalPages,
    currentPage,
    maxPagesToShow,
  })

  //* check if there is only one page
  if (totalPages < 2) return null

  const lastPage = totalPages

  const goToPage = (targetPage: number) => {
    onPageChange(targetPage)
  }

  const goNextPageRange = () => {
    onPageChange(currentPage + 1)
  }

  const goPreviousPageRange = () => {
    onPageChange(currentPage - 1)
  }

  return (
    <nav className={styles['pagination-root']} {...passProps}>
      <ul className={styles['pagination-list']} data-testid="news-list-pagination">
        <li>
          <button
            data-testid="pagination-first-page-btn"
            disabled={currentPage === FIRST_PAGE}
            className={cx(styles['pagination-btn'], styles['nav-btn'])}
            onClick={() => goToPage(FIRST_PAGE)}
          >
            <FirstPageIcon className={styles['svg-icon']} />
          </button>
        </li>
        <li>
          <button
            data-testid="pagination-previous-page-btn"
            disabled={currentPage === FIRST_PAGE}
            className={cx(styles['pagination-btn'], styles['nav-btn'], styles['prev-btn'])}
            onClick={goPreviousPageRange}
          >
            <ChevronLeftIcon className={styles['svg-icon']} />
          </button>
        </li>
        <ul data-testid="pagination-num-btn-list" className={styles['num-btn-list']}>
          {paginationRange.map((pageBtn) => {
            return (
              <li key={pageBtn}>
                <button
                  data-testid={`pagination-page-btn${pageBtn === currentPage ? '-active' : ''}`}
                  className={cx(styles['pagination-btn'], styles['num-btn'], {
                    [styles['pagination-btn--active']]: pageBtn === currentPage,
                  })}
                  onClick={() => goToPage(pageBtn)}
                >
                  {pageBtn}
                </button>
              </li>
            )
          })}
        </ul>
        <li>
          <button
            data-testid="pagination-next-page-btn"
            disabled={currentPage === lastPage}
            className={cx(styles['pagination-btn'], styles['nav-btn'], styles['next-btn'])}
            onClick={goNextPageRange}
          >
            <ChevronRightIcon className={styles['svg-icon']} />
          </button>
        </li>
        <li>
          <button
            data-testid="pagination-last-page-btn"
            disabled={currentPage === lastPage}
            className={cx(styles['pagination-btn'], styles['nav-btn'])}
            onClick={() => goToPage(lastPage)}
          >
            <LastPageIcon className={styles['svg-icon']} />
          </button>
        </li>
      </ul>
    </nav>
  )
}
