import { useMemo } from 'react'

import { PathRoutes } from '@/configs/paths'
import { useLocale } from '@/hooks/use-locale'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import LinkTo from '@/layout/header/link-to/link-to'
import { useGetMainNewsList } from '@/services/news'
import { Locale } from '@/types/locales'

import Container from '../../../../components/container'
import { RightIcon } from '../../../../components/icons'
import Skeleton from '../../../../components/skeleton'
import NewsCard from '../news-card'
import styles from './news-section.module.scss'

const NewsSection = () => {
  const lang = useLocale()
  const { data, isFetching } = useGetMainNewsList(lang as Locale)

  const isDesktop = useMediaQuery(mediaQueryBreakpoints.up('lg2'))

  const newsList = useMemo(() => {
    if (!isDesktop) {
      return data?.newsList.slice(0, 3)
    }
    return data?.newsList
  }, [data, isDesktop])

  return (
    <div className={styles.wrapper}>
      <Container>
        <div data-testid="data-test-id-news-section-title" className={styles.title}>
          GameScale News
        </div>

        <LinkTo
          to={`${PathRoutes.NEWS}`}
          data-testid="data-test-id-learn-more-pc"
          className={styles.learnMorePC}
        >
          <p className={styles.label}>Learn More</p>
          <RightIcon className={styles.icon} />
        </LinkTo>

        {isFetching ? (
          <div className={styles.newsList}>
            <Skeleton className={styles.skeleton} />
            <Skeleton className={styles.skeleton} />
            <Skeleton className={styles.skeleton} />
            {isDesktop && <Skeleton className={styles.skeleton} />}
          </div>
        ) : (
          <div data-testid="data-test-id-news-list" className={styles.newsList}>
            {newsList?.map((news) => <NewsCard key={news.id} data={news} />)}
          </div>
        )}

        <LinkTo
          to={`${PathRoutes.NEWS}`}
          data-testid="data-test-id-learn-more-button"
          className={styles.learnMoreBtn}
        >
          <p className={styles.label}>Learn More</p>
          <RightIcon className={styles.icon} />
        </LinkTo>
      </Container>
    </div>
  )
}

export default NewsSection
