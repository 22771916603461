export const UxAnalysisIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5055 4V11.0755C25.7568 11.7971 29.0078 15.4951 29.0078 19.9549C29.0078 24.9258 24.9666 28.9746 20.005 28.9746C18.0644 28.9746 16.2738 28.3533 14.7934 27.301L9.79181 32.3119C12.5627 34.617 16.1138 36 19.995 36C28.8378 36 36 28.8243 36 19.9649C36.01 11.6066 29.638 4.76167 21.5055 4Z"
        fill="black"
      />
      <path
        d="M14.8034 12.6189C15.8837 11.8472 17.1441 11.306 18.5146 11.0755V4C15.2235 4.31068 12.2226 5.60351 9.81183 7.60789L14.8034 12.6189Z"
        fill="black"
      />
      <path
        d="M11.0022 19.9548C11.0022 21.899 11.6224 23.703 12.6727 25.1762L7.68115 30.1771C5.38043 27.4011 4 23.8333 4 19.9548C4 16.0763 5.38043 12.5085 7.68115 9.73242L12.6727 14.7334C11.6224 16.2066 11.0022 18.0105 11.0022 19.9548Z"
        fill="black"
      />
    </svg>
  )
}
