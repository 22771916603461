export const ADCreatorIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8778 27.5L16 5.84055L4.12225 27.5H27.8778ZM21.1223 23.5H10.8778L16 14.1594L21.1223 23.5Z"
        fill="black"
      />
      <path
        d="M26 12H23V16H26C29.866 16 33 19.134 33 23C33 26.866 29.866 30 26 30H23V34H26C32.0751 34 37 29.0751 37 23C37 16.9249 32.0751 12 26 12Z"
        fill="black"
      />
    </svg>
  )
}
