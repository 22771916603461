export const CustomPortalIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.224 20.262C24.224 22.6159 22.3328 24.5241 20 24.5241C17.6671 24.5241 15.776 22.6159 15.776 20.262C15.776 17.9082 17.6671 16 20 16C22.3328 16 24.224 17.9082 24.224 20.262Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6H36V34H4V6ZM8 14V30H13.6974C14.8313 27.6405 17.2285 26.0122 20 26.0122C22.7715 26.0122 25.1687 27.6405 26.3026 30H32V14H8ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z"
        fill="black"
      />
    </svg>
  )
}
