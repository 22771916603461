export function splitStringByIndices(inputString: string, indices: number[]) {
  const result = []
  let start = 0
  const splitWord = inputString.split(' ')
  for (const index of indices) {
    if (index >= start && index < splitWord.length) {
      result.push(splitWord.slice(start, index).join(' '))
      start = index
    }
  }

  // Add the remaining part of the string
  if (start < splitWord.length) {
    result.push(splitWord.slice(start).join(' '))
  }

  return result
}
