export const DeploymentIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7V17H5V33H35V17H34V7H6ZM30 17V11H10V17H15C15 19.7614 17.2386 22 20 22C22.7614 22 25 19.7614 25 17H30Z"
        fill="black"
      />
    </svg>
  )
}
