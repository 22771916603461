export const SupportXSmallIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 5.75024H17.5V16.3155C17.5 16.5556 17.3053 16.7502 17.0652 16.7502H2.93478C2.69466 16.7502 2.5 16.5556 2.5 16.3155V5.75024Z"
        fill="black"
      />
      <path
        d="M2.5 3.68478C2.5 3.44466 2.69466 3.25 2.93478 3.25H17.0652C17.3053 3.25 17.5 3.44466 17.5 3.68478V5.75H2.5V3.68478Z"
        fill="#4268FB"
      />
      <path d="M12.498 9.48242L14.2658 11.2502L12.498 13.018" stroke="white" />
      <path d="M7.49805 9.48242L5.73028 11.2502L7.49805 13.018" stroke="white" />
    </svg>
  )
}
