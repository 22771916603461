export const WebviewSupportIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6V34H36V6H4ZM8 30V16H32V30H8ZM10 13C11.1046 13 12 12.1046 12 11C12 9.89543 11.1046 9 10 9C8.89543 9 8 9.89543 8 11C8 12.1046 8.89543 13 10 13ZM18 11C18 12.1046 17.1046 13 16 13C14.8954 13 14 12.1046 14 11C14 9.89543 14.8954 9 16 9C17.1046 9 18 9.89543 18 11ZM22 13C23.1046 13 24 12.1046 24 11C24 9.89543 23.1046 9 22 9C20.8954 9 20 9.89543 20 11C20 12.1046 20.8954 13 22 13Z"
        fill="black"
      />
    </svg>
  )
}
