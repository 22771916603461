export const AccountTrustRatingIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 25.75L11.33 17.08L14.16 14.25L20 20.09L25.84 14.25L28.67 17.08L20 25.75Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.96L20 4L35 8.96V16.21C35 25.09 30.99 31.83 20 36C9.01 31.83 5 25.08 5 16.21V8.96ZM20 31.69C27.69 28.4 31 23.71 31 16.21V11.85L20 8.21L9 11.85V16.21C9 23.71 12.31 28.41 20 31.69Z"
        fill="black"
      />
    </svg>
  )
}
