type FirstPageIconType = {
  width?: number
  height?: number
  className?: string
}

export const FirstPageIcon = ({ width = 16, height = 16, className }: FirstPageIconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27669 7.9999L10.9434 12.6666L10.0006 13.6094L5.72441 9.33323L5.72441 13.6094H4.39108L4.39108 7.9999L4.39108 2.39042H5.72441L5.72441 6.66657L10.0006 2.39042L10.9434 3.33323L6.27669 7.9999Z"
        fill="#777D8A"
      />
    </svg>
  )
}
