import { SolutionDetailData } from '@/configs/solution-detail'
import useBuildT from '@/hooks/use-locale'

export const translateSolutionDetailData = (
  t: ReturnType<typeof useBuildT>,
  data: SolutionDetailData,
): SolutionDetailData => {
  return {
    id: data.id,
    name: t(data.name),
    title: data.title.map((part) => t(part)).filter((part) => part),
    description: t(data.description),
    featureItems: data.featureItems.map((feature) => ({
      ...feature,
      title: t(feature.title),
      link: t(feature.link ?? ''),
    })),
    featureDetails: data.featureDetails.map((feature) => ({
      title: t(feature.title),
      description: feature.description.map((item) => ({
        title: t(item.title),
        contents: item.contents.map((content) => t(content)),
      })),
    })),
  }
}
