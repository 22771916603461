import { ElementRef } from 'react'

import { useScrollIndicator } from '@/hooks/use-scroll-indicator'

import styles from './progress-bar.module.scss'

function ProgressBar() {
  const ref = useScrollIndicator<ElementRef<'div'>>()

  return (
    <div className={styles.wrapper}>
      <div ref={ref} className={styles.bar} />
    </div>
  )
}

export default ProgressBar
