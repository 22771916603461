import { forwardRef } from 'react'

import { PathRoutes } from '@/configs/paths'
import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import LinkTo from '@/layout/header/link-to'
import { RelatedNewsDto } from '@/services/news'

import styles from './RelatedNews.module.scss'

export type RelatedNewsDetailItemProps = {
  type: 'PREV' | 'NEXT'
} & RelatedNewsDto

export const RelatedNewsDetailItem = forwardRef<HTMLDivElement, RelatedNewsDetailItemProps>(
  function ({ type, category, title, id }, ref) {
    const t = useBuildT()

    const typeString =
      type === 'PREV' ? t(i18nLanguage.newsDetail.prev) : t(i18nLanguage.newsDetail.next)

    return (
      <LinkTo to={`${PathRoutes.NEWS}/${id}`}>
        <div className={styles.wrapper} data-testid="data-test-id-related-news-detail-item">
          <p className={styles.type}>{typeString}</p>
          <p ref={ref} data-testid="data-test-id-category" className={styles.category}>
            {category.name}
          </p>
          <h1 data-testid="data-test-id-title" className={styles.title}>
            {title}
          </h1>
        </div>
      </LinkTo>
    )
  },
)

RelatedNewsDetailItem.displayName = 'RelatedNewsDetailItem'
