import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps } from 'react-router-dom'

type LinkToProps = NavLinkProps & {
  children: ReactNode
  to: string
  isExternal?: boolean
  disabled?: boolean
  'data-testid'?: string
}

function LinkTo({ children, to, isExternal = false, disabled = false, ...props }: LinkToProps) {
  const {
    i18n: { language },
  } = useTranslation()

  if (disabled) {
    return (
      <div data-testid={props['data-testid']} className={props.className as string}>
        {children}
      </div>
    )
  }

  return (
    <NavLink
      to={isExternal ? to : '/' + language + '/' + to}
      target={isExternal ? '_blank' : ''}
      {...props}
    >
      {children}
    </NavLink>
  )
}

export default LinkTo
