export const ArrowDirection = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8837 22.2498L24.2976 16.6998L26.8236 14L35 22.2497V26H5V22.2498H29.8837Z"
        fill="currentColor"
      />
    </svg>
  )
}
