export const CommunityXSmallIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="18"
        y="6"
        width="10.5"
        height="13.5"
        rx="0.5"
        transform="rotate(90 18 6)"
        fill="#4268FB"
      />
      <rect
        x="15.5"
        y="3.5"
        width="10.5"
        height="13.5"
        rx="0.5"
        transform="rotate(90 15.5 3.5)"
        fill="black"
      />
      <circle cx="8.74805" cy="8.75" r="0.875" fill="white" />
      <circle cx="11.998" cy="8.75" r="0.875" fill="white" />
      <circle cx="5.49805" cy="8.75" r="0.875" fill="white" />
    </svg>
  )
}
