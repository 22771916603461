import { ErrorResponse, useNavigate, useRouteError } from 'react-router-dom'

import { useLocale } from '@/hooks/use-locale'
import MainLayout from '@/layout/main-layout'

import styles from './error.module.scss'

const ErrorPage = () => {
  const error = useRouteError() as ErrorResponse
  const navigate = useNavigate()

  const lang = useLocale()

  const handleBack = () => {
    if (!history.state['key']) {
      navigate(`/${lang}`)
    } else navigate(-1)
  }

  return (
    <MainLayout>
      <div className={styles.errorPage}>
        <div className={styles.statusCode}>{error.status}</div>
        <div className={styles.message}>{error.data}</div>
        <button onClick={handleBack} className={styles.backButton}>
          Back
        </button>
      </div>
    </MainLayout>
  )
}

export default ErrorPage
