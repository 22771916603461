import { HeroMain, NewsSection, SolutionSection } from '@/pages/home/components'

function HomePage() {
  return (
    <>
      <HeroMain />
      <SolutionSection />
      <NewsSection />
    </>
  )
}

export default HomePage
