import { useMemo } from 'react'

import dayjs from 'dayjs'
import DOMPurify from 'dompurify'

import { PathRoutes } from '@/configs/paths'
import LinkTo from '@/layout/header/link-to'
import { NewsDto } from '@/services/news'
import { shortContent } from '@/utils/short-content'

import styles from './news-card.module.scss'

interface NewsCardProps {
  data: NewsDto
}
const NewsCard = ({ data }: NewsCardProps) => {
  const description = useMemo(() => {
    const doc = new DOMParser().parseFromString(data.detail || '', 'text/html')
    const shortDoc = shortContent(doc)

    return DOMPurify.sanitize(shortDoc)
  }, [data.detail])

  return (
    <LinkTo to={`${PathRoutes.NEWS}/${data.id}`} className={styles.cardWrapper}>
      <div data-testid="data-test-id-badge-new" className={styles.badge}>
        {data.isNew && <div className={styles.badgeNew}>New</div>}
      </div>
      <div data-testid="data-test-id-category" className={styles.category}>
        {data.category.name}
      </div>
      <h1 data-testid="data-test-id-title" className={styles.title}>
        {data.title}
      </h1>
      <p data-testid="data-test-id-date" className={styles.date}>
        {dayjs(data.createdDate).format('YYYY-MM-DD')}
      </p>
      {data.description ? (
        <p data-testid="data-test-id-description" className={styles.description}>
          {data.description}
        </p>
      ) : (
        <p
          data-testid="data-test-id-description"
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
    </LinkTo>
  )
}

export default NewsCard
