import { useRef, useState } from 'react'

import clsx from 'clsx'
import dayjs from 'dayjs'

import { DoneIcon, LinkIcon } from '@/components/icons'
import ProgressBar from '@/components/progress-bar'
import useEventListener from '@/hooks/use-event-listener'
import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import { NewsDto } from '@/services/news'

import styles from './news-detail-hero-section.module.scss'

interface NewsDetailHeroSectionProps {
  data?: NewsDto
}

const TOAST_MESSAGE_TIMEOUT = 2000

function NewsDetailHeroSection({ data }: NewsDetailHeroSectionProps) {
  const [isShow, setIsShow] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [enableToastMessage, setEnableToastMessage] = useState(false)

  const t = useBuildT()

  const handleScroll = () => {
    if (!ref.current) return
    const height = ref.current.offsetHeight
    setIsShow(() => window.scrollY > height)
  }

  useEventListener({ eventType: 'scroll', handler: handleScroll })

  const handleCopyToClipboard = () => {
    if (!enableToastMessage) {
      // navigator.clipboard.writeText(window.location.href)
      // Create a hidden text area
      const textArea = document.createElement('textarea')
      textArea.value = window.location.href

      // Prevent the text area from being visible or selectable
      textArea.style.position = 'fixed'
      textArea.style.opacity = '0'

      // Append the text area to the document
      document.body.appendChild(textArea)

      // Select the text inside the text area
      textArea.select()
      textArea.setSelectionRange(0, 99999) // For mobile devices

      // Copy the selected text to the clipboard
      document.execCommand('copy')

      // Remove the text area from the document
      document.body.removeChild(textArea)
      setEnableToastMessage(true)
      setTimeout(() => setEnableToastMessage(false), TOAST_MESSAGE_TIMEOUT)
    }
  }

  return (
    <>
      <div className={styles.heroWrapper} ref={ref}>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.category}>{data?.category.name}</div>
            <div className={styles.title}>{data?.title}</div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.date}>
              {data && dayjs(data.createdDate).format('YYYY-MM-DD')}
            </div>
            <div className={styles['copy-container']}>
              <div
                className={clsx(styles['copy-wrapper'], {
                  [styles.animation]: enableToastMessage,
                })}
              >
                <DoneIcon />
                <p className={styles['copy-text']}>{t(i18nLanguage.newsDetail.toastMessage)}</p>
              </div>

              <button className={styles.icon} onClick={handleCopyToClipboard}>
                <LinkIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.progressBar, isShow && styles.show)}>
        <ProgressBar />
      </div>
    </>
  )
}

export default NewsDetailHeroSection
