import clsx from 'clsx'

import '@/assets/styles/editor.scss'

import styles from './render-editor.module.scss'

interface RenderEditorProps {
  data?: string
}
const RenderEditor = ({ data = '' }: RenderEditorProps) => {
  return (
    <div
      className={clsx('editor-container', styles.renderEditorWrapper)}
      dangerouslySetInnerHTML={{ __html: data }}
    ></div>
  )
}

export default RenderEditor
