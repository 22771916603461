import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga'

import App from '@/App/App'
import { ENV } from '@/configs/env'

// import '@/assets/styles/styles.scss'
import reportWebVitals from './reportWebVitals'

ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID)

const root = createRoot(document.getElementById('root') as HTMLElement)

if (import.meta.env.MODE === 'e2e' || ENV.ENABLE_FAKE_DATA === 'true') {
  import('@/__mocks__/browser')
    .then(async ({ worker }) => {
      await worker.start()
    })
    .then(() => {
      root.render(
        <StrictMode>
          <App />
        </StrictMode>,
      )
    })
} else {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
}

reportWebVitals()
