import { ComponentPropsWithoutRef } from 'react'

import clsx from 'clsx'

import styles from './container.module.scss'

const Container = ({
  isSubPage,
  className,
  children,
  ...passProps
}: { isSubPage?: boolean } & ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={clsx(styles.wrapper, className, { [styles['sub-page']]: isSubPage })}
      {...passProps}
    >
      {children}
    </div>
  )
}
export default Container
