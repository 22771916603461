export const AccountProtectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 16.51C24.5 18.9953 22.4853 21.01 20 21.01C17.5147 21.01 15.5 18.9953 15.5 16.51C15.5 14.0247 17.5147 12.01 20 12.01C22.4853 12.01 24.5 14.0247 24.5 16.51Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.96L20 5L35 9.96V17.21C35 26.09 30.99 32.83 20 37C9.01 32.83 5 26.09 5 17.21V9.96ZM27.75 27.35C29.97 24.67 31 21.38 31 17.21V12.85L20 9.21L9 12.85V17.21C9 21.38 10.02 24.68 12.25 27.35C13.58 24.39 16.55 22.33 20 22.33C23.45 22.33 26.42 24.39 27.75 27.35Z"
        fill="black"
      />
    </svg>
  )
}
