type LastPageIconType = {
  width?: number
  height?: number
  className?: string
}
export const LastPageIcon = ({ width = 16, height = 16, className }: LastPageIconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72376 7.9999L5.05709 12.6666L5.9999 13.6094L10.276 9.33323V13.6094H11.6094V7.9999L11.6094 2.39042H10.276L10.276 6.66657L5.9999 2.39042L5.05709 3.33323L9.72376 7.9999Z"
        fill="#777D8A"
      />
    </svg>
  )
}
