export const AnalysisMediumIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.998" cy="15" r="11.625" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6231 15.0011H14.9961V3.375C14.9967 3.375 14.9974 3.375 14.998 3.375C21.4184 3.375 26.6231 8.57969 26.6231 15C26.6231 15.0004 26.6231 15.0007 26.6231 15.0011Z"
        fill="#4268FB"
      />
      <circle cx="15" cy="15" r="4.5" fill="white" />
    </svg>
  )
}
