import { Outlet } from 'react-router-dom'
import { Fragment } from 'react/jsx-runtime'

import '@/assets/styles/main-layout.scss'
import ScrollToTopButton from '@/components/scroll-to-top-button'
import useBackToTopNewPage from '@/hooks/use-back-to-top-new-page'
import HelmetProvider from '@/providers/helmet-provider'

import Body from './body'
import Footer from './footer'
import Header from './header'

interface MainLayoutProps {
  children?: React.ReactNode
}
function MainLayout({ children }: MainLayoutProps) {
  useBackToTopNewPage()

  return (
    <HelmetProvider>
      <Fragment>
        <Header />
        <Body>
          {children ?? <Outlet />}
          <ScrollToTopButton />
        </Body>
        <Footer />
      </Fragment>
    </HelmetProvider>
  )
}

export default MainLayout
